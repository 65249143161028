import React from "react"

import styles from "./InfoCard.module.sass"

export type InfoCardProps = {
  icon: React.ReactNode
  heading: React.ReactNode
  children: React.ReactNode
}

const InfoCard: React.FC<InfoCardProps> = ({ icon, heading, children }) => {
  return (
    <div className={styles.root}>
      <span>
        {icon}
        {heading}
      </span>
      {children}
    </div>
  )
}

export default InfoCard
