import React from "react"
import ReactDOM from "react-dom"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import { useAppConfig } from "@components/theme/AppConfigProvider"
import { SHOW_NOTIFICATION_BANNER_KEY } from "@framework/constants/app"
import { useStore } from "@store"

import NotificationBanner from "./NotificationBanner"

import styles from "./GlobalNotificationBanner.module.sass"

export interface GlobalNotificationBannerProps {}

export const GlobalNotificationBanner: React.FC<GlobalNotificationBannerProps> =
  observer(() => {
    const location = useLocation()
    const navigate = useNavigate()

    const config = useAppConfig()

    const { authStore } = useStore()

    const [showBanner, setBannerVisible] = React.useState<Boolean>(
      localStorage.getItem(SHOW_NOTIFICATION_BANNER_KEY) === "true" &&
        authStore.isAuthorized
    )

    const handleShowBanner = (value: boolean) => {
      setBannerVisible(value)
      localStorage.setItem(SHOW_NOTIFICATION_BANNER_KEY, `${value}`)
    }

    const isDisabled = !!config.notificationBanner?.disabled

    React.useEffect(() => {
      const { reason, ...restState } = location.state ?? {}

      const isAfterLogin = reason === "LOGIN"

      if (isDisabled || !isAfterLogin || !authStore.isAuthorized) return

      handleShowBanner(true)
      navigate(`${location.pathname}${location.search}`, { ...restState })
    }, [location.state])

    if (config.notificationBanner == null) return null
    if (!showBanner || isDisabled) return null

    const { placement } = config.notificationBanner

    return ReactDOM.createPortal(
      <div
        className={styles.root}
        style={{
          [placement?.toLocaleLowerCase() ?? "top"]: 0,
        }}
      >
        <div className={styles.banner}>
          <NotificationBanner
            type={config.notificationBanner.messageType}
            title={config.notificationBanner.title}
            onClose={() => handleShowBanner(false)}
          >
            {config.notificationBanner.message}
          </NotificationBanner>
        </div>
      </div>,
      document.body
    )
  })

export default GlobalNotificationBanner
