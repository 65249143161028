import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import Spreadsheet from "@components/ui/Spreadsheet/Spreadsheet"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { useController, useStore } from "@store"
import { SolutionData } from "@framework/types/solution"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import mainRoutes from "@root/main.routes"
import { MatrixSnapshot } from "@components/ui/Spreadsheet/types"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import useQueryParams from "@components/hooks/useQueryParams"

import { initCustomFunctions } from "./custom-functions"
import { customContextMenuBuilder } from "./custom-context-menu"

import styles from "./index.module.sass"

interface Props {
  solution: SolutionData
}

const UnifiedMatrix: React.FC<Props> = observer(({ solution }) => {
  const alert = useAlert()
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = useQueryParams()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const solutionId = solution.id

  const conditionModal = useModal(ModalsTypes.CONFIRM_MODAL)

  const { unifiedMatrixStore } = useStore()

  const { unifiedMatrixController } = useController()

  const matrixStore = unifiedMatrixStore.getMatrixInstance(solutionId)

  const flushQueryParams = () => {
    navigate(location.pathname)
  }

  const initNewInstance = async () => {
    unifiedMatrixStore.snapshotStore.dropSnapshot(solutionId)

    const matrixStore = await unifiedMatrixStore.initInstance(
      solutionId,
      unifiedMatrixController,
      solution.workbookData,
      true
    )

    if (matrixStore) initCustomFunctions(matrixStore, unifiedMatrixController)

    return matrixStore
  }

  const initInstanceFromSnapshot = async () => {
    const matrixStore = await unifiedMatrixStore.initInstance(
      solutionId,
      unifiedMatrixController,
      solution.workbookData,
      false
    )

    if (matrixStore) initCustomFunctions(matrixStore, unifiedMatrixController)
  }

  const goHome = () => {
    navigate(mainRoutes.home())
  }

  const storeSnapshot = (snapshot: MatrixSnapshot) => {
    unifiedMatrixStore.snapshotStore.saveSnapshot(solutionId, snapshot)
  }

  React.useEffect(() => {
    if (matrixStore != null && unifiedMatrixStore.error == null) {
      const { manufacturer, product } = queryParams

      if (manufacturer) {
        const point = matrixStore.editManager.setToFirstEmptyCell(
          "A1",
          manufacturer,
          {
            autoSelect: true,
            axis: "y",
          }
        )

        if (point == null)
          alert.error(
            <>
              Unable to prefill Company: {manufacturer}
              <br />
              No empty cell found
            </>
          )
      }

      if (product) {
        const point = matrixStore.editManager.setToFirstEmptyCell(
          "B1",
          product,
          {
            autoSelect: true,
            axis: "y",
          }
        )

        if (point == null)
          alert.error(
            <>
              Unable to prefill Product: {product}
              <br />
              No empty cell found
            </>
          )
      }

      flushQueryParams()

      if (matrixStore.readonlyMode) return undefined

      matrixStore.addCellContextMenuBuilder(customContextMenuBuilder)
      return () =>
        matrixStore.removeCellContextMenuBuilder(customContextMenuBuilder)
    }

    return undefined
  }, [matrixStore])

  React.useEffect(() => {
    if (matrixStore != null) return

    if (unifiedMatrixStore.snapshotStore.hasSnapshot(solutionId)) {
      conditionModal.showModal({
        message: "Would you like to continue previous session?",
        confirmButtonText: "Continue",
        rejectButtonText: "New Session",
        onReject: () => {
          initNewInstance()
        },
        onConfirm: initInstanceFromSnapshot,
        onClose: goHome,
      })
      return
    }

    initNewInstance()
  }, [solutionId])

  return (
    <div className={styles.root}>
      {!isTablet && (
        <Templates.Header
          className={styles.header}
          left={
            <List overflow="initial" gutter="0">
              <Text color="text50Color" variant="caption2">
                Workbook
              </Text>
              <Text color="text100Color" variant="h4">
                {solution.name}
              </Text>
            </List>
          }
        />
      )}
      <div className={styles.body}>
        {matrixStore == null ? (
          unifiedMatrixStore.error == null ? (
            <Loader size="large" fluid />
          ) : (
            <NotFound>{unifiedMatrixStore.error}</NotFound>
          )
        ) : (
          <Spreadsheet instance={matrixStore} onUpdate={storeSnapshot} />
        )}
      </div>
    </div>
  )
})

export default UnifiedMatrix
