import React from "react"
import { observer } from "mobx-react-lite"

import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import { useSearchContext } from "@components/prototypes/SearchContext"
import useSearch from "@components/hooks/useSearch"

export const SearchContextInput: React.FC = observer(() => {
  const context = useSearchContext()

  const [searchProps, helper] = useSearch({
    value: context.query,
    onChange: context.setQuery,
  })

  return (
    <TextInput
      {...searchProps}
      name="search"
      placeholder="Search"
      before={<Icon name="search" />}
      after={
        !!searchProps.value && (
          <Icon name="cross" onClick={() => helper.setQuery("")} />
        )
      }
    />
  )
})

export default TextInput
