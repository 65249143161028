import React from "react"
import { observer } from "mobx-react-lite"
import { nanoid } from "nanoid"

import { Point } from "@framework/types/common"

import { useMatrixContext } from "./MatrixContext"
import { includes } from "./utils"

import styles from "./Spreadsheet.module.sass"

export const SelectionSquare: React.FC<{
  shardId: string
  cell: Point
  xBaseIndex?: number
  yBaseIndex?: number
}> = observer(({ xBaseIndex = 0, yBaseIndex = 0, shardId, cell }) => {
  const { grid, editManager } = useMatrixContext()

  const shardGrid = grid.getVisibleGridCache(shardId)

  const shiftedCell = {
    x: cell.x - xBaseIndex,
    y: cell.y - yBaseIndex,
  }

  const isVisible = includes(shardGrid.visibleGridRect, cell)
  const position = shardGrid.getCellCoordinates(shiftedCell)

  const [id] = React.useState(() => nanoid())

  React.useEffect(() => {
    if (!editManager.isEditing && isVisible)
      document.getElementById(id)?.focus()
  }, [editManager.isEditing, cell.x, cell.y, isVisible])

  if (!isVisible) return null
  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      id={id}
      className={styles.selected}
      role="button"
      tabIndex={0}
      style={{
        left: position.x,
        top: position.y,
        width: grid.getCellWidth(cell.x),
        height: grid.getCellHeight(cell.y),
      }}
    />
  )
})

export default SelectionSquare
