import React from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"

export const ManufacturerTableHeader: React.FC = observer(() => {
  return (
    <Row>
      <Column as="th" key="name" width="auto">
        Manufacturer
      </Column>

      <Column as="th" key="actions" width="auto" justify="center">
        Products
      </Column>

      <Column as="th" key="roles" width="auto" justify="center">
        Content
      </Column>

      <Column as="th" key="groups" width="auto">
        Link to Public Data
      </Column>

      <Column as="th" key="created_date" width="auto" />
    </Row>
  )
})

export default ManufacturerTableHeader
