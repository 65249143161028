import clsx from "clsx"
import React from "react"

import { ChipColorType } from "./Chip"

import styles from "./IconChip.module.sass"
import colorStyles from "./Chip-colors.module.sass"

export interface IconChipProps extends React.HTMLProps<HTMLDivElement> {
  color?: ChipColorType
  iconSize?: "small" | "medium" | "big"
}

export const IconChip = ({
  className,
  color,
  iconSize,
  children,
  ...rest
}: IconChipProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        styles.root,
        {
          [colorStyles[`color-${color}`]]: color,
          [styles[`size-${iconSize}`]]: iconSize,
        },
        className
      )}
    >
      {children}
    </div>
  )
}

export default IconChip
