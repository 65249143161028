import React, { MouseEventHandler } from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { useUnstructuredSearchEntity } from "@pages/search/SearchContext/SearchResultContext"
import Icon from "@components/ui/Icon/Icon"

import styles from "./SuggestImprovements.module.sass"

type SuggestButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>
}

const SuggestButton: React.FC<SuggestButtonProps> = observer(({ onClick }) => {
  const {
    searchEntity: { suggestedSummary },
  } = useUnstructuredSearchEntity()

  if (suggestedSummary) {
    return null
  }

  return (
    <Button
      onClick={onClick}
      before={<Icon name="sparkling" />}
      size="small"
      color="primary"
    >
      Improve this Answer
    </Button>
  )
})

export default SuggestButton
