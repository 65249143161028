import React from "react"

import Row from "@components/ui/BaseTable/Row"
import TextColumn from "@components/ui/SimpleTable/TextColumn"

const ProductsTableHeader: React.FC = () => {
  return (
    <Row>
      <TextColumn>Product Name</TextColumn>
      <TextColumn>Manufacturer</TextColumn>
      <TextColumn>Identifiers</TextColumn>
      <TextColumn>Linked Contents</TextColumn>
      <TextColumn width={38}> </TextColumn>
    </Row>
  )
}

export default ProductsTableHeader
