import React, { Dispatch } from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import Tooltip from "@components/ui/Tooltip/Tooltip"
import useToggle from "@components/hooks/useToggle"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import MenuItem from "@components/containers/Sidebar/MenuItem"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"

interface ContextMenuProps {
  allowAll: boolean
  setAllowAll: Dispatch<boolean>
}

const UserAccessContextMenu: React.FC<ContextMenuProps> = observer(
  ({ allowAll, setAllowAll }) => {
    const { isOpened, handleToggle, setOpened } = useToggle()

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            <MenuItem
              icon="edit"
              onClick={() => {
                setAllowAll(true)
                handleToggle()
              }}
              key="edit"
            >
              All Users
            </MenuItem>
            <MenuItem
              icon="standards"
              onClick={() => {
                setAllowAll(false)
                handleToggle()
              }}
              key="edit"
            >
              Specific Users
            </MenuItem>
          </TooltipContainer>
        }
      >
        <Button
          shape="rounded"
          variant="outlined"
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleToggle()
          }}
          after={<Icon name="arrow-down" />}
        >
          {allowAll ? "All Users" : "Specific Users"}
        </Button>
      </Tooltip>
    )
  }
)

export default UserAccessContextMenu
