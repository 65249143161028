import { useEffect, useRef, useCallback } from "react"

type InfiniteScrollOptions = {
  callback: () => void
  isLoading: boolean
  hasMore: boolean
}

export const useInfiniteScroll = ({
  callback,
  isLoading,
  hasMore,
}: InfiniteScrollOptions) => {
  const observerRef = useRef<HTMLDivElement | null>(null)

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting && !isLoading && hasMore) {
        callback()
      }
    },
    [callback, isLoading, hasMore]
  )

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      threshold: 0.1,
    })

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current)
      }
    }
  }, [observerCallback])

  return observerRef
}

export default useInfiniteScroll
