import { useMemo } from "react"
import { useLocation } from "react-router-dom"

const useQueryParams = () => {
  const { search } = useLocation()
  return useMemo(
    () =>
      Object.fromEntries(
        search
          .replace("?", "")
          .split("&")
          .map((pair) => pair.split("="))
          .filter(([key]) => !!key)
          .map(([key, value]) => [
            decodeURIComponent(key),
            decodeURIComponent(value),
          ])
      ),
    [search]
  )
}

export default useQueryParams
