import React from "react"
import { observer } from "mobx-react-lite"
import { Navigate, Route, Routes } from "react-router-dom"

import { useStore } from "@store/index"
import NotFound from "@components/ui/NotFound/NotFound"

import ProductIdentifiers from "./components/ProductIdentifiersList"
import IdentifierViewPage from "./components/view"
import IdentifierEditPage from "./components/edit"

const ProductIdentifiersRoot: React.FC = observer(() => {
  const { restrictionsStore: access } = useStore()

  return (
    <Routes>
      {access.canViewIdentifier && (
        <>
          <Route path="/" element={<Navigate to="list" />} />
          <Route path="/list" element={<ProductIdentifiers />} />
          <Route path="/view/:id" element={<IdentifierViewPage />} />
        </>
      )}
      {access.canUpdateIdentifier && (
        <Route path="/edit/:id" element={<IdentifierEditPage />} />
      )}

      <Route path="*" element={<NotFound />} />
    </Routes>
  )
})

export default ProductIdentifiersRoot
