import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import usePopper from "@components/hooks/usePopper"
import { initArray } from "@utils/numberUtils"

import Icon, { IconProps } from "../Icon/Icon"
import Popper from "../Dropdown/Popper"
import Box from "../Dropdown/Box"
import IconButton, { IconButtonSize } from "../IconButton/IconButton"
import Button from "../Button/Button"

import styles from "./ColorPicker.module.sass"

type ColorPickerProps = {
  icon?: IconProps["name"]
  size?: IconButtonSize
  options: string[]
  columns?: number
  value?: string
  title?: string
  disabled?: boolean
  onChange?: (value: string | undefined) => void
}

const ColorPicker: React.FC<ColorPickerProps> = observer(
  ({
    value,
    options,
    icon = "palette",
    columns = 5,
    title,
    size,
    disabled,
    onChange,
  }) => {
    const [menuNode, setMenuNode] = React.useState<HTMLElement | null>(null)

    const [containerNode, setContainerNode] =
      React.useState<HTMLElement | null>(null)

    const popoverProps = usePopper(containerNode, menuNode, { disabled })
    const rows = Math.ceil(options.length / columns)

    const contentNode = (
      <div className={styles.root}>
        <div className={styles.grid}>
          {initArray(columns, (colIdx) => (
            <div className={styles.column} key={colIdx}>
              {initArray(rows, (rowIdx) => {
                const it = options[colIdx * rows + rowIdx]
                if (it == null) return null
                return (
                  <ColorButton
                    key={it}
                    active={value === it}
                    color={it}
                    onClick={(e) => {
                      onChange?.(it)
                      popoverProps.toggle(e, false)
                    }}
                  />
                )
              })}
            </div>
          ))}
        </div>

        {value != null && (
          <Button
            variant="text"
            size="tiny"
            before={<Icon name="prohibited" />}
            onClick={(e) => {
              onChange?.(undefined)
              popoverProps.toggle(e, false)
            }}
          >
            Reset
          </Button>
        )}
      </div>
    )

    return (
      <>
        <IconButton
          ref={setContainerNode}
          active={popoverProps.isActive}
          onClick={popoverProps.toggle}
          size={size}
          title={title}
          disabled={disabled}
        >
          <Icon name={icon} />
        </IconButton>

        <Popper
          ref={setMenuNode}
          style={popoverProps.style}
          isActive={popoverProps.isActive}
        >
          <Box color="primary">{contentNode}</Box>
        </Popper>
      </>
    )
  }
)

const ColorButton: React.FC<
  { active?: boolean } & React.HTMLAttributes<HTMLButtonElement>
> = observer(({ active, color, className, ...props }) => {
  return (
    <button
      type="button"
      tabIndex={-1}
      aria-label="Color"
      {...props}
      className={clsx(
        styles.colorButton,
        { [styles.active]: active },
        className
      )}
      style={{
        background: color,
        color,
        ...props.style,
      }}
    />
  )
})

export default ColorPicker
