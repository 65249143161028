import React from "react"
import { Field, FieldProps } from "formik"

import OptionsGroup from "@components/prototypes/FilterSidebar/components/OptionGroup/OptionGroup"
import UserSelect from "@components/prototypes/FilterSidebar/components/UserSelect/UserSelect"
import { assignStatusOptions } from "@framework/constants/analytics"
import SolutionsSelect from "@components/prototypes/FilterSidebar/components/SolutionsSelect/SolutionsSelect"
import RatingSelect from "@components/prototypes/FilterSidebar/components/RatingSelect/RatingSelect"

const FilterFormBody: React.FC = () => (
  <>
    <Field
      name="userIds"
      label="By User"
      valueKey="id"
      component={UserSelect}
      showSuperAdmins={false}
    />

    <Field name="solutions" label="Solutions" component={SolutionsSelect} />

    <Field name="ratings" label="Rating" component={RatingSelect} />

    <Field name="assignStatus">
      {({ field, form }: FieldProps) => (
        <OptionsGroup
          label="Assign Status"
          options={assignStatusOptions}
          value={field.value}
          onChange={(value) => form.setFieldValue(field.name, value)}
        />
      )}
    </Field>
  </>
)

export default FilterFormBody
