import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { combineLatest, from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"

import { searchBy } from "@utils/optionsUtils"
import contentManagerService from "@services/content-manager.service"
import { Company } from "@framework/types/company"

const RETRY_DELAY = 30 * 1000

type State = {
  isLoading: boolean
  data: Company[]
  total: number
  errorMessage: string
  searchQuery: string
  reloadTrigger: number
}

export class AllCompaniesStore {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    this.state = {
      isLoading: false,

      data: [],

      total: 0,

      searchQuery: "",

      errorMessage: "",

      reloadTrigger: Date.now(),
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initLoadingStream().subscribe()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    const { state } = this

    const loadStream$ = from(
      toStream(() => [state.reloadTrigger], true) as ObservableInput<[boolean]>
    ).pipe(
      tap(() =>
        runInAction(() => {
          state.isLoading = true
          state.errorMessage = ""
        })
      ),
      switchMap(() => {
        return contentManagerService.getCompanies$()
      })
    )

    const filterStream$ = from(
      toStream(() => [state.searchQuery], true) as ObservableInput<[string]>
    )

    return combineLatest([loadStream$, filterStream$]).pipe(
      tap(([response, [searchQuery]]) =>
        runInAction(() => {
          const items = response.data ?? []
          state.data = searchBy(items, searchQuery, (it) => it.name)
          state.total = state.data.length
          state.isLoading = false
        })
      ),
      retry({
        delay: () => {
          runInAction(() => {
            state.errorMessage = "Loading Failed"
          })
          return timer(RETRY_DELAY)
        },
      })
    )
  }

  search = (query: string) => {
    this.state.searchQuery = query
  }

  refresh = () => {
    this.state.reloadTrigger = Date.now()
  }

  get getById() {
    const dataMap = new Map(this.state.data.map((it) => [it.id, it]))
    return (id?: string | null) => {
      if (id == null) return null
      return dataMap.get(id) ?? null
    }
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number) => {
      return data[index]
    }
  }
}

export default AllCompaniesStore
