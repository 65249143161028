import React from "react"
import clsx from "clsx"

import Avatar from "@components/ui/Avatar/Avatar"
import Text from "@components/ui/Typography/Text"
import { UserAndGroup } from "@framework/types/user"

import styles from "./AccessPermissionModal.module.sass"

interface UserAndGroupCardProps {
  user: UserAndGroup
  isSelected: boolean
  onSelect: (user: UserAndGroup) => void
  lastItemRef?: React.Ref<HTMLDivElement>
}

export const getName = (user: UserAndGroup) => {
  return user.type === "user" ? `${user.firstName} ${user.lastName}` : user.name
}

const UserAndGroupCard: React.FC<UserAndGroupCardProps> = ({
  user,
  isSelected,
  onSelect,
  lastItemRef,
}) => {
  return (
    <div
      key={user.id}
      className={clsx(styles.user, {
        [styles.selected]: isSelected,
      })}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onSelect(user)
        }
      }}
      onClick={() => onSelect(user)}
      ref={lastItemRef}
    >
      <Avatar src={user.avatarURL} name={getName(user)} />
      <div>
        <Text variant="body2" color="text100Color">
          {getName(user)}
        </Text>
        <Text variant="body2" color="text70Color">
          {user.type === "user" ? user.email : `${user.userCount} users`}
        </Text>
      </div>
    </div>
  )
}

export default UserAndGroupCard
