import React from "react"
import { observer } from "mobx-react-lite"

import { Option } from "@framework/types/utils"
import { toggleOneOfMany } from "@utils/collections"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import { useMatrixContext } from "./MatrixContext"
import IconButton from "../IconButton/IconButton"
import { CellTextAlign, CellTextDecoration, CellTextSize } from "./types"
import Icon from "../Icon/Icon"
import ColorPicker from "./ColorPicker"
import { DEFAULT_ALIGN, DEFAULT_TEXT_SIZE, colorPalette8x7 } from "./constants"

import styles from "./ToolBar.module.sass"

interface ToolBarProps {}

export const ToolBar: React.FC<ToolBarProps> = observer(() => {
  const isMobile = useMediaQuery(AppMediaQueries.minTablet)

  const { selectedRange, editManager } = useMatrixContext()

  const activeCell = editManager.getCellAtPoint(selectedRange.origin)
  const activeCellFormat = activeCell.format

  const disabled = activeCell.readonly

  const vAlign = activeCellFormat?.vAlignment ?? DEFAULT_ALIGN
  const hAlign = activeCellFormat?.hAlignment ?? DEFAULT_ALIGN
  const textSize = activeCellFormat?.textSize ?? DEFAULT_TEXT_SIZE
  const textDecoration = activeCellFormat?.textDecoration
  const backgroundColor = activeCellFormat?.backgroundColor
  const textColor = activeCellFormat?.textColor

  const handleTextSizeChange = (value: CellTextSize) => {
    editManager.applyFormattingToRange({
      textSize: DEFAULT_TEXT_SIZE === value ? undefined : value,
    })
  }

  const handleHAlignChange = (value: CellTextAlign) => {
    editManager.applyFormattingToRange({
      hAlignment: DEFAULT_ALIGN === value ? undefined : value,
    })
  }

  const handleVAlignChange = (value: CellTextAlign) => {
    editManager.applyFormattingToRange({
      vAlignment: DEFAULT_ALIGN === value ? undefined : value,
    })
  }

  const handleDecorationChange = (value: CellTextDecoration) => {
    if (textDecoration == null) {
      editManager.applyFormattingToRange({ textDecoration: [value] })
      return
    }

    const newData = toggleOneOfMany(textDecoration, value)
    editManager.applyFormattingToRange({
      textDecoration: newData.length ? newData : undefined,
    })
  }

  const handleBackgroundChange = (value: string | undefined) => {
    editManager.applyFormattingToRange({
      backgroundColor: value,
    })
  }

  const handleTextColorChange = (value: string | undefined) => {
    editManager.applyFormattingToRange({
      textColor: value,
    })
  }

  const handleResetFormat = () => {
    editManager.resetFormattingForRange()
  }

  const iconSize = isMobile ? "medium" : "small"

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        {textSizeOptions.map((it) => (
          <IconButton
            key={it.name}
            title={it.value}
            active={it.name === textSize}
            size={iconSize}
            disabled={disabled}
            onClick={() => handleTextSizeChange(it.name)}
          >
            {it.icon ? <Icon name={it.icon} /> : it.value}
          </IconButton>
        ))}
      </div>

      <div className={styles.section}>
        {textHAlignOptions.map((it) => (
          <IconButton
            key={it.name}
            title={it.value}
            active={it.name === hAlign}
            size={iconSize}
            disabled={disabled}
            onClick={() => handleHAlignChange(it.name)}
          >
            <Icon name={it.icon ?? "question-mark"} />
          </IconButton>
        ))}
      </div>

      <div className={styles.section}>
        {textVAlignOptions.map((it) => (
          <IconButton
            key={it.name}
            title={it.value}
            active={it.name === vAlign}
            size={iconSize}
            disabled={disabled}
            onClick={() => handleVAlignChange(it.name)}
          >
            <Icon name={it.icon ?? "question-mark"} />
          </IconButton>
        ))}
      </div>

      <div className={styles.section}>
        {textDecorationOptions.map((it) => (
          <IconButton
            key={it.name}
            title={it.value}
            active={textDecoration?.includes(it.name)}
            size={iconSize}
            disabled={disabled}
            onClick={() => handleDecorationChange(it.name)}
          >
            <Icon name={it.icon ?? "question-mark"} />
          </IconButton>
        ))}
      </div>

      <div className={styles.section}>
        <ColorPicker
          title="Background color"
          value={backgroundColor}
          columns={8}
          icon="paint"
          options={colorPalette8x7}
          size={iconSize}
          disabled={disabled}
          onChange={handleBackgroundChange}
        />

        <ColorPicker
          title="Text color"
          value={textColor}
          columns={8}
          icon="font-color"
          options={colorPalette8x7}
          size={iconSize}
          disabled={disabled}
          onChange={handleTextColorChange}
        />
      </div>

      <div className={styles.section}>
        <IconButton
          title="Reset Formatting"
          size={iconSize}
          disabled={disabled}
          onClick={() => handleResetFormat()}
        >
          <Icon name="blur-off" />
        </IconButton>
      </div>
    </div>
  )
})

export default ToolBar

const textSizeOptions: Option<CellTextSize>[] = [
  {
    name: "normal",
    value: "Normal Text",
  },
  {
    name: "h1",
    value: "Heading 1",
    icon: "h-1",
  },
  {
    name: "h2",
    value: "Heading 2",
    icon: "h-2",
  },
  {
    name: "h3",
    value: "Heading 3",
    icon: "h-3",
  },
]

const textHAlignOptions: Option<CellTextAlign>[] = [
  {
    name: "start",
    value: "Align Left",
    icon: "align-left",
  },
  {
    name: "center",
    value: "Align Center",
    icon: "align-center",
  },
  {
    name: "end",
    value: "Align Right",
    icon: "align-right",
  },
]

const textVAlignOptions: Option<CellTextAlign>[] = [
  {
    name: "start",
    value: "Align Top",
    icon: "align-top",
  },
  {
    name: "center",
    value: "Align Middle",
    icon: "align-vertically",
  },
  {
    name: "end",
    value: "Align Bottom",
    icon: "align-bottom",
  },
]

const textDecorationOptions: Option<CellTextDecoration>[] = [
  {
    name: "bold",
    value: "Bold",
    icon: "bold",
  },
  {
    name: "italic",
    value: "Italic",
    icon: "italic",
  },
  {
    name: "strike",
    value: "Strikethrough",
    icon: "strikethrough",
  },
]
