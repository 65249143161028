import { makeAutoObservable } from "mobx"

import { ProductFilter } from "@framework/types/product-filter"
import { ListResponse } from "@framework/types/product"
import { UserAndGroup } from "@framework/types/user"

export default class EntitiesStore {
  constructor() {
    makeAutoObservable(this)
  }

  usersAndGroups: UserAndGroup[] = []

  loadingUserAndGroups: boolean = false

  errorUserAndGroups: string | null = null

  loadingSolutionPermissions: boolean = false

  loadingUpdatePermissions: boolean = false

  userAndGroupsPagination = {
    totalItems: 0,
    totalPages: 0,
    page: 1,
    pageSize: 10,
  }

  selectedUserAndGroups: UserAndGroup[] = []

  allUserAndGroups: UserAndGroup[] = []
}
