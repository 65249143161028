import { makeAutoObservable } from "mobx"

import {
  Identifier,
  IdentifierDetails,
  IdentifierFilter,
  IdentifierPaginationMetaData,
} from "@framework/types/identifier"
import { ConnectorDocument } from "@framework/types/content-manager"

class IdentifierStore {
  loadingCreate = false

  loadingList = false

  loadingUpdate: boolean = false

  loadingDelete: boolean = false

  loading = false

  identifiers: Identifier[] = []

  error: string | null = null

  activeFilter: IdentifierFilter = {
    manufacturers: [],
    includes: true,
  }

  identifiersMetaData: IdentifierPaginationMetaData = {
    pageNum: 1,
    pageSize: 10,
    total: 0,
    totalPages: 0,
  }

  contentPaginationMeta: IdentifierPaginationMetaData | null = null

  identifierContents: ConnectorDocument[] = []

  isContentsLoading = false

  errorList: string = ""

  searchQuery: string = ""

  filterSearchQuery: string = ""

  activePage: number = 1

  identifierDetails: IdentifierDetails | null = null

  constructor() {
    makeAutoObservable(this)
  }
}

export default IdentifierStore
