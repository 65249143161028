import React, { useEffect, useLayoutEffect } from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"

import Table from "@components/ui/BaseTable/Table"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import Pagination from "@components/ui/Pagination/Pagination"
import { useStore } from "@store"
import Text from "@components/ui/Typography/Text"
import useQueryParams from "@components/hooks/useQueryParams"
import { initArray } from "@utils/numberUtils"
import NotFound from "@components/ui/NotFound/NotFound"

import ProductsTableHeader from "./ProductListHeader"
import ProductListRow from "./ProductListRow"
import ProductListRowSkeleton from "./ProductListRowSkeleton"

import styles from "./productList.module.sass"

interface ProductListProps {}

const ProductsList: React.FC<ProductListProps> = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)
  const initialPage = parseInt(queryParams.get("page") || "1", 10)

  const [initialLoad, setInitialLoad] = React.useState(true)

  const updatePageInURL = (page: number) => {
    queryParams.set("page", page.toString())
    navigate({ search: queryParams.toString() }, { replace: true })
  }

  const handlePageChange = ({ selected }: { selected: number }) => {
    const newPage = selected + 1
    setActivePage(newPage)
    updatePageInURL(newPage)
  }

  const searchParams = useQueryParams()

  const manufacturerId = searchParams?.manufacturerId || ""
  const manufacturerName = searchParams?.manufacturerName || ""

  const {
    productComparisonStore: {
      productStore: {
        products,
        loadingProductsList,
        errorProductsList,
        loadProducts,
        productsMetaData: { totalPages },
        searchQuery,
        activeFilter,
        activePage,
        updateActivePage: setActivePage,
        updateActiveFilter,
        updateSearchQuery,
      },
    },
  } = useStore()

  const getProducts = () => {
    const companyIds = activeFilter.manufacturers.map((company) => company.id)

    loadProducts({
      pagination: {
        page: activePage,
      },
      filters: {
        search: searchQuery,
        companyIds,
        categoryIds: activeFilter.identifiers.map((category) => category.id),
        include: activeFilter.includes,
      },
    })
  }

  useLayoutEffect(() => {
    setActivePage(initialPage)
  }, [])

  useEffect(() => {
    getProducts()
  }, [activePage])

  useEffect(() => {
    const isManufacturerActive = !!activeFilter.manufacturers.some(
      (manufacturer) => manufacturer.id === manufacturerId
    )

    if (!isManufacturerActive) {
      navigate(`/products/list?page=${activePage}`, { replace: true })
    }

    if (initialLoad) {
      setInitialLoad(false)
      return
    }

    if (activePage === 1) {
      getProducts()
    } else {
      setActivePage(1)
      navigate(`/products/list?page=1`, { replace: true })
    }
  }, [searchQuery, activeFilter])

  useEffect(() => {
    if (!manufacturerId) return

    updateActiveFilter({
      ...activeFilter,
      manufacturers: [
        ...activeFilter.manufacturers,
        {
          id: manufacturerId,
          name: manufacturerName,
        },
      ],
    })
  }, [manufacturerId])

  useEffect(() => {
    // cleanup all
    return () => {
      updateActiveFilter({
        manufacturers: [],
        identifiers: [],
        includes: true,
      })

      updateSearchQuery("")
    }
  }, [])

  const isEmptyProducts = !searchQuery && products.length === 0

  if (isEmptyProducts && !loadingProductsList && !errorProductsList) {
    return (
      <NotFound className={styles.notFoundBox}>
        No Products have been added yet!
      </NotFound>
    )
  }

  return (
    <div className={styles.productListContainer}>
      <Table className={styles.productList} header={<ProductsTableHeader />}>
        {products.map((productData) => {
          return (
            <ProductListRow
              onDelete={getProducts}
              key={productData.id}
              product={productData}
            />
          )
        })}

        {loadingProductsList &&
          initArray(10, (index) => <ProductListRowSkeleton key={index + 1} />)}

        {errorProductsList && (
          <Text className={styles.alertText} variant="body1" color="redColor">
            {errorProductsList}
          </Text>
        )}

        {!errorProductsList &&
          !loadingProductsList &&
          products.length === 0 && (
            <NotFound className={styles.notFoundBox}>
              No products found
            </NotFound>
          )}
      </Table>
      <EntityPageHeaderLayout
        right={
          <Pagination
            pageCount={totalPages}
            forcePage={activePage - 1}
            onPageChange={handlePageChange}
          />
        }
      />
    </div>
  )
})

export default ProductsList
