/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import IconButton from "@components/ui/IconButton/IconButton"
import Checkbox from "@components/ui/Checkbox/Checkbox"
import Text from "@components/ui/Typography/Text"
import Avatar from "@components/ui/Avatar/Avatar"
import Icon from "@components/ui/Icon/Icon"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import ListItem from "@components/ui/ListItem/ListItem"

import styles from "./Option.module.sass"

export interface OptionProps {
  isEdit?: boolean
  label?: string
  metaInfo?: React.ReactNode
  avatarUrl?: string | null
  checked?: boolean
  onClick?: () => void
  onRemoveClick?: () => void
}

const Option: React.FC<OptionProps> = ({
  isEdit,
  label,
  checked,
  metaInfo,
  avatarUrl,
  onClick,
  onRemoveClick,
}) => {
  return (
    <ListItem onClick={isEdit ? onClick : undefined}>
      <div className={clsx(styles.root)}>
        <div className={styles.body}>
          <Avatar src={avatarUrl} name={label} height={45} width={45} />
          <div>
            <Text variant="h4">{label}</Text>
            {!!metaInfo && (
              <Text variant="caption1" color="text50Color">
                {metaInfo}
              </Text>
            )}
          </div>
        </div>

        {isEdit ? (
          <Checkbox checked={checked} />
        ) : (
          <Tooltip
            content={
              <TooltipContainer placement="left" color="primary">
                Remove
              </TooltipContainer>
            }
          >
            <IconButton
              className={styles.remove}
              rounded
              onClick={onRemoveClick}
            >
              <Icon name="cross" circle />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </ListItem>
  )
}

export default Option
