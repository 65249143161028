import { observer } from "mobx-react-lite"
import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import throttle from "lodash/throttle"

import useSearch from "@components/hooks/useSearch"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import { Option } from "@framework/types/utils"
import { useStore } from "@store/index"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Switch from "@components/ui/Switch/Switch"
import { constructComplexUrlWithParams } from "@utils/textUtils"
import useQuery from "@components/hooks/useQuery"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"

import SynonymTable from "./components/GlossaryTable/SynonymTable"
import AcronymTable from "./components/GlossaryTable/AcronymTable"

import styles from "./Glossary.module.sass"

export enum GlossaryPageMode {
  ACRONYM = "Acronyms",
  SYNONYM = "Synonyms",
}

const modes: Option<GlossaryPageMode>[] = [
  { name: "Acronyms" as GlossaryPageMode, value: GlossaryPageMode.ACRONYM },
  { name: "Synonyms" as GlossaryPageMode, value: GlossaryPageMode.SYNONYM },
]

export const GlossaryPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()

  const [glossaryMode, setGlossaryMode] = useState(GlossaryPageMode.ACRONYM)

  const queryParams = useQuery()

  const [searchProps] = useSearch()

  const loadSearchedGlossaryUnits = useCallback(
    throttle((query: string) => {
      if (glossaryMode === GlossaryPageMode.ACRONYM) {
        loadAcronyms(query)
      } else {
        loadSynonyms(query)
      }
    }, 500),
    [glossaryMode]
  )

  useEffect(() => {
    loadSearchedGlossaryUnits(searchProps.value.trim())
  }, [searchProps.value])

  const uploadFiles = useModal(ModalsTypes.UPLOAD_FILES_MODAL)

  const {
    acronymsStore: {
      loadAcronyms,
      acronymsCollection,
      isAcronymsLoading,
      uploadAcronymsFromFile,
    },
    synonymsStore: {
      loadSynonyms,
      synonymsCollection,
      isSynonymsLoading,
      uploadSynonymsFromFile,
    },
    restrictionsStore: access,
  } = useStore()

  const handleConnect = () =>
    uploadFiles.showModal({
      title: "Glossary",
      text: `File should be CSV format, first column (${
        glossaryMode === GlossaryPageMode.ACRONYM ? "Abbreviation" : "Word"
      }) and second column (${
        glossaryMode === GlossaryPageMode.ACRONYM
          ? "Full Form"
          : "Synonyms - separated by comma"
      })`,
      onUpload: async (files: File[]) => {
        const success = await (glossaryMode === GlossaryPageMode.ACRONYM
          ? uploadAcronymsFromFile(files)
          : uploadSynonymsFromFile(files))
        if (success) {
          if (glossaryMode === GlossaryPageMode.ACRONYM) {
            loadAcronyms()
          } else {
            loadSynonyms()
          }
        }
        return success
      },
      multipleFiles: false,
      extensions: ["text/csv"],
    })

  useEffect(() => {
    navigate(
      constructComplexUrlWithParams(location.pathname, {
        glossaryMode: GlossaryPageMode.ACRONYM,
      })
    )
  }, [])

  useEffect(() => {
    if (glossaryMode === GlossaryPageMode.ACRONYM) {
      loadAcronyms()
    } else {
      loadSynonyms()
    }
  }, [glossaryMode])

  useEffect(() => {
    const glossaryMode = queryParams.get("glossaryMode")

    setGlossaryMode(glossaryMode as GlossaryPageMode)
  }, [location])

  const switchGlossaryModeHandler = () =>
    navigate(
      constructComplexUrlWithParams(location.pathname, {
        glossaryMode:
          glossaryMode === GlossaryPageMode.SYNONYM
            ? GlossaryPageMode.ACRONYM
            : GlossaryPageMode.SYNONYM,
      })
    )

  const isLoading = isAcronymsLoading || isSynonymsLoading

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate("../")}>Glossary</BackButton>
            }
            right={
              !!access.isGlossaryUploadEnabled && (
                <Button
                  color="primary"
                  size="big"
                  onClick={handleConnect}
                  disabled={isLoading}
                >
                  Upload Glossary
                </Button>
              )
            }
          >
            <TextInput
              before={<Icon name="search" />}
              placeholder="Search"
              onChange={searchProps.onChange}
              value={searchProps.value}
            />
          </EntityPageHeaderLayout>

          <div className={styles.switchContainer}>
            <Switch<GlossaryPageMode>
              items={modes}
              checked={glossaryMode}
              onChange={switchGlossaryModeHandler}
            />
          </div>

          <LoadingCover isLoading={isLoading}>
            {glossaryMode === GlossaryPageMode.ACRONYM ? (
              <AcronymTable glossaryGroups={acronymsCollection ?? {}} />
            ) : (
              <SynonymTable glossaryGroups={synonymsCollection ?? {}} />
            )}
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})

export default GlossaryPage
