import React from "react"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import { capitalizeFirstLetter, removeHyphens } from "@utils/textUtils"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import { QueryFilterData } from "@framework/types/query-filter"

import { FormData } from "./FilterSubForm"

import styles from "./FiltersSection.module.sass"

const FiltersSection: React.FC = observer(() => {
  const formik = useFormikContext<FormData>()

  const activeFilters = formik.values.filters ?? []

  const removeFilter = (filter: QueryFilterData) => {
    formik.setFieldValue(
      "filters",
      activeFilters.filter((it) => it.id !== filter.id)
    )
  }

  return (
    <div className={styles.root}>
      {activeFilters.length ? (
        activeFilters.map((filter) => {
          const text = capitalizeFirstLetter(
            removeHyphens(
              filter.name === "manual" ? "Manual Upload" : filter.name
            )
          )
          return (
            <Chip
              title={text}
              variant="rounded"
              color="secondary-solid"
              onClick={() => removeFilter(filter)}
              onRemove={() => removeFilter(filter)}
              className={styles.filterItem}
              key={filter.id}
            >
              {text}
            </Chip>
          )
        })
      ) : (
        <Text variant="caption1" color="text50Color" align="center">
          No Filters applied
        </Text>
      )}
    </div>
  )
})

export default FiltersSection
