import { makeAutoObservable } from "mobx"

import {
  AddProductRequest,
  Product,
  ProductDetails,
  ProductListQueryRequest,
  ProductListResponsePaginationMetaData,
} from "@framework/types/product"
import productsService from "@services/products.service"
import { ConnectorDocument } from "@framework/types/content-manager"

export interface Company {
  id: string
  name: string
}

export interface ProductCategory {
  id: string
  name: string
  companyId?: string
  child?: ProductCategory
}

export interface V2_Products {
  id: string
  name: string
  description: string
  company: Company
  categories: ProductCategory[]
  contentCount: number
}

interface ProductFilter {
  identifiers: ProductCategory[]
  manufacturers: Company[]
  includes?: boolean
}

export default class ProductStore {
  constructor() {
    makeAutoObservable(this)
  }

  searchQuery: string = ""

  filterSearchQuery: string = ""

  activeFilter: ProductFilter = {
    identifiers: [],
    manufacturers: [],
    includes: true,
  }

  activePage: number = 1

  product: Product | null = null

  error: string | null = null

  addProductError: string | null = null

  isLoading = false

  isProductDetailsLoading = false

  isUpdateProductLoading = false

  deletingProducts: { [key: string]: boolean } = {}

  isContentsLoading = false

  addingProduct = false

  products: V2_Products[] = []

  productsMetaData: ProductListResponsePaginationMetaData = {
    page: 1,
    pageSize: 10,
    totalItems: 0,
    totalPages: 0,
  }

  loadingProductsList = true

  productListSignal: AbortController = new AbortController()

  errorProductsList: string | null = null

  productDetails: ProductDetails | null = null

  contentPaginationMeta: {
    page: number
    pageSize: number
    totalPages: number
  } | null = null

  productContents: ConnectorDocument[] = []

  loadProducts = async ({
    filters,
    pagination: { pageSize = 15, ...rest },
  }: ProductListQueryRequest) => {
    this.loadingProductsList = true
    this.errorProductsList = null

    try {
      this.products = []

      this.productListSignal.abort("AbortError")

      this.productListSignal = new AbortController()

      const response = await productsService.getAllProductsList(
        {
          filters,
          pagination: {
            pageSize,
            ...rest,
          },
        },
        this.productListSignal.signal
      )

      this.products = response.data.data
      this.productsMetaData = response.data.pagination
      this.loadingProductsList = false
    } catch (error) {
      console.log({ error })
      if (
        error instanceof Error &&
        (error.name === "AbortError" || error.name === "CanceledError")
      ) {
        return
      }

      this.errorProductsList = "Failed to load products"
      this.loadingProductsList = false
    }
  }

  addProduct = async (productsData: AddProductRequest[]) => {
    this.addingProduct = true
    this.addProductError = null

    try {
      const response = await productsService.createProduct({
        products: productsData,
      })

      this.addProductError = response.data.errors[0]?.message

      return response.data
    } catch (error) {
      this.addProductError = "Failed to add product"
    } finally {
      this.addingProduct = false
    }

    return { success: [], errors: [] }
  }

  updateSearchQuery = (query: string) => {
    this.searchQuery = query
  }

  updateFilterSearchQuery = (query: string) => {
    this.filterSearchQuery = query
  }

  updateActivePage = (page: number) => {
    this.activePage = page
  }

  updateActiveFilter = (filter: ProductFilter) => {
    this.activeFilter = filter
  }
}
