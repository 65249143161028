import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import { Point, Range } from "@framework/types/common"

import { useMatrixContext } from "./MatrixContext"
import { includes, intersection } from "./utils"

import styles from "./Spreadsheet.module.sass"

interface RangeSquareProps {
  shardId: string
  range: Range<Point>
  bgColor?: string
  color?: string
  solid?: boolean
  blurred?: boolean
  withKnob?: boolean
  xBaseIndex?: number
  yBaseIndex?: number
  onKnobMouseDown?: React.MouseEventHandler
}

const RangeSquare = observer(
  React.forwardRef<HTMLDivElement, RangeSquareProps>(
    (
      {
        shardId,
        range,
        solid = false,
        blurred = false,
        color,
        bgColor,
        withKnob = false,
        xBaseIndex = 0,
        yBaseIndex = 0,
        onKnobMouseDown,
      },
      ref
    ) => {
      const { grid } = useMatrixContext()

      const shardGrid = grid.getVisibleGridCache(shardId)
      const visGrid = shardGrid.visibleGridRect

      const rect = intersection(visGrid, range)
      const showKnob = includes(visGrid, range.end)

      const width = grid.getRangeWidth(rect)
      const height = grid.getRangeHeight(rect)

      if (!width || !height) return null

      const shiftedPoint = {
        x: rect.start.x - xBaseIndex,
        y: rect.start.y - yBaseIndex,
      }

      const position = shardGrid.getCellCoordinates(shiftedPoint)

      return (
        <div
          ref={ref}
          className={clsx(styles.selectedRange)}
          style={{
            left: position.x,
            top: position.y,
            width,
            height,
            borderStyle: solid ? "solid" : "dashed",
            backgroundColor: blurred ? bgColor : "transparent",
            borderTopColor:
              rect.start.y === visGrid.start.y ? "transparent" : color,
            borderRightColor:
              rect.end.x === visGrid.end.x ? "transparent" : color,
            borderBottomColor:
              rect.end.y === visGrid.end.y ? "transparent" : color,
            borderLeftColor:
              rect.start.x === visGrid.start.x ? "transparent" : color,
          }}
        >
          {!!withKnob && showKnob && (
            <span
              role="button"
              tabIndex={0}
              aria-label="spread"
              className={styles.knob}
              onMouseDown={onKnobMouseDown}
              style={{ background: color }}
            />
          )}
        </div>
      )
    }
  )
)

export default RangeSquare
