import React from "react"

import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { Option } from "@framework/types/utils"

import MatrixStore from "./state/MatrixStore"
import { pointToCode, rangeSize, totalRangeCells } from "./utils"
import { IContextMenuBuilder } from "./state/ContextMenuManager"

const useDefaultContextMenu = (matrix: MatrixStore) => {
  const addCommentModal = useModal(ModalsTypes.ADD_COMMENT_MODAL)

  React.useEffect(() => {
    const customContextMenuBuilder: IContextMenuBuilder = (context) => {
      const cell = context.editManager.getCellAtPoint(
        context.selectedRange.origin
      )

      const options: Option[] = []

      if (!matrix.readonlyMode) {
        options.push({
          name: "ADD_COMMENT",
          value: "Add Comment",
          icon: "chat-new",
        })

        options.push(
          !cell.readonly
            ? {
                name: "ENABLE_READONLY",
                value: "Make Read-Only",
                icon: "lock",
              }
            : {
                name: "DISABLE_READONLY",
                value: "Make Editable",
                icon: "lock-unlock",
              }
        )
      }

      options.push({
        name: "SPREAD",
        value: "Spread",
        icon: "aspect-ratio",
      })

      const { range } = context.selectedRange

      const selectedRangeSize = rangeSize(range)
      const mainAxis = selectedRangeSize.x < selectedRangeSize.y ? "x" : "y"

      if (totalRangeCells(range) > 1 && !matrix.readonlyMode) {
        const cellNumber = Math.min(range.end.x, range.end.y) + 1

        const unit = mainAxis === "x" ? "column" : "row"

        const currentlyFrozen = context.grid.frozenPoint[mainAxis]

        if (currentlyFrozen === cellNumber) {
          options.push({
            name: "UNFREEZE",
            value: `Unfreeze ${cellNumber} ${unit}`,
            icon: "pushpin",
          })
        } else {
          options.push({
            name: "FREEZE",
            value: `Freeze first ${cellNumber} ${unit}`,
            icon: "pushpin",
          })
        }
      }

      return {
        groups: [
          {
            name: "COMMENT",
            options,
          },
        ],
        handler: (it) => {
          switch (it.name) {
            case "ADD_COMMENT": {
              const cell = context.editManager.getCellAtPoint(
                context.selectedRange.origin
              )
              addCommentModal.showModal({
                onSubmit: cell.setComment,
                title: `Add comment at ${pointToCode(
                  context.selectedRange.origin
                )}`,
              })
              break
            }

            case "ENABLE_READONLY": {
              cell.editManager.setRangeReadonlyMode(true)
              break
            }

            case "DISABLE_READONLY": {
              cell.editManager.setRangeReadonlyMode(false)
              break
            }

            case "SPREAD":
              context.startPreciseSpread("DOWN")
              break

            case "FREEZE": {
              const index = Math.min(range.end.x, range.end.y)
              context.freezeFirst(index + 1, mainAxis)
              break
            }

            case "UNFREEZE": {
              context.freezeFirst(0, mainAxis)
              break
            }

            default:
              return false
          }
          return true
        },
      }
    }

    if (!matrix.readonlyMode) {
      matrix.addCellContextMenuBuilder(utilsCellContextMenuBuilder)
    }

    matrix.addCellContextMenuBuilder(defaultCellContextMenuBuilder)

    matrix.addCellContextMenuBuilder(customContextMenuBuilder)

    return () => {
      matrix.removeCellContextMenuBuilder(utilsCellContextMenuBuilder)

      matrix.removeCellContextMenuBuilder(defaultCellContextMenuBuilder)

      matrix.removeCellContextMenuBuilder(customContextMenuBuilder)
    }
  }, [matrix.readonlyMode])
}

export default useDefaultContextMenu

const utilsCellContextMenuBuilder: IContextMenuBuilder = (context) => ({
  handler: (it) => {
    switch (it.name) {
      case "CLEAN_UP":
        context.editManager.reset()
        break

      default:
        return false
    }

    return true
  },
  groups: [
    {
      name: "UTILS",
      options: [
        {
          name: "CLEAN_UP",
          value: "Clean Up",
          icon: "trash-can",
        },
      ],
    },
  ],
})

const defaultCellContextMenuBuilder: IContextMenuBuilder = (context) => ({
  handler: (it) => {
    switch (it.name) {
      case "CUT":
        context.editManager.cut()
        break

      case "COPY":
        context.editManager.copy()
        break

      case "PASTE":
        context.editManager.paste()
        break

      default:
        return false
    }

    return true
  },
  groups: [
    {
      name: "DEFAULT",
      options: [
        { name: "CUT", value: "Cut", icon: "scissors" },
        { name: "COPY", value: "Copy", icon: "checkbox-multiple-blank" },
        { name: "PASTE", value: "Paste", icon: "clipboard" },
      ],
    },
  ],
})
