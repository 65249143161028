import React from "react"
import { observer } from "mobx-react-lite"

import { useStore } from "@store/index"
import { BaseUserData } from "@framework/types/user"
import NotFound from "@components/ui/NotFound/NotFound"

import useMembershipContext from "./MembershipContext/useMembershipContext"
import Option from "./Option/Option"
import BaseContainer from "./BaseContainer/BaseContainer"
import InfiniteCollection from "./BaseContainer/InfiniteCollection"

interface UsersProps {
  isLoading?: boolean
  onAdd?: () => void
  onRemove?: (userId: string) => Promise<void>
}

const Users: React.FC<UsersProps> = observer(
  ({ isLoading, onAdd, onRemove }) => {
    const multiformContext = useMembershipContext()

    const { selectedUsers, isEditing, handleEdit, updateMembershipData } =
      multiformContext

    const { adminUserCollection } = useStore()

    const users = adminUserCollection.state

    const handleAddUsers = (id: string) => {
      updateMembershipData(id)
    }

    const handleRemoveConfirm = async (id: string) => {
      await onRemove?.(id)
    }

    const optionRenderer = (item: BaseUserData) => {
      return (
        <Option
          avatarUrl={item.avatarURL}
          label={`${item.firstName} ${item.lastName}`}
          metaInfo={item.email}
          checked={selectedUsers.includes(item.id)}
          onClick={() => handleAddUsers(item.id)}
          onRemoveClick={() => handleRemoveConfirm(item.id)}
          isEdit={isEditing}
          key={item.id}
        />
      )
    }

    return (
      <BaseContainer
        editMode={isEditing}
        unit="Users"
        isLoading={isLoading}
        totalSelected={selectedUsers.length}
        onSubmit={() => onAdd?.()}
        onEdit={() => handleEdit(true)}
        onCancel={() => handleEdit(false)}
      >
        {users.total > 0 ? (
          <InfiniteCollection
            unit="User"
            renderItem={optionRenderer}
            total={users.total}
            getItem={adminUserCollection.getByIndex}
          />
        ) : (
          <NotFound />
        )}
      </BaseContainer>
    )
  }
)

export default Users
