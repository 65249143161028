import React from "react"
import { useNavigate } from "react-router-dom"

import Row from "@components/ui/BaseTable/Row"
import Column from "@components/ui/BaseTable/Column"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store"
import { Identifier } from "@framework/types/identifier"
import VerticalItemList from "@pages/admin/components/VerticalItemList"

import ContextMenu from "./ContextMenu"

import styles from "./IdentifierList.module.sass"

interface IdentifierListRowProps {
  identifier: Identifier
  onDelete: () => void
}

function getCategoryBranchPathAndLastBranch(identifier: Identifier): {
  path: string
  lastBranch: string
} {
  const path = []
  let currentBranch = identifier?.categoryBranch
  let lastBranch = ""

  if (!identifier.categoryBranch) {
    return { path: "", lastBranch: identifier.parent?.name }
  }

  while (currentBranch) {
    if (currentBranch.child) {
      path.push(currentBranch.name)
    } else {
      lastBranch = currentBranch.name
    }
    currentBranch = currentBranch.child
  }

  return {
    path: path.join(" / "),
    lastBranch,
  }
}

const IdentifierListRow: React.FC<IdentifierListRowProps> = ({
  identifier,
  onDelete,
}) => {
  const navigate = useNavigate()
  const { restrictionsStore: access } = useStore()
  const handleRowClick = () => {
    if (!access.canViewIdentifier) return
    navigate(`/product-identifiers/view/${identifier.id}`)
  }

  const { path, lastBranch } = getCategoryBranchPathAndLastBranch(identifier)

  return (
    <Row>
      <Column>
        <div
          className={styles.identifierName}
          onClick={handleRowClick}
          tabIndex={-1}
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") handleRowClick()
          }}
        >
          <Text variant="h5">{identifier.name}</Text>
        </div>
      </Column>
      <Column>
        {identifier.parent ? (
          <Text
            className={styles.catName}
            variant="body2"
            as="span"
            color="text50Color"
          >
            {path && <>{path} / </>}
            <Text className={styles.catName} variant="body2" as="span">
              {lastBranch}
            </Text>
          </Text>
        ) : (
          "N/A"
        )}
      </Column>
      <Column>{identifier.company?.name}</Column>
      <Column>
        <Text variant="body2">{identifier.contentCount || 0}</Text>
      </Column>
      <Column>
        <Text variant="body2">
          {identifier.products?.length ? (
            <VerticalItemList
              items={identifier.products}
              initialShownCount={3}
            />
          ) : (
            "N/A"
          )}
        </Text>
      </Column>
      <Column className={styles.actionColumn}>
        <div className={styles.contextMenu}>
          <ContextMenu onDelete={onDelete} identifier={identifier} />
        </div>
      </Column>
    </Row>
  )
}

export default IdentifierListRow
