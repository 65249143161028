import { FormikProvider, useFormik } from "formik"
import React from "react"
import * as yup from "yup"

import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import Templates from "@components/ui/Templates"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"
import { ProductSolutionCategory } from "@framework/types/solution"
import { solutionCategoryOptions } from "@framework/constants/solution-category"
import { stringArrayToOptions } from "@utils/textUtils"
import Switch from "@components/ui/Switch/Switch"
import DocumentDropZone from "@components/ui/DocumentDropZone/DocumentDropZone"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import { toBase64 } from "@utils/file"
import Loader from "@components/ui/Loader/BarLoader"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import Checkbox from "@components/ui/Checkbox/Checkbox"

import styles from "./BasicInfoForm.module.sass"

const categoryOptions = stringArrayToOptions(solutionCategoryOptions)

const allowedExtensions = [".png", ".jpg", ".jpeg"]

export const validationSchema = yup.object({
  image: yup.string().label("Preview Image").required().default(""),

  name: yup.string().label("Name").max(100).min(3).default("").trim(),

  description: yup.string().label("Description").default("").trim(),

  isBeta: yup.boolean(),

  category: yup
    .mixed<ProductSolutionCategory>()
    .label("Category")
    .oneOf([...solutionCategoryOptions])
    .transform((v) => (v === null ? undefined : v))
    .default(solutionCategoryOptions[0]),
})

export type FormData = yup.InferType<typeof validationSchema>

export interface BasicInfoFormProps {
  initialValues?: Partial<FormData>
  isLoading?: boolean
  isEdit?: boolean
  onSubmit?: (data: FormData) => void
  onCancel?: () => void
}

export const BasicInfoForm: React.FC<BasicInfoFormProps> = ({
  initialValues,
  isEdit,
  isLoading,
  onSubmit,
  onCancel,
}) => {
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const handleSubmit = (data: FormData) =>
    onSubmit?.(validationSchema.cast(data))

  const handleCancel = onCancel

  const formik = useFormik<FormData>({
    initialValues: validationSchema.cast(
      { ...initialValues },
      { stripUnknown: true }
    ),
    validationSchema,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  })

  const handleUpdateAvatar = async (file: File[]) => {
    const image = file[0]

    if (!image) {
      formik.setFieldValue("image", undefined)
      return
    }

    formik.setFieldValue("image", await toBase64(image))
  }

  return (
    <form className={styles.root} onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Templates.RollScript
          gutter="32"
          footerSocket={
            <ModalFooterContainer>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                disabled={isLoading}
                after={!!isLoading && <Loader />}
              >
                {isEdit ? "Save" : "Create"}
              </Button>
            </ModalFooterContainer>
          }
        >
          <List gutter="24" className={styles.body}>
            <Label id="description" label="Category" uppercase>
              <div className={styles.categories}>
                {categoryOptions.map((item) => (
                  <Button
                    key={item.name}
                    color={
                      item.name === formik.values.category
                        ? "primary"
                        : "secondary"
                    }
                    size={isTablet ? "small" : "medium"}
                    variant="contained"
                    onClick={() => formik.setFieldValue("category", item.name)}
                  >
                    {item.value}
                  </Button>
                ))}
              </div>
            </Label>

            <Label id="name" label="Cover Image" uppercase>
              <DocumentDropZone
                multiple={false}
                icon="landscape"
                unit="image"
                extensions={allowedExtensions}
                previewImage={formik.values.image || undefined}
                withError={formik.touched.image && !!formik.errors.image}
                description={
                  formik.touched.image && formik.errors.image != null ? (
                    <ErrorChip message={formik.errors.image} />
                  ) : null
                }
                onDrop={handleUpdateAvatar}
              />
            </Label>

            <Label id="name" label="Name" uppercase>
              <FormTextInput placeholder="Enter name" name="name" />
            </Label>

            <Label id="description" label="Description" uppercase>
              <TextAreaInput
                name="description"
                placeholder="Add Description of the solution"
                value={formik.values.description}
                onChange={(v) =>
                  formik.setFieldValue("description", v.currentTarget.value)
                }
              />
            </Label>

            <div className={styles.betaContainer}>
              <div className={styles.betaHeadings}>
                <Text variant="h3">
                  Create as Beta Solution <Chip color="green">Beta</Chip>
                </Text>
                <Text variant="body2">
                  Mark this solution as a Beta release to gather early feedback
                  and validate solutions
                </Text>
              </div>
              <Checkbox
                checked={formik.values.isBeta}
                onClick={() =>
                  formik.setFieldValue("isBeta", !formik.values.isBeta)
                }
              />
            </div>
          </List>
        </Templates.RollScript>
      </FormikProvider>
    </form>
  )
}

export default BasicInfoForm
