import React from "react"
import { Form, FormikProvider, useFormik } from "formik"
import * as yup from "yup"
import filter from "lodash/filter"
import { useAlert } from "react-alert"
import { observer } from "mobx-react-lite"
import { nanoid } from "nanoid"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import { SolutionData } from "@framework/types/solution"

import Footer from "./components/Footer"
import SuggestedQuestionsSubForm, {
  validationSchema as suggestedQuestionsValidationSchema,
} from "./components/SuggestedQuestionsSubForm"
import FiltersSubForm, {
  validationSchema as filtersValidationSchema,
} from "./components/FilterSubForm"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import useModal from "../useModal"
import { ModalsTypes } from "../constants"

import styles from "./SearchFilterModal.module.sass"

export type EditSearchSolutionPresetModalProps = {
  isCreate?: boolean
}

const validationSchema = yup
  .object({})
  .concat(suggestedQuestionsValidationSchema)
  .concat(filtersValidationSchema)

type FormData = yup.InferType<typeof validationSchema>

const EditSearchSolutionPresetModal: React.FC<EditSearchSolutionPresetModalProps> =
  observer(({ isCreate }) => {
    const alert = useAlert()

    const currentModal = useModal(ModalsTypes.EDIT_SEARCH_SOLUTION_PRESET_MODAL)
    const accessPermissionModal = useModal(ModalsTypes.ACCESS_PERMISSIONS_MODAL)

    const { solutionsController } = useController()
    const {
      solutionsStore: { setSolution, solution },
      restrictionsStore: access,
    } = useStore()

    const handleSubmit = async (form: FormData) => {
      if (!solution) return

      const suggestedQuestions = filter(
        form.suggestedQuestions.map((it) => it.value),
        (it) => !!it
      ) as string[]

      const result = await solutionsController.updateSolution(solution?.id, {
        filters: form.filters,
        suggestedQuestions,
      })

      if (result.status === "FAILED") {
        alert.error(result.message)
        return
      }

      alert.success("Solution Preset was updated successfully")

      setSolution(result.data)
      solutionsController.applyFilters(result.data.filters ?? [])
      if (isCreate && access.canUpdateSolutionPermissions) {
        accessPermissionModal.showModal({
          solutionId: solution.id,
          allowAllEntities: solution.allowAllEntities,
          selectedUsersFunc: async () => {
            const permissions =
              await solutionsController.getSolutionPermissions(solution.id)
            return permissions || []
          },
        })
      }
      currentModal.hideModal()
    }

    const formik = useFormik<FormData>({
      validationSchema,
      initialValues: validationSchema.cast(castSolutionToFormData(solution), {
        stripUnknown: true,
      }),
      onSubmit: handleSubmit,
    })

    return (
      <FormikProvider value={formik}>
        <BaseModal
          className={styles.root}
          containerClassName={styles.rootContainer}
          title={<ModalTitle titleText="Edit Search preset" />}
        >
          <Form className={styles.body}>
            <Templates.RollScript
              className={styles.formContainer}
              bodyClassName={styles.container}
              gutter="24"
              footerSocket={
                <Footer
                  className={styles.container}
                  onCancel={() => currentModal.hideModal()}
                />
              }
            >
              <FiltersSubForm />

              <SuggestedQuestionsSubForm />
            </Templates.RollScript>
          </Form>
        </BaseModal>
      </FormikProvider>
    )
  })

export default EditSearchSolutionPresetModal

const castSolutionToFormData = (solution?: SolutionData | null): FormData => {
  return {
    filters: solution?.filters ?? [],
    suggestedQuestions:
      solution?.suggestedQuestions?.map((it) => ({
        id: nanoid(),
        value: it,
      })) ?? [],
  }
}
