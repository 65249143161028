import React from "react"
import { Field } from "formik"

import UserSelect from "@components/prototypes/FilterSidebar/components/UserSelect/UserSelect"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import DateFormField from "@components/prototypes/form-elements/DateFormField"
import DataTypeSelect from "@components/prototypes/FilterSidebar/components/DataTypeSelect/DataTypeSelect"
import { DateRange, FilterMode, filterMode } from "@framework/types/common"
import { stringArrayToOptions } from "@utils/textUtils"
import FormSwitch from "@components/prototypes/form-elements/FormSwitch"
import DataSourceSelect from "@components/prototypes/FilterSidebar/components/DataSourceSelect/DataSourceSelect"
import { useSearchContext } from "@components/prototypes/SearchContext"
import DataConnectorSelect from "@components/prototypes/FilterSidebar/components/DataConnectorSelect/DataConnectorSelect"
import CompanySelect from "@components/prototypes/FilterSidebar/components/ManufacturerSelect/ManufacturerSelect"

export type FilterForm = {
  mode: FilterMode
  contentType?: string[]
  contentSource?: string[]
  connectedBy?: string[]
  contentConnector?: string[]
  connectedDate?: DateRange
  manufacturer?: string[]
}

const options = stringArrayToOptions(filterMode)

const ContentManagerFilterForm: React.FC<{ visible?: boolean }> = ({
  visible,
}) => {
  const context = useSearchContext()

  React.useEffect(() => {
    if (!visible) context.setQuery("")
  }, [visible])

  return (
    <>
      <FormSwitch name="mode" items={options} />

      <Field
        name="contentType"
        label="Content Type"
        component={DataTypeSelect}
      />

      <Field
        name="contentSource"
        label="Content Source"
        component={DataSourceSelect}
      />

      <Field
        name="contentConnector"
        label="Content Connector"
        valueKey="name"
        component={DataConnectorSelect}
      />

      <Field
        name="connectedBy"
        label="Connected By"
        valueKey="id"
        component={UserSelect}
      />

      <Field
        name="connectedDate"
        label="Sync Date"
        format={DD_MM_YYYY_FORMAT}
        component={DateFormField}
      />

      <Field
        name="manufacturer"
        label="Manufacturer"
        component={CompanySelect}
      />
    </>
  )
}

export default ContentManagerFilterForm
