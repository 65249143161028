import React from "react"
import { observer } from "mobx-react-lite"
import capitalize from "lodash/capitalize"

import { direction2D, Direction2D, Point, Range } from "@framework/types/common"
import { Option } from "@framework/types/utils"
import Switch from "@components/ui/Switch/Switch"
import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Box from "@components/ui/Dropdown/Box"
import List from "@components/ui/List/List"

import { useMatrixContext } from "../MatrixContext"
import { rangeToCode } from "../utils"

import styles from "./Tooltip.module.sass"

const options: Option<Direction2D>[] = direction2D.map((name) => ({
  name,
  value: capitalize(name),
}))

export const SpreadRangeForm: React.FC<{
  direction: Direction2D
  from: Range<Point>
  to: Range<Point>
}> = observer(({ direction, from, to }) => {
  const { startPreciseSpread, endPreciseSpreading, abortPreciseSpreading } =
    useMatrixContext()

  return (
    <Box color="primary" className={styles.root}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        className={styles.root}
        onKeyDown={(e) => {
          if (e.key === "Escape") abortPreciseSpreading()
          e.stopPropagation()
        }}
        onMouseUp={(e) => {
          e.stopPropagation()
        }}
        onSubmit={(e) => {
          endPreciseSpreading()
          e.preventDefault()
        }}
      >
        <List gutter="16" overflow="initial">
          <Switch
            size="small"
            items={options}
            checked={direction}
            onChange={startPreciseSpread}
          />

          <List gutter="8" overflow="initial">
            <List direction="row">
              <Text variant="caption1" color="text50Color" inline>
                From:
              </Text>
              <Text variant="caption1">{rangeToCode(from)}</Text>
            </List>

            <List direction="row">
              <Text variant="caption1" color="text50Color" inline>
                To:
              </Text>
              <Text variant="caption1">{rangeToCode(to)}</Text>
            </List>
          </List>

          <Button size="small" color="primary" type="submit" autoFocus>
            Apply
          </Button>
        </List>
      </form>
    </Box>
  )
})

export default SpreadRangeForm
