import snakeCase from "lodash/snakeCase"

import { IconName } from "@components/ui/Icon/IconName"
import {
  DataConnectorContentGroupName,
  DataConnectorContentName,
  DataConnectorSourceName,
  DataSourceNodeName,
} from "@framework/types/upload"
import { DocumentIconType } from "@framework/types/utils"

export const DATA_SOURCES_FAVORITES_KEY = "DATA_SOURCES_FAVORITES"

export const LATEST_DATA_TYPE_CHOICE_KEY = "LATEST_DATA_TYPE_CHOICE"

export type AttachType = "CONNECT" | "UPLOAD"

export const attachTypeMapper = {
  CONNECT: {
    action: "connect",
    runningStatus: "connecting",
    doneStatus: "connected",
  },
  UPLOAD: {
    action: "upload",
    runningStatus: "uploading",
    doneStatus: "uploaded",
  },
}

export interface DataSourceNodeContextProps<
  T = DataSourceNodeName | "unknown"
> {
  /**
   * @deprecated consider to remove along with old data manager
   */
  readonly label: string
  readonly name: T
  readonly displayName: string
  readonly itemName: string
  readonly attachType?: AttachType // CONNECT by default
  readonly icon: DocumentIconType | IconName
  readonly contentItemName?:
    | DataConnectorContentName
    | DataConnectorContentGroupName
  readonly description?: string
  readonly viewOnlyForm?: {
    readonly label?: string
    readonly description?: string
  }
}

export const dataSourceContextPropsMap: Record<
  DataSourceNodeName,
  DataSourceNodeContextProps<DataSourceNodeName>
> = {
  file: {
    itemName: "file",
    name: "file",
    displayName: "File",
    label: "Files",
    attachType: "UPLOAD",
    icon: "folder",
    description: "Add your files",
    viewOnlyForm: {
      description: "Your files",
    },
  },
  page: {
    itemName: "page",
    name: "page",
    displayName: "Page",
    label: "Page",
    attachType: "CONNECT",
    icon: "file",
  },
  website: {
    itemName: "website",
    name: "website",
    displayName: "Website",
    label: "Websites",
    icon: "global",
    description: "Add link to your website",
    viewOnlyForm: {
      description: "Links to your websites",
    },
  },
  "data-connector": {
    itemName: "data connector",
    name: "data-connector",
    displayName: "Data Connector",
    label: "Data Connectors",
    icon: "cloud-filled",
    description: "Connect to internal data sources",
    viewOnlyForm: {
      description: "Your internal data sources",
    },
  },
  manual: {
    itemName: "file",
    name: "manual",
    displayName: "Manual Upload",
    label: "Upload Files",
    attachType: "UPLOAD",
    icon: "folder",
    description: "Just simple drag and drop pdf, pptx, docx, xlsx  ",
    viewOnlyForm: {
      label: "Uploaded",
      description: "Files uploaded from the app",
    },
  },
  folders: {
    itemName: "folder",
    name: "folders",
    displayName: "Folder",
    label: "Folder",
    attachType: "CONNECT",
    icon: "folder",
    contentItemName: "file",
  },
  spaces: {
    itemName: "space",
    name: "spaces",
    displayName: "Space",
    label: "Space",
    attachType: "CONNECT",
    icon: "folder",
    contentItemName: "page",
  },
  smb: {
    itemName: "account",
    name: "smb",
    displayName: "EC2 SMB",
    label: "EC2 SMB",
    icon: "img:aws-ec2",
    contentItemName: "folders",
  },
  "network-drive": {
    itemName: "network drive",
    name: "network-drive",
    displayName: "Network Drive",
    label: "Network Drive",
    icon: "hard-drive",
    contentItemName: "folders",
  },
  box: {
    itemName: "account",
    name: "box",
    displayName: "Box",
    label: "Box",
    icon: "img:box_inc",
    contentItemName: "folders",
  },
  slack: {
    itemName: "account",
    name: "slack",
    displayName: "Slack",
    label: "Slack",
    icon: "img:slack",
    contentItemName: "folders",
  },
  "google-drive": {
    itemName: "account",
    name: "google-drive",
    displayName: "Google Drive",
    label: "Google Drive",
    icon: "img:google-drive",
    contentItemName: "folders",
  },
  blobstorage: {
    itemName: "account",
    name: "blobstorage",
    displayName: "Blob Storage",
    label: "Blob Storage",
    icon: "img:azure-storage",
    contentItemName: "folders",
  },
  azure_blob_storage: {
    itemName: "account",
    name: "azure_blob_storage",
    displayName: "Azure Blob Storage",
    label: "Azure Blob Storage",
    icon: "img:azure-storage",
    contentItemName: "folders",
  },
  "amazon-aws-s3": {
    itemName: "bucket",
    name: "amazon-aws-s3",
    displayName: "S3 Cloud",
    label: "S3 Cloud",
    icon: "img:aws_s3",
    contentItemName: "folders",
  },
  sharepoint: {
    itemName: "account",
    name: "sharepoint",
    displayName: "Sharepoint",
    label: "Sharepoint",
    icon: "img:microsoft-sharepoint",
    contentItemName: "folders",
  },
  sharepoint_online: {
    itemName: "account",
    name: "sharepoint_online",
    displayName: "Sharepoint Online",
    label: "Sharepoint Online",
    icon: "img:microsoft-sharepoint",
    contentItemName: "folders",
  },
  "microsoft-teams": {
    itemName: "account",
    name: "microsoft-teams",
    displayName: "Teams",
    label: "Teams",
    icon: "img:microsoft-teams",
    contentItemName: "folders",
  },
  "microsoft-one-note": {
    itemName: "account",
    name: "microsoft-one-note",
    displayName: "OneNote",
    label: "OneNote",
    icon: "img:one-note",
    contentItemName: "folders",
  },
  "microsoft-one-drive": {
    itemName: "account",
    name: "microsoft-one-drive",
    displayName: "OneDrive",
    label: "OneDrive",
    icon: "img:one-drive",
    contentItemName: "folders",
  },
  "atlassian-confluence": {
    itemName: "account",
    name: "atlassian-confluence",
    displayName: "Confluence Cloud",
    label: "Confluence Cloud",
    icon: "img:confluence",
    contentItemName: "spaces",
  },
  "atlassian-confluence-data-center": {
    itemName: "account",
    name: "atlassian-confluence-data-center",
    displayName: "Confluence Server",
    label: "Confluence Server",
    icon: "img:confluence",
    contentItemName: "spaces",
  },
  "atlassian-jira": {
    itemName: "account",
    name: "atlassian-jira",
    displayName: "Jira",
    label: "Jira",
    icon: "img:jira-software",
    contentItemName: "folders",
  },
  salesforce: {
    itemName: "account",
    name: "salesforce",
    displayName: "Salesforce",
    label: "Salesforce",
    icon: "img:salesforce",
    contentItemName: "folders",
  },
  zendesk: {
    itemName: "account",
    name: "zendesk",
    displayName: "Zendesk",
    label: "Zendesk",
    icon: "img:zendesk",
    contentItemName: "folders",
  },
}

export type DataCategoryStaticDescription = {
  icon: DocumentIconType | IconName
}

// requires revision
export const dataCategoryStaticDescriptionMap: Record<
  string,
  DataCategoryStaticDescription
> = {
  safety_data_sheet: {
    icon: "safety-data-sheet",
  },
  technical_data_sheet: {
    icon: "data-sheets",
  },
  competitor_intelligence: {
    icon: "competitor-intelligence",
  },
  competitor_info: {
    icon: "competitor-intelligence",
  },
  product_handbook: {
    icon: "handbook-fill",
  },
  product_formulation: {
    icon: "handbook-fill",
  },
  voice_of_customer: {
    icon: "voice",
  },
  regulations: {
    icon: "storage",
  },
  regulatory_data: {
    icon: "storage",
  },
  maintenance_manual: {
    icon: "maintenance-manual",
  },
  engineering_design: {
    icon: "engineering-design",
  },
  customer_intelligence: {
    icon: "standards",
  },
  standards: {
    icon: "standards",
  },
  standard_operating_procedure: {
    icon: "maintenance-manual",
  },
  product_bulletin: {
    icon: "standards",
  },
  product_label: {
    icon: "standards",
  },
  product_brochure: {
    icon: "standards",
  },
  lab_notebook: {
    icon: "standards",
  },
  certification: {
    icon: "data-sheets",
  },
}

export const unknownNodeContextProps: DataSourceNodeContextProps<"unknown"> = {
  itemName: "unknown",
  name: "unknown",
  displayName: "Unknown",
  label: "Unknown",
  icon: "img:web_site_source",
}

export const isDataSourceName = (candidate: string) => {
  return dataSourceContextPropsMap[candidate as DataSourceNodeName] != null
}

export const getDataSourceNodeContextProps = <
  T extends DataSourceNodeName = DataSourceNodeName
>(
  type: T
): DataSourceNodeContextProps<T | "unknown"> =>
  (dataSourceContextPropsMap[type] ??
    unknownNodeContextProps) as DataSourceNodeContextProps<T>

/**
 * @deprecated use iconUrl from API
 */
export const getDataSourceCategoryDescription = (
  type: string
): DataCategoryStaticDescription =>
  dataCategoryStaticDescriptionMap[snakeCase(type).toLowerCase()] ?? {
    icon: "global",
  }

export const getDataConnectorsFolderContextByDataConnectorName = (
  type: DataConnectorSourceName | string
) =>
  dataSourceContextPropsMap[
    dataSourceContextPropsMap[type as DataConnectorSourceName]
      ?.contentItemName ?? "folders"
  ]

export const getDataConnectorsFilesContextByDataConnectorName = (
  type: DataConnectorSourceName | string
) =>
  dataSourceContextPropsMap[
    dataSourceContextPropsMap[
      dataSourceContextPropsMap[type as DataConnectorSourceName]
        ?.contentItemName ?? "folders"
    ]?.contentItemName ?? "file"
  ]

export const validExtensions = ["pdf", "doc", "docx", "ppt", "pptx", "xlsx"]
