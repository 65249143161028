import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import { GridSnapshot } from "../types"
import ShardGrid from "./ShardGrid"
import { getReactEdgeSize } from "../utils"

class MatrixGrid {
  readonly overscanCount: number = 3

  readonly defaultCellWidth: number = 200

  readonly defaultCellHeight: number = 50

  readonly totalColumns: number = 27

  readonly totalRows: number = 52

  frozenPoint: Point<number>

  visibleGridCaches: Map<string, ShardGrid>

  gridElement: Element | null = null

  xAxisSizes: Record<number, number>

  yAxisSizes: Record<number, number>

  constructor(data?: GridSnapshot) {
    this.visibleGridCaches = new Map()

    this.xAxisSizes = { ...data?.xAxisSizes }
    this.yAxisSizes = { ...data?.yAxisSizes }

    this.frozenPoint = {
      x: data?.frozenPoint?.x ?? 0,
      y: data?.frozenPoint?.y ?? 0,
    }

    makeAutoObservable(this)
  }

  setGridContainer = (element: Element | null) => {
    this.gridElement = element
  }

  getVisibleGridCache = (shardId: string) => {
    if (!this.visibleGridCaches.has(shardId))
      this.visibleGridCaches.set(shardId, new ShardGrid())

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.visibleGridCaches.get(shardId)!
  }

  getCellWidth = (index: number) => {
    const width = this.xAxisSizes[index]
    if (width == null) return this.defaultCellWidth
    return width
  }

  getCellHeight = (index: number) => {
    const height = this.yAxisSizes[index]
    if (height == null) return this.defaultCellHeight
    return height
  }

  getWidth = (startIndex: number, endIndex: number) => {
    return getReactEdgeSize(startIndex, endIndex, this.getCellWidth)
  }

  getHeight = (startIndex: number, endIndex: number) => {
    return getReactEdgeSize(startIndex, endIndex, this.getCellHeight)
  }

  getRangeWidth = (rect: Range<Point>) => {
    return getReactEdgeSize(rect.start.x, rect.end.x, this.getCellWidth)
  }

  getRangeHeight = (rect: Range<Point>) => {
    return getReactEdgeSize(rect.start.y, rect.end.y, this.getCellHeight)
  }

  resizeCellWidth = (index: number, shift: number) => {
    this.xAxisSizes[index] = this.getCellWidth(index) + shift
  }

  resizeCellHeight = (index: number, shift: number) => {
    this.yAxisSizes[index] = this.getCellHeight(index) + shift
  }

  get rect(): Range<Point> {
    return {
      start: { x: 0, y: 0 },
      end: { x: this.totalColumns - 1, y: this.totalRows - 1 },
    }
  }

  serialize = (): GridSnapshot => {
    return {
      xAxisSizes: { ...this.xAxisSizes },
      yAxisSizes: { ...this.yAxisSizes },
      frozenPoint: { ...this.frozenPoint },
    }
  }
}

export default MatrixGrid
