import React from "react"
import { Grid, GridCellProps } from "react-virtualized"
import { observer } from "mobx-react-lite"

import AxisCell from "./AxisCell"
import { ResizerHandle, ResizeScale } from "./ResizeScale"
import { AxisName } from "./types"
import { useMatrixContext } from "./MatrixContext"

import styles from "./Axis.module.sass"

interface AxisProps {
  axis: AxisName
  width: number
  height: number
  scrollTop?: number
  scrollLeft?: number
  xBaseIndex?: number
  yBaseIndex?: number
  totalColumns?: number
  totalRows?: number
}
export const Axis: React.FC<AxisProps> = observer((props) => {
  const {
    axis,
    width,
    height,
    scrollTop,
    scrollLeft,
    xBaseIndex = 0,
    yBaseIndex = 0,
    totalColumns = 0,
    totalRows = 0,
  } = props

  const [gridRef, setGridRef] = React.useState<Grid | null>()

  const state = useMatrixContext()

  const isColumn = axis === "x"

  const lastColumnIndex = Math.max(totalColumns - 1, 0)
  const lastRowIndex = Math.max(totalRows - 1, 0)

  const renderCell = ({ rowIndex, columnIndex, key, style }: GridCellProps) => {
    const { height, width, ...rest } = style

    const xIndex = Math.max(columnIndex + xBaseIndex, 0)
    const yIndex = Math.max(rowIndex + yBaseIndex, 0)

    const index = isColumn ? xIndex : yIndex

    return (
      <div
        role="none"
        key={key}
        style={rest}
        className={styles.cell}
        onMouseDown={() => {
          state.selectedRange.startRange({
            x: isColumn ? xIndex : 0,
            y: isColumn ? 0 : yIndex,
          })
          state.selectedRange.updateRange({
            x: isColumn ? xIndex : lastColumnIndex,
            y: isColumn ? lastRowIndex : yIndex,
          })
        }}
        onMouseEnter={() => {
          state.selectedRange.updateRange({
            x: isColumn ? xIndex : lastColumnIndex,
            y: isColumn ? lastRowIndex : yIndex,
          })
        }}
      >
        <AxisCell axis={axis} index={index} height={height} width={width} />

        <ResizerHandle
          axis={axis}
          index={index}
          minSize={isColumn ? Number(width) - 80 : Number(height) - 50}
        />
      </div>
    )
  }

  const getCellWidth = isColumn ? state.grid.getCellWidth : () => width
  const getCellHeight = isColumn ? () => height : state.grid.getCellHeight

  React.useEffect(() => {
    gridRef?.recomputeGridSize()
  }, [state.renderTrigger])

  return (
    <>
      <Grid
        ref={setGridRef}
        className={styles.axis}
        cellRenderer={renderCell}
        columnCount={isColumn ? totalColumns : 1}
        rowCount={isColumn ? 1 : totalRows}
        width={width}
        height={height}
        columnWidth={({ index }) => getCellWidth(index + xBaseIndex)}
        rowHeight={({ index }) => getCellHeight(index + yBaseIndex)}
        scrollTop={scrollTop}
        scrollLeft={scrollLeft}
        overscanRowCount={state.grid.overscanCount}
        tabIndex={-1}
      />

      <ResizeScale axis={axis} height={height} width={width} />
    </>
  )
})

export default Axis
