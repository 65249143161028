import React from "react"
import clsx from "clsx"

import styles from "./Filter.module.sass"

export interface FilterProps extends React.HTMLAttributes<HTMLDivElement> {
  left?: React.ReactNode
  leftClassName?: string
  right?: React.ReactNode
  rightClassName?: string
  mainClassName?: string
  align?: "center" | "start" | "end"
}

export const Filter: React.FC<FilterProps> = ({
  left,
  leftClassName,
  right,
  rightClassName,
  children,
  mainClassName,
  align = "center",
  className,
  ...rest
}) => {
  return (
    <header
      className={clsx(styles.root, styles[`align-${align}`], className)}
      {...rest}
    >
      {left != null && (
        <div className={clsx(styles.left, leftClassName)}>{left}</div>
      )}

      <div className={clsx(styles.main, mainClassName)}>{children}</div>

      {right != null && (
        <div className={clsx(styles.right, rightClassName)}>{right}</div>
      )}
    </header>
  )
}

export default Filter
