import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import Icon from "@components/ui/Icon/Icon"
import TextInput from "@components/ui/TextInput/TextInput"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import useDebounce from "@components/hooks/useDebounce"
import { useController, useStore } from "@store"
import useSearch from "@components/hooks/useSearch"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import Button from "@components/ui/Button/Button"

import IdentifierList from "./IdentifierList"
import IdentifierFilter from "../IdentifierFilter"

import styles from "./index.module.sass"

const ProductIdentifiers: React.FC = () => {
  const [searchProps, searchContext] = useSearch()
  const { restrictionsStore: access } = useStore()
  const navigate = useNavigate()
  const {
    identifierController: { getIdentifiers },
  } = useController()

  const { debouncedValue: searchQuery, setDebounce } = useDebounce()
  const addProductIdentifierModal = useModal(
    ModalsTypes.ADD_PRODUCT_IDENTIFIER_MODAL
  )

  const openAddIdentifierModal = () => {
    addProductIdentifierModal.showModal({
      onSuccess: () => {
        getIdentifiers({ pageNum: 1 })
      },
    })
  }
  useEffect(() => {
    setDebounce(searchProps.value)
  }, [searchProps.value])

  const clearSearch = () => {
    searchContext.setQuery("")
  }

  const {
    identifierController: { updateSearchQuery },
  } = useController()

  useEffect(() => {
    updateSearchQuery(searchQuery)
  }, [searchQuery])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <Button
                variant="text"
                size="medium"
                before={<Icon name="line-arrow-down" rotateAngle={90} />}
                onClick={() => navigate("/products/list")}
                className={styles.backButton}
              >
                Manage Product Identifiers
              </Button>
            }
            right={
              access.canCreateIdentifier && (
                <Button
                  variant="contained"
                  color="primary"
                  size="big"
                  onClick={openAddIdentifierModal}
                >
                  Add Identifier
                </Button>
              )
            }
          >
            <TextInput
              {...searchProps}
              className={styles.searchInput}
              before={<Icon name="search" />}
              placeholder="Search"
              after={
                !!searchProps.value && (
                  <Icon name="cross" onClick={clearSearch} />
                )
              }
            />
          </EntityPageHeaderLayout>
          <IdentifierFilter />
          <IdentifierList />
        </div>
      </Container>
    </MainLayout>
  )
}

export default ProductIdentifiers
