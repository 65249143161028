import { makeAutoObservable } from "mobx"
import clamp from "lodash/clamp"
import cloneDeep from "lodash/cloneDeep"

import { Direction2D, Point } from "@framework/types/common"

import {
  equalRanges,
  forEachOfRange,
  intersection,
  merge,
  refToRange,
  shiftRef,
  translateIndexPoint,
} from "../utils"
import RectSelection from "./RectSelection"
import MatrixGrid from "./MatrixGrid"
import EditManager from "./EditManager"
import Resizer from "./Resizer"
import { replaceRefs } from "../parser/utils"
import ContextMenuManager, { IContextMenuBuilder } from "./ContextMenuManager"
import CellValidationManager from "./CellValidationManager"
import {
  CellSnapshot,
  CellValidationRule,
  GridSnapshot,
  MatrixSnapshot,
} from "../types"

type Options = {
  snapshot?: MatrixSnapshot
  readonlyMode?: boolean
}

class MatrixStore {
  readonlyMode: boolean

  grid: MatrixGrid

  editManager: EditManager

  renderTrigger: number = 0

  preciseSpreadRange: {
    selection: RectSelection
    direction: Direction2D
  } | null

  selectedRange: RectSelection

  spreadRange: RectSelection | null

  resizer: Resizer

  contextMenuManager: ContextMenuManager

  validationManager: CellValidationManager

  constructor(options?: Options) {
    this.readonlyMode = options?.readonlyMode ?? false

    this.selectedRange = new RectSelection()
    this.preciseSpreadRange = null
    this.spreadRange = null
    this.grid = this.initGridManager(options?.snapshot?.grid)
    this.resizer = new Resizer()
    this.validationManager = this.initValidationManager()
    this.editManager = this.initEditManager(options?.snapshot?.data)
    this.contextMenuManager = new ContextMenuManager({ context: this })

    makeAutoObservable(this)
  }

  init = (options: { initialValue?: string[][] } = {}) => {
    if (options.initialValue)
      this.editManager.insertValues(options.initialValue)

    this.editManager.init()
  }

  render = () => {
    this.renderTrigger += 1
  }

  moveSelection = (xShift: number, yShift: number) => {
    const { x, y } = this.selectedRange.origin
    const { totalColumns, totalRows } = this.grid

    const point = {
      x: clamp(x + xShift, 0, totalColumns - 1),
      y: clamp(y + yShift, 0, totalRows - 1),
    }

    this.selectedRange.setSelection(point)
  }

  freezeFirst = (index: number = 0, axis: "x" | "y" = "y") => {
    this.grid.frozenPoint[axis] = index
  }

  startPreciseSpread = (direction: Direction2D) => {
    if (this.editManager.isEditing) return

    const selection = new RectSelection()

    selection.startRange(this.selectedRange.range.start)

    if (direction === "DOWN") {
      selection.updateRange({
        ...this.selectedRange.range.end,
        y: this.grid.totalRows - 1,
      })
    }

    if (direction === "UP") {
      selection.updateRange({
        ...this.selectedRange.range.end,
        y: 0,
      })
    }

    if (direction === "LEFT") {
      selection.updateRange({
        ...this.selectedRange.range.end,
        x: 0,
      })
    }

    if (direction === "RIGHT") {
      selection.updateRange({
        ...this.selectedRange.range.end,
        x: this.grid.totalColumns - 1,
      })
    }

    this.preciseSpreadRange = {
      selection,
      direction,
    }
  }

  endPreciseSpreading = () => {
    try {
      if (this.preciseSpreadRange == null) return

      this.spread(this.preciseSpreadRange.selection)
    } finally {
      this.preciseSpreadRange = null
    }
  }

  abortPreciseSpreading = () => {
    this.preciseSpreadRange = null
  }

  startManualSpreading = () => {
    if (this.editManager.isEditing) return

    this.spreadRange = new RectSelection()
    this.spreadRange.startRange(this.selectedRange.range.start)
    this.spreadRange.updateRange(this.selectedRange.range.end)
  }

  updateManualSpreading = (cell: Point) => {
    if (this.spreadRange == null) return

    const selection = this.selectedRange.range

    // down
    if (selection.end.y < cell.y) {
      this.spreadRange.selectRange({
        start: { x: selection.start.x, y: selection.end.y + 1 },
        end: { x: selection.end.x, y: cell.y },
      })
      return
    }

    // up
    if (selection.start.y > cell.y) {
      this.spreadRange.selectRange({
        start: { x: selection.start.x, y: cell.y },
        end: { x: selection.end.x, y: selection.start.y - 1 },
      })
      return
    }

    // right
    if (selection.end.x < cell.x) {
      this.spreadRange.selectRange({
        start: { x: selection.end.x + 1, y: selection.start.y },
        end: { x: cell.x, y: selection.end.y },
      })
      return
    }

    // left
    if (selection.start.x > cell.x) {
      this.spreadRange.selectRange({
        start: { x: cell.x, y: selection.start.y },
        end: { x: selection.start.x - 1, y: selection.end.y },
      })
      return
    }

    this.spreadRange.selectRange(cloneDeep(selection))
  }

  endManualSpreading = () => {
    try {
      if (this.spreadRange == null) return

      this.spread(this.spreadRange)
    } finally {
      this.spreadRange = null
    }
  }

  spread = (spreadRange: RectSelection) => {
    if (equalRanges(this.selectedRange.range, spreadRange.range)) return

    const sourceRect = this.selectedRange.range
    const spreadRect = spreadRange.range

    forEachOfRange(spreadRange.range, (spreadIndex) => {
      const sourceIndex = translateIndexPoint(
        spreadRect,
        sourceRect,
        spreadIndex
      )

      const sourceCell = this.editManager.getCellAtPoint(sourceIndex)
      const spreadCell = this.editManager.getCellAtPoint(spreadIndex)

      // is acceptor cell is readonly
      if (spreadCell.readonly) return

      if (sourceCell.format != null) {
        spreadCell.cloneFormatting(sourceCell.format)
      }

      // is simple user mode
      if (this.readonlyMode) return

      if (sourceCell.state.formula != null) {
        const shift: Point = {
          x: spreadIndex.x - sourceIndex.x,
          y: spreadIndex.y - sourceIndex.y,
        }

        const shiftedRefs = Object.fromEntries(
          sourceCell.state.formula.refs.map((it) => {
            try {
              return [it, shiftRef(it, shift)]
            } catch (error) {
              return [it, "#REF!"]
            }
          })
        )

        const newInput = `=${replaceRefs(
          sourceCell.state.input.slice(1),
          shiftedRefs
        )}`

        spreadCell.setInput(newInput)
      } else {
        spreadCell.setInput(sourceCell.state.input)
      }

      spreadCell.setValidationRule(sourceCell.validationRuleId)

      spreadCell.setReadonlyMode(sourceCell.readonly)

      spreadCell.apply()
    })

    const newSelection = merge(sourceRect, spreadRect)
    this.selectedRange.selectRange(newSelection)
  }

  isValidationRuleRegistered = (name: string) => {
    return this.validationManager.hasRule(name)
  }

  registerValidationRule = (name: string, validation: CellValidationRule) => {
    this.validationManager.registerValidationRule(name, validation)
  }

  applyValidationRule = (ref: string, ruleId: string) => {
    const range = refToRange(ref)

    const rect = intersection(this.grid.rect, range)

    forEachOfRange(rect, (point) => {
      const cell = this.editManager.getCellAtPoint(point)
      cell.setValidationRule(ruleId)
      cell.apply()
    })
  }

  addCellContextMenuBuilder = (builder: IContextMenuBuilder) => {
    this.contextMenuManager.addCellContextMenuBuilder(builder)
  }

  removeCellContextMenuBuilder = (builder: IContextMenuBuilder) => {
    this.contextMenuManager.removeCellContextMenuBuilder(builder)
  }

  serialize = async (): Promise<MatrixSnapshot> => {
    return {
      data: this.editManager.serialize(),
      grid: this.grid.serialize(),
    }
  }

  private initEditManager = (data?: Record<string, CellSnapshot>) => {
    return new EditManager({ context: this, snapshot: data })
  }

  private initValidationManager = () => {
    return new CellValidationManager({
      context: this,
    })
  }

  private initGridManager = (data?: GridSnapshot) => {
    return new MatrixGrid(data)
  }
}

export default MatrixStore
