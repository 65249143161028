import React from "react"
import { useNavigate } from "react-router-dom"

import Row from "@components/ui/BaseTable/Row"
import Column from "@components/ui/BaseTable/Column"
import Text from "@components/ui/Typography/Text"
import { V2_Products } from "@store/product/product.store"
import { useStore } from "@store"

import ContextMenu from "./ContextMenu"

import styles from "./productList.module.sass"

interface ProductListRowProps {
  product: V2_Products
  onDelete: () => void
}

const ProductListRow: React.FC<ProductListRowProps> = ({
  product,
  onDelete,
}) => {
  const navigate = useNavigate()
  const {
    restrictionsStore: access,
    productComparisonStore: { productStore },
  } = useStore()
  const handleRowClick = () => {
    navigate(`/products/view/${product.id}`)
  }
  const flattenedCategories = product.categories.map((cat) => {
    let names = cat.name
    let currentCat = cat.child
    while (currentCat) {
      names += `/${currentCat.name}`
      currentCat = currentCat.child
    }
    return names
  })

  return (
    <Row>
      <Column>
        <div
          className={styles.productName}
          onClick={handleRowClick}
          tabIndex={-1}
          role="button"
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") handleRowClick()
          }}
        >
          <Text variant="h5">{product.name}</Text>
          <Text color="text40Color" variant="body3">
            {product.description}
          </Text>
        </div>
      </Column>
      <Column>
        <Text variant="body2">{product.company?.name}</Text>
      </Column>
      <Column>
        <div>
          {product.categories.length === 0 ? (
            <Text variant="body2" color="text40Color">
              -
            </Text>
          ) : (
            flattenedCategories.map((cat, index) => {
              // Find the position of the last slash
              const lastSlashIndex = cat.lastIndexOf("/")

              // Split the string into two parts: before the last slash and after it
              const beforeLastSlash =
                lastSlashIndex > 0 ? cat.slice(0, lastSlashIndex) : ""
              const afterLastSlash =
                lastSlashIndex > 0 ? cat.slice(lastSlashIndex) : cat

              return (
                <div className={styles.catContainer} key={cat}>
                  <Text
                    className={styles.catName}
                    variant="body2"
                    as="span"
                    color="text50Color"
                    key={cat}
                  >
                    {beforeLastSlash}
                    <Text className={styles.catName} variant="body2" as="span">
                      {afterLastSlash}
                    </Text>
                  </Text>
                </div>
              )
            })
          )}
        </div>
      </Column>
      <Column>
        <Text variant="body2">{product.contentCount}</Text>
      </Column>
      <Column className={styles.actionColumn}>
        {productStore.deletingProducts[product.id] ? (
          <Text variant="body2" color="redColor">
            Deleting
          </Text>
        ) : (
          <div className={styles.contextMenu}>
            <ContextMenu onDelete={onDelete} product={product} />
          </div>
        )}
      </Column>
    </Row>
  )
}

export default ProductListRow
