import React, { useCallback, useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
// import Table from "@components/ui/BaseTable/Table"
// import Row from "@components/ui/BaseTable/Row"
// import TextColumn from "@components/ui/SimpleTable/TextColumn"
// import Column from "@components/ui/BaseTable/Column"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import { CategoryChild } from "@framework/types/product"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Loader from "@components/ui/Loader/BarLoader"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import NotFound from "@components/ui/NotFound/NotFound"
import { ConnectorDocument } from "@framework/types/content-manager"

import styles from "./index.module.sass"

function formatCategories(categories: CategoryChild[]): string[] {
  const formattedCategories: string[] = []

  function traverse(category: CategoryChild, path: string) {
    const currentPath = path ? `${path} / ${category.name}` : category.name

    if (!category.child) {
      formattedCategories.push(currentPath)
    }

    if (category.child) {
      traverse(category.child, currentPath)
    }
  }

  categories.forEach((category) => traverse(category, ""))
  return formattedCategories
}

const ProductViewPage = observer(() => {
  const { id } = useParams()

  const {
    productComparisonStore: { productStore },
  } = useStore()
  const { productController } = useController()
  const [allContents, setAllContents] = useState<ConnectorDocument[]>([])

  const {
    productDetails,
    isProductDetailsLoading,
    isContentsLoading,
    contentPaginationMeta,
    productContents,
  } = productStore

  const hasMoreContent =
    (contentPaginationMeta?.page || 0) <
    (contentPaginationMeta?.totalPages || 0)

  const formattedCategories = useMemo(() => {
    return productDetails?.categories
      ? formatCategories(productDetails.categories)
      : []
  }, [productDetails?.categories])

  const loadMoreContent = useCallback(() => {
    if (hasMoreContent && id) {
      productController.loadMoreProductContentIds(
        (contentPaginationMeta?.page || 1) + 1,
        100,
        id || ""
      )
    }
  }, [contentPaginationMeta, productController])

  const lastItemRef = useInfiniteScroll({
    callback: loadMoreContent,
    isLoading: isContentsLoading,
    hasMore: hasMoreContent,
  })

  useEffect(() => {
    if (id) {
      productController.loadMoreProductContentIds(1, 100, id || "")
      productController.getProductDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (productContents.length) {
      setAllContents([
        ...allContents,
        ...productContents.filter(
          (doc) => !allContents.some((content) => content.id === doc.id)
        ),
      ])
    }
  }, [productContents])

  useEffect(() => {
    setAllContents([])
  }, [])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={
              <BackButton>
                {isProductDetailsLoading ? (
                  <Skeleton
                    className={styles.nameSkeleton}
                    lineHeight={30}
                    minWidth={200}
                  />
                ) : (
                  productDetails?.name
                )}
              </BackButton>
            }
          />
          <LoadingCover
            isLoading={isProductDetailsLoading}
            className={styles.container}
          >
            <div className={styles.mainContent}>
              <Chip variant="rounded" color="green">
                {productDetails?.company?.name}
              </Chip>
              <Text weight="bold" variant="h1" className={styles.mainHeading}>
                {productDetails?.name}
              </Text>
              <Text variant="body2" color="text50Color">
                {productDetails?.description || "N/A"}
              </Text>
              <Text variant="h3" className={styles.header}>
                Product Notes
              </Text>
              <Text variant="body2" color="text50Color">
                {productDetails?.notes || "N/A"}
              </Text>
              {!!formattedCategories.length && (
                <>
                  <Text variant="h3" className={styles.header}>
                    Identifiers
                  </Text>
                  <div className={clsx(styles.identifiers, styles.card)}>
                    {formattedCategories.map((category) => (
                      <Chip
                        key={category}
                        variant="rounded"
                        color="secondary-solid"
                      >
                        {category}
                      </Chip>
                    ))}
                  </div>
                </>
              )}
              {/* don't have data right now, when available wil integrate it */}
              {/* <Text variant="h3" className={styles.header}>
                Attributes
              </Text>
              <div className={clsx(styles.card, styles.attributes)}>
                <Table
                  hideBorder
                  header={
                    <Row>
                      <TextColumn>Product Name</TextColumn>
                      <TextColumn>Manufacturer</TextColumn>
                    </Row>
                  }
                >
                  <Row>
                    <Column> Weight</Column>
                    <Column> Approximately 75 kg</Column>
                  </Row>
                  <Row>
                    <Column> Material</Column>
                    <Column> Primarily metal and plastic components</Column>
                  </Row>
                  <Row>
                    <Column> Voltage</Column>
                    <Column> 100-240 V</Column>
                  </Row>
                </Table>
              </div> */}
            </div>
            <div className={clsx(styles.linkedContent, styles.card)}>
              <Text weight="bold" variant="h3">
                Linked Content
              </Text>
              <div className={styles.linkedContentList}>
                {allContents.map((content, index) => (
                  <div
                    key={content.id}
                    className={styles.content}
                    ref={index === allContents.length - 1 ? lastItemRef : null}
                  >
                    <span>
                      <b>Content</b> : {content.id}
                    </span>
                    <Icon name="arrow-up" rotateAngle={90} />
                  </div>
                ))}
                {!allContents.length && !isContentsLoading && <NotFound />}
                {isContentsLoading && (
                  <div className={styles.loader}>
                    <Loader size="small" />
                  </div>
                )}
              </div>
            </div>
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})
export default ProductViewPage
