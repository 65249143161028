import React, { useEffect } from "react"
import { Field, FieldProps } from "formik"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"

import Templates from "@components/ui/Templates"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import FilterButton from "@components/ui/Button/FilterButton"
import { useStore } from "@store"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import Icon from "@components/ui/Icon/Icon"
import FormSwitch from "@components/prototypes/form-elements/FormSwitch"

import ProductFilterManufacturerField from "./ProductFilterManufacturerField"
import ProductFilterIdentifierField from "./ProductFilterIdentifierField"
import ProductFilterHeader from "../ProductList/ProductFilterHeader"

import styles from "./ProductFilterModal.module.sass"

interface ProductsFilterProps {}

interface ProductFilterButtonProps {
  onToggle: () => void
  onReset: () => void
}

const ProductFilterButton: React.FC<ProductFilterButtonProps> = observer(
  ({ onToggle, onReset }) => {
    const {
      productComparisonStore: {
        productStore: { activeFilter },
      },
    } = useStore()

    const filterCount =
      activeFilter.identifiers.length + activeFilter.manufacturers.length

    return (
      <FilterButton
        size="big"
        color="secondary"
        counter={filterCount}
        onOpen={onToggle}
        onClean={onReset}
      >
        Filter
      </FilterButton>
    )
  }
)

const ProductFilterSearchInput: React.FC<FieldProps<string[]>> = observer(
  ({ form, field: { name } }) => {
    const {
      productComparisonStore: {
        productStore: { updateFilterSearchQuery, activeFilter },
      },
    } = useStore()

    useEffect(() => {
      updateFilterSearchQuery(form.values[name])
    }, [form.values[name]])

    useEffect(() => {
      form.setFieldValue("manufacturers", activeFilter.manufacturers)
      form.setFieldValue("identifiers", activeFilter.identifiers)
    }, [activeFilter])

    return (
      <FormTextInput
        value={form.values[name]}
        name="searchQuery"
        placeholder="Search"
        before={<Icon name="search" />}
      />
    )
  }
)

const options = [
  { name: "true", value: "Include" },
  { name: "false", value: "Exclude" },
]

const ProductFilterForm: React.FC = observer(() => {
  const {
    productComparisonStore: {
      productStore: { filterSearchQuery },
    },
  } = useStore()
  return (
    <>
      <FormSwitch items={options} name="includes" />

      <Field
        name="manufacturers"
        label="Manufacturer"
        valueKey="id"
        component={ProductFilterManufacturerField}
        searchQuery={filterSearchQuery}
      />

      <Field
        name="identifiers"
        label="Identifier"
        valueKey="id"
        component={ProductFilterIdentifierField}
      />
    </>
  )
})

const ProductsFilter: React.FC<ProductsFilterProps> = observer(() => {
  const {
    productComparisonStore: {
      productStore: { updateActiveFilter, activeFilter },
    },
  } = useStore()

  return (
    <Templates.Header
      className={styles.filterHeader}
      left={
        activeFilter.identifiers.length + activeFilter.manufacturers.length ? (
          <ProductFilterHeader />
        ) : null
      }
      right={
        <FilterSidebar
          initialValue={{
            identifiers: [],
            manufacturers: [],
            searchQuery: "",
            includes: "true",
          }}
          defaultValue={{
            identifiers: [],
            manufacturers: [],
            searchQuery: "",
            includes: "true",
          }}
          onChange={(newValue) => {
            updateActiveFilter({
              manufacturers: toJS(newValue.manufacturers) || [],
              identifiers: toJS(newValue.identifiers) || [],
              includes: toJS(newValue.includes) === "true",
            })
          }}
          targetSlot={({ onToggle, onReset }) => (
            <ProductFilterButton onReset={onReset} onToggle={onToggle} />
          )}
          headerSlot={
            <Field name="searchQuery" component={ProductFilterSearchInput} />
          }
        >
          <ProductFilterForm />
        </FilterSidebar>
      }
    />
  )
})

export default ProductsFilter
