import React from "react"
import moment from "moment"
import { observer } from "mobx-react-lite"

import Avatar from "@components/ui/Avatar/Avatar"
import { AnswerStatus, ExpertQuestion } from "@framework/types/question"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"

import styles from "./QuestionItem.module.sass"

interface QuestionItemProps {
  data: ExpertQuestion
}

const QuestionItem: React.FC<QuestionItemProps> = observer(({ data }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Text variant="h6">{getAssignmentStatusLabel(data)}</Text>

        <QuestionStatusBadge data={data} />
      </div>

      <div className={styles.body}>
        <Avatar
          name={data.author.name}
          src={data.author.avatarUrl ?? ""}
          width={32}
          height={32}
        />

        <div className={styles.content}>
          <Text variant="h4" weight="bold">
            {data.author.name ?? "Unknown"}
          </Text>

          <Text variant="body2">{data.question}</Text>
        </div>
      </div>

      <div className={styles.footer}>
        <Text variant="caption2" color="text50Color">
          {moment(data.lastUpdated).fromNow()}
        </Text>
      </div>
    </div>
  )
})

export default QuestionItem

const QuestionStatusBadge: React.FC<{ data: ExpertQuestion }> = observer(
  ({ data }) => {
    const isVerified = data.status === AnswerStatus.ANSWERED

    const iconName = isVerified ? "shield-check-fill" : "time"
    const iconColor = isVerified ? "green" : "gold"
    const message = isVerified ? "Expert Verified" : "Pending"

    return (
      <Text variant="h5" className={styles.alignCenter}>
        <Icon name={iconName} color={iconColor} inline />{" "}
        <Text variant="h6" inline>
          {message}
        </Text>
      </Text>
    )
  }
)

const getAssignmentStatusLabel = (data: ExpertQuestion) => {
  if (!data.delegatedToSelf) return "Open Question"
  return "Assigned to you"
}
