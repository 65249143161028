import React, { useEffect, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"

import useAvatarKnowledgePreviewStore from "@store/avatar-knowledge/useAvatarKnowledgePreview"
import { useController, useStore } from "@store"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DataCategoryEntity } from "@framework/types/knowledge"
import useTopicsCollection from "@pages/expert-insights/Topics/hooks/useTopicsCollection"
import Icon from "@components/ui/Icon/Icon"
import IconChip from "@components/ui/Chip/IconChip"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import useActiveAvatar from "../ActiveAvatarContext/useActiveAvatar"

import styles from "./HomeInfo.module.sass"

const getContentLinkUrl = (
  category: any,
  avatarId: string,
  isKibanaEnabled: boolean
) => {
  if (category.isExpertInsights) {
    return `expert-insights/${avatarId}`
  }
  return isKibanaEnabled
    ? `/content-manager?contentTypeId=${category.id}`
    : `data-source/${avatarId}/${category.id}`
}

const DataTypes = observer(() => {
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)
  const { avatar } = useActiveAvatar()
  const { avatarKnowledgePreviewController, expertInsightsController } =
    useController()
  const { restrictionsStore: access } = useStore()

  const collection = useAvatarKnowledgePreviewStore(
    avatar?.id
  ).dataCategoriesStore
  const topicsCollection = useTopicsCollection(avatar?.id)

  useEffect(() => {
    if (avatar?.id) {
      avatarKnowledgePreviewController.loadDataCategories(avatar.id)
      expertInsightsController.loadTopicList(avatar.id, "", false, "view")
    }
  }, [avatar?.id, avatarKnowledgePreviewController, expertInsightsController])

  const getIcon = (
    category: DataCategoryEntity | { isExpertInsights: boolean }
  ) => {
    if ("isExpertInsights" in category && category.isExpertInsights) {
      return (
        <IconChip className={styles.expertsIcon}>
          <Icon color="primary" name="user-insight" />
        </IconChip>
      )
    }

    const icon = (category as DataCategoryEntity).iconUrl ?? "global"

    return <DocumentIcon className={styles.productIcon} icon={icon} />
  }

  const getCount = (category: DataCategoryEntity) =>
    (parseInt(category.totalFiles || "0", 10) || 0) +
    (parseInt(category.totalWebsites || "0", 10) || 0)

  const categoryLinks = useMemo(() => {
    const allCategories = [
      ...(topicsCollection.total > 0
        ? [
            {
              id: "expertInsights",
              name: "Expert Insights",
              count: topicsCollection.total,
              isExpertInsights: true,
            },
          ]
        : []),
      ...(collection.data?.map((category) => ({
        ...category,
        count: getCount(category),
        isExpertInsights: false,
      })) || []),
    ]

    return allCategories
      .filter((category) => category.count > 0)
      .sort((a, b) => b.count - a.count)
      .map((category) => (
        <Link
          key={category.id}
          state={category.isExpertInsights ? "/home" : ""}
          aria-disabled
          className={styles.link}
          to={getContentLinkUrl(
            category,
            avatar.id,
            access.isContentManagerEnabled
          )}
        >
          <div className={styles.product}>
            <div className={styles.iconWrapper}>
              {getIcon(category)}
              <span>{category.name}</span>
            </div>
            <span>{category.count}</span>
          </div>
        </Link>
      ))
  }, [collection.data, avatar?.id, topicsCollection.total])

  if (!avatar) return null
  if (collection.isLoading)
    return <Skeleton count={5} lineHeight={30} minWidth={100} />
  if (collection.data?.length === 0) return null

  return (
    <>
      <Text variant={isTablet ? "h5" : "h3"}>Content Types</Text>
      <div className={styles.sectionList}>
        <div className={styles.listWrapper}>{categoryLinks}</div>
      </div>
    </>
  )
})

export default DataTypes
