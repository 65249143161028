import React from "react"
import { observer } from "mobx-react-lite"

import SelectionRange from "./SelectionRange"
import { useMatrixContext } from "./MatrixContext"
import EditorCell from "./EditorCell"
import SelectionSquare from "./SelectionSquare"
import PreciseSpreadRange from "./PreciseSpreadRange"

const GOLD_BORDER_COLOR = "rgb(255, 190, 78)"
const GOLD_BACKGROUND_COLOR = "rgba(255, 190, 78, 0.2)"

interface SelectionLayerProps {
  shardId: string
  xBaseIndex?: number
  yBaseIndex?: number
}

export const SelectionLayer: React.FC<SelectionLayerProps> = observer(
  ({ children, xBaseIndex = 0, yBaseIndex = 0, shardId }) => {
    const state = useMatrixContext()

    const isSelectedEditing = state.editManager.isCellEditing(
      state.selectedRange.origin
    )

    return (
      <>
        <SelectionSquare
          shardId={shardId}
          cell={state.selectedRange.origin}
          xBaseIndex={xBaseIndex}
          yBaseIndex={yBaseIndex}
        />

        {state.spreadRange != null && (
          <SelectionRange
            shardId={shardId}
            range={state.spreadRange.range}
            xBaseIndex={xBaseIndex}
            yBaseIndex={yBaseIndex}
          />
        )}

        <SelectionRange
          shardId={shardId}
          xBaseIndex={xBaseIndex}
          yBaseIndex={yBaseIndex}
          range={state.selectedRange.range}
          blurred={state.selectedRange.isRange}
          withKnob={
            !state.selectedRange?.rangeStarted &&
            !state.spreadRange?.rangeStarted &&
            !isSelectedEditing
          }
          solid
          onKnobMouseDown={(e) => {
            state.startManualSpreading()
            e.preventDefault()
            e.stopPropagation()
          }}
        />

        {state.preciseSpreadRange != null && (
          <PreciseSpreadRange
            shardId={shardId}
            xBaseIndex={xBaseIndex}
            yBaseIndex={yBaseIndex}
            selection={state.preciseSpreadRange.selection}
            direction={state.preciseSpreadRange.direction}
          />
        )}

        {state.editManager.activeCellState?.validRefEntries.map(
          ([name, range]) => (
            <SelectionRange
              shardId={shardId}
              xBaseIndex={xBaseIndex}
              yBaseIndex={yBaseIndex}
              range={range}
              solid={false}
              blurred
              color={GOLD_BORDER_COLOR}
              bgColor={GOLD_BACKGROUND_COLOR}
              key={name}
            />
          )
        )}

        {state.editManager.refSelectingRange != null && (
          <SelectionRange
            shardId={shardId}
            xBaseIndex={xBaseIndex}
            yBaseIndex={yBaseIndex}
            range={state.editManager.refSelectingRange.range}
            solid={false}
            key="ref_selection"
            blurred
            color={GOLD_BORDER_COLOR}
            bgColor={GOLD_BACKGROUND_COLOR}
          />
        )}

        {isSelectedEditing && (
          <EditorCell
            shardId={shardId}
            xBaseIndex={xBaseIndex}
            yBaseIndex={yBaseIndex}
            cell={state.selectedRange.origin}
            key={state.editManager.activeCellId}
          />
        )}

        {children}
      </>
    )
  }
)

export default SelectionLayer
