import CompanyAPI from "@services/company.service"
import {
  CreateCompanyRequest,
  LinkCompanyRequest,
} from "@framework/types/company"
import RootStore from "@store/RootStore"

import CompanyStore from "./company.store"

class CompanyController {
  private companyStore: CompanyStore

  constructor(injections: RootStore) {
    this.companyStore = injections.companyStore
  }

  fetchCompanies = async () => {
    this.companyStore.loadingCompanies = true
    this.companyStore.error = null
    try {
      const response = await CompanyAPI.getCompanies()
      this.companyStore.companies = response.data?.map((company, index) => ({
        ...company,
        linkedPublicCompanies: company.linkedPublicCompanies?.filter(
          (item) => !!item
        ),
      }))
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loadingCompanies = false
    }
  }

  fetchTrialInstanceCompanies = async () => {
    this.companyStore.loadingCompanies = true
    this.companyStore.error = null
    try {
      const response = await CompanyAPI.getTrialInstanceCompanies()
      this.companyStore.trialInstanceCompanies = response.data
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loadingCompanies = false
    }
  }

  createOrUpdateCompany = async (data: CreateCompanyRequest, id?: string) => {
    this.companyStore.loading = true
    this.companyStore.error = null
    try {
      const response = await (id
        ? CompanyAPI.updateCompany(data, id)
        : CompanyAPI.createCompany(data))
      if (response.status === 200) {
        return true
      }
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loading = false
    }
    return false
  }

  linkCompanies = async (data: LinkCompanyRequest) => {
    this.companyStore.loading = true
    this.companyStore.error = null
    try {
      const response = await CompanyAPI.linkCompanies(data)
      if (response.status === 200) {
        return true
      }
    } catch (error: any) {
      this.companyStore.error = error.message
    } finally {
      this.companyStore.loading = false
    }
    return false
  }
}

export default CompanyController
