import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { useStore } from "@store"

import SuggestedQuestions from "./SuggestedQuestions"

import styles from "./AppControlContainer.module.sass"

type AppControlContainerProps = {
  moveDown: boolean
  title?: string
  description?: string
  hideRecentQuestions?: boolean
}

const AppControlContainer: React.FC<AppControlContainerProps> = observer(
  ({ moveDown, title, description, children, hideRecentQuestions = false }) => {
    const isTablet = useMediaQuery(AppMediaQueries.minTablet)
    const isMobile = useMediaQuery(AppMediaQueries.minMobile)
    const isSmallScreen = useMediaQuery(AppMediaQueries.wideDesktop)

    const {
      factFinderSolutionStore: { searchFlowStore },
    } = useStore()

    const showQuestionOnTop =
      !searchFlowStore.flowSequence.length &&
      searchFlowStore.isFlowReset &&
      !hideRecentQuestions

    const showInitialQuestions =
      !moveDown && !hideRecentQuestions && !searchFlowStore.isFlowReset

    return (
      <div className={clsx(styles.root, { [styles.down]: moveDown })}>
        <div
          className={clsx(styles.centerText, {
            [styles.moveLeft]: isSmallScreen,
          })}
        >
          {!moveDown && (
            <div className={styles.header}>
              <Text
                variant={isMobile ? "h4" : isTablet ? "h3" : "h2"}
                align="center"
                weight="semibold"
              >
                {title}
              </Text>
              <Text align="center" color="text70Color" weight="normal">
                {description}
              </Text>
            </div>
          )}

          {children}

          <div className={styles.footer}>
            <SuggestedQuestions />
          </div>
        </div>
      </div>
    )
  }
)

export default AppControlContainer
