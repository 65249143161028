import React, { useEffect, useState } from "react"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as Yup from "yup"
import { useNavigate, useParams } from "react-router-dom"
import { useAlert } from "react-alert"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import { TextAreaInput } from "@components/ui/TextInput/TextInput"
import List from "@components/ui/List/List"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import FormTextInput from "@components/prototypes/form-elements/FormTextInput"
import { useController, useStore } from "@store"
import SelectOption from "@components/ui/Dropdown/SelectOption"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import MultiSelect from "@components/ui/MultiSelect/MultiSelect"
import Loader from "@components/ui/Loader/BarLoader"
import getCategoryFromChild from "@utils/category"

import LinkedContentTable from "./LinkedContentTable"

import styles from "./index.module.sass"

const validationSchema = Yup.object({
  companyId: Yup.string().required("Manufacturer is required"),
  name: Yup.string().required("Name is required"),
  description: Yup.string().optional(),
  categoryIds: Yup.array().of(Yup.string()).optional(),
})

const ProductEditPage = observer(() => {
  const { id } = useParams()
  const navigate = useNavigate()
  const alert = useAlert()
  const { contentManagerController, productController } = useController()
  const [contentIds, setContentIds] = useState<{
    added: string[]
    deleted: string[]
  }>({ added: [], deleted: [] })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      companyId: "",
      name: "",
      description: "",
      categoryIds: [] as string[],
      notes: "",
    },
    onSubmit: async ({ companyId, name, notes, categoryIds, description }) => {
      const data = {
        companyId,
        name,
        notes: notes || "",
        categoryIds,
        description,
        addedDocumentIds: contentIds.added,
        deletedDocumentIds: contentIds.deleted,
      }
      if (id) {
        const success = await productController.updateProductDetails(id, data)
        if (success) {
          alert.success("Successfully updated")
        } else {
          alert.error("Failed to update product")
        }
      }
    },
  })
  const {
    contentManagerStore,
    productComparisonStore: { productStore },
  } = useStore()

  const { companies, categories, loadingCategories } = contentManagerStore || {}
  const { productDetails, isProductDetailsLoading, isUpdateProductLoading } =
    productStore

  useEffect(() => {
    contentManagerController.getCompanies()
  }, [])

  useEffect(() => {
    if (id) {
      productController.getProductDetails(id)
    }
  }, [id])

  useEffect(() => {
    if (productDetails) {
      formik.setValues({
        companyId: productDetails.company.id,
        name: productDetails.name,
        description: productDetails.description,
        categoryIds: productDetails?.categories?.map(
          (item) => getCategoryFromChild(item).id
        ),
        notes: productDetails.notes,
      })
      contentManagerController.getCategories(productDetails.company.id)
    }
  }, [productDetails])

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={<BackButton>{productDetails?.name}</BackButton>}
            right={
              <List
                direction="row"
                gutter="16"
                overflow="initial"
                justify="flex-end"
              >
                <Button
                  className={styles.headerButton}
                  color="secondary"
                  size="big"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>

                <Button
                  className={styles.headerButton}
                  color="primary"
                  size="big"
                  disabled={isUpdateProductLoading}
                  onClick={formik.submitForm}
                  after={isUpdateProductLoading && <Loader size="small" />}
                >
                  Save
                </Button>
              </List>
            }
          />
          <LoadingCover
            isLoading={isProductDetailsLoading}
            className={styles.container}
          >
            <div className={styles.mainContent}>
              <FormikProvider value={formik}>
                <div className={styles.inputGrid}>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Product Name</Text>
                    <FormTextInput
                      name="name"
                      key="name"
                      placeholder="Enter Product name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Identifier Name</Text>
                    <MultiSelect
                      isOptionsLoading={loadingCategories}
                      isRounded
                      hideMoreItems
                      placeholder="Select identifiers..."
                      onSelectionChange={(selectedItems) => {
                        formik.setFieldValue("categoryIds", selectedItems)
                      }}
                      defaultSelectedItems={
                        loadingCategories ? [] : formik.values.categoryIds
                      }
                      variant="secondary"
                      options={
                        formik.values.companyId
                          ? categories?.map((category) => {
                              return {
                                id: category.id,
                                name: category.name,
                                value: category.name,
                              }
                            })
                          : []
                      }
                      error={!!formik.errors.categoryIds?.length}
                    />
                  </div>
                  <div className={styles.inputContainer}>
                    <Text variant="body2">Manufacturer</Text>
                    <MultiSelect
                      placeholder="Select the manufacturer"
                      variant="secondary"
                      options={companies.map((company) => ({
                        id: company.id,
                        name: company.name,
                        value: company.name,
                      }))}
                      defaultSelectedItems={[formik.values.companyId]}
                      onSelectionChange={(selectedItems) => {
                        const selectedCompanyId = selectedItems[0] || ""
                        formik.setFieldValue("companyId", selectedCompanyId)
                        formik.setFieldValue("categoryIds", [])
                        contentManagerController.getCategories(
                          selectedCompanyId
                        )
                      }}
                      isRounded
                      isSingleSelect
                      error={!!formik.errors.companyId}
                    />
                  </div>
                </div>
                <div className={styles.inputContainer}>
                  <Text variant="body2">Product Description</Text>
                  <TextAreaInput
                    name="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    withError={
                      !!(
                        formik.touched.description && formik.errors.description
                      )
                    }
                    onBlur={formik.handleBlur}
                    type="textarea"
                  />
                </div>
                <div className={styles.inputContainer}>
                  <Text variant="body2">Product Notes</Text>
                  <TextAreaInput
                    name="notes"
                    value={formik.values.notes}
                    onChange={formik.handleChange}
                    withError={!!(formik.touched.notes && formik.errors.notes)}
                    onBlur={formik.handleBlur}
                    type="textarea"
                  />
                </div>
                {/* hidden till attributes not available */}
                {/* <div className={styles.attributes}>
                  <Text variant="h2" weight="bold">
                    Attribute Value <Icon name="info" />
                  </Text>
                  <Table
                    header={
                      <Row>
                        <TextColumn>Name</TextColumn>
                        <TextColumn>Value</TextColumn>
                      </Row>
                    }
                  >
                    <Row>
                      <Column> Weight</Column>
                      <Column> Approximately 75 kg</Column>
                    </Row>
                    <Row>
                      <Column> Material</Column>
                      <Column> Primarily metal and plastic components</Column>
                    </Row>
                    <Row>
                      <Column> Voltage</Column>
                      <Column> 100-240 V</Column>
                    </Row>
                  </Table>
                </div> */}

                {!isProductDetailsLoading && (
                  <LinkedContentTable
                    contentIds={contentIds}
                    setContentIds={setContentIds}
                  />
                )}
              </FormikProvider>
            </div>
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})
export default ProductEditPage
