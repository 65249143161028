import clamp from "lodash/clamp"
import * as yup from "yup"

import { initArrayByLength } from "@utils/numberUtils"

import { ArgumentValidationReport } from "./errors/FormulaValidationError"
import { FunctionArgumentDescription } from "./types"

export const ARGUMENT_IS_EMPTY_MESSAGE = "should be valuable"

export const ARGUMENT_IS_REQUIRED_MESSAGE = "argument is missing"

export const ARGUMENT_IS_STRING_MESSAGE = "should be a valid string"

export const ARGUMENT_IS_NUMBER_MESSAGE = "should be a valid number"

export const defaultStringValidator = yup
  .string()
  .typeError(ARGUMENT_IS_STRING_MESSAGE)
  .min(1, ARGUMENT_IS_EMPTY_MESSAGE)

export const defaultOptionalStringValidator = yup
  .string()
  .typeError(ARGUMENT_IS_STRING_MESSAGE)
  .nullable()

export const defaultNumberValidator = yup
  .number()
  .typeError(ARGUMENT_IS_NUMBER_MESSAGE)

export const numberOrArrayOfNumber = yup.mixed().when("isArray", {
  is: Array.isArray,
  then: yup
    .array()
    .of(defaultNumberValidator)
    .min(1, ARGUMENT_IS_EMPTY_MESSAGE),
  otherwise: defaultNumberValidator,
})

export const stringOrArrayOfStrings = yup.mixed().when("isArray", {
  is: Array.isArray,
  then: yup
    .array()
    .of(defaultStringValidator)
    .min(1, ARGUMENT_IS_EMPTY_MESSAGE),
  otherwise: defaultStringValidator,
})

export const arrayOfStrings = yup
  .array()
  .of(defaultOptionalStringValidator)
  .min(1, ARGUMENT_IS_EMPTY_MESSAGE)

export const optionalArrayOfStrings = yup
  .array()
  .of(defaultOptionalStringValidator)
  .optional()

export const validateArgumentsNumber = (
  totalArguments: number,
  requiredArgumentsNum: number,
  maxArgumentsNum: number
): string | null => {
  if (totalArguments > maxArgumentsNum)
    return `Wrong number of arguments. Expected ${maxArgumentsNum} arguments maximum, but got ${totalArguments} arguments`

  if (totalArguments < requiredArgumentsNum)
    return `Wrong number of arguments. Expected at least ${requiredArgumentsNum} arguments, but got ${totalArguments} arguments`

  return null
}

export const validateArguments = (
  args: any[],
  argDescriptions: FunctionArgumentDescription[],
  requiredArgumentsNum: number,
  maxArgumentsNum: number
): ArgumentValidationReport[] => {
  const totalArgsToValidate = clamp(
    args.length,
    requiredArgumentsNum,
    maxArgumentsNum
  )

  return initArrayByLength(totalArgsToValidate).reduce<
    ArgumentValidationReport[]
  >((acc, index) => {
    const argDescriptor =
      argDescriptions[Math.min(argDescriptions.length - 1, index)]

    const value = args[index]

    const isRequired = index < requiredArgumentsNum

    const { validator } = argDescriptor

    const error = validateValue(value, validator, isRequired)

    if (error) acc.push({ name: argDescriptor.displayName, error, index })

    return acc
  }, [])
}

export const validateValue = (
  value: any,
  validationScheme: yup.AnySchema | undefined,
  isRequired: boolean
): string | null => {
  try {
    if (validationScheme) {
      const validator = isRequired
        ? validationScheme.required(ARGUMENT_IS_REQUIRED_MESSAGE)
        : validationScheme

      validator.validateSync(value)
    } else if (isRequired) return ARGUMENT_IS_REQUIRED_MESSAGE
  } catch (error: any) {
    if (typeof error.message === "string") return error.message
    return "Unexpected error"
  }
  return null
}
