import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"

interface IdentifierFilterHeaderProps {}

type ChipLabel = "Manufacturer" | "Identifier"

const IdentifierFilterHeader: React.FC<IdentifierFilterHeaderProps> = observer(
  () => {
    const {
      identifierStore: { activeFilter },
    } = useStore()

    const {
      identifierController: { updateActiveFilter },
    } = useController()

    const maxChipsToDisplay = 4

    const allChips = React.useMemo(() => {
      return [
        ...activeFilter.manufacturers.map((manufacturer) => ({
          label: "Manufacturer" as ChipLabel,
          value: manufacturer.name,
          id: manufacturer.id,
        })),
      ]
    }, [activeFilter.manufacturers])

    const removeChip = (chipId: string, label: ChipLabel) => {
      if (label === "Manufacturer") {
        updateActiveFilter({
          ...activeFilter,
          manufacturers: activeFilter.manufacturers.filter((manufacturer) => {
            return manufacturer.id !== chipId
          }),
        })
      }
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Text>Applied Filters: </Text>

        {allChips.slice(0, maxChipsToDisplay).map((chipData) => (
          <Chip
            key={chipData.id}
            variant="rounded"
            color="secondary"
            onRemove={() => {
              removeChip(chipData.id, chipData.label)
            }}
            style={{
              border: "none",
              marginLeft: "0.5rem",
            }}
          >
            {chipData.label}: {chipData.value}
          </Chip>
        ))}

        {allChips.length > maxChipsToDisplay && (
          <Text style={{ marginLeft: "0.5rem" }}>
            +{allChips.length - maxChipsToDisplay} more
          </Text>
        )}

        <Button
          variant="slim"
          color="default"
          size="small"
          style={{
            marginLeft: "1rem",
          }}
          onClick={() => {
            updateActiveFilter({
              ...activeFilter,
              manufacturers: [],
            })
          }}
        >
          Clear All
        </Button>
      </div>
    )
  }
)

export default IdentifierFilterHeader
