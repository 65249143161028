import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { useController, useStore } from "@store/index"
import { SolutionData, WorkFlowType } from "@framework/types/solution"
import mainRoutes from "@root/main.routes"

interface ContextMenuProps {
  solution: SolutionData
}

const ContextMenu: React.FC<ContextMenuProps> = observer(({ solution }) => {
  const navigate = useNavigate()

  const {
    userSetupStore,
    restrictionsStore: access,
    solutionsStore,
  } = useStore()

  const { solutionsController } = useController()

  const { isOpened, handleToggle, setOpened } = useToggle()

  const editModal = useModal(ModalsTypes.EDIT_SOLUTION_MODAL)
  const editSearchPresetModal = useModal(
    ModalsTypes.EDIT_SEARCH_SOLUTION_PRESET_MODAL
  )
  const deleteModal = useModal(ModalsTypes.CONFIRM_DELETE_MODAL)
  const accessPermissionModal = useModal(ModalsTypes.ACCESS_PERMISSIONS_MODAL)
  const handleEdit = () => {
    editModal.showModal({
      solution,
      workflowType: solution.workflowType,
      onSubmit: () => {
        solutionsController.loadAvatarSolutions(userSetupStore.avatarId)
      },
    })
  }

  const handleEditPreset = () => {
    navigate(mainRoutes.solutionEdit(solution.workflowType, solution.id))

    if (solution.workflowType === WorkFlowType.SEARCH) {
      solutionsStore.setSolution(solution)
      editSearchPresetModal.showModal({})
    }
  }

  const handleDelete = () =>
    deleteModal.showModal({
      message: "Sure you want to remove this solution?",
      onSubmit: async () => {
        await solutionsController.deleteSolution(solution.id)
        solutionsController.loadAvatarSolutions(userSetupStore.avatarId)
        return true
      },
    })

  const handleAccessPermission = async () => {
    accessPermissionModal.showModal({
      solutionId: solution.id,
      allowAllEntities: solution.allowAllEntities,
      selectedUsersFunc: async () => {
        const permissions = await solutionsController.getSolutionPermissions(
          solution.id
        )
        return permissions || []
      },
      obSubmit: () => {
        solutionsController.loadAvatarSolutions(userSetupStore.avatarId)
      },
    })
  }

  if (!access.canUpdateSolution) return null
  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer color="primary" placement="bottom">
          <>
            <MenuItem icon="edit" onClick={handleEdit} key="edit">
              Edit Preview
            </MenuItem>
            <MenuItem icon="standards" onClick={handleEditPreset} key="edit">
              Edit Preset
            </MenuItem>
            {access.canUpdateSolutionPermissions && (
              <MenuItem
                icon="user-settings-fill"
                onClick={handleAccessPermission}
                key="edit"
              >
                Access Permission
              </MenuItem>
            )}
            <MenuItem
              color="red"
              icon="trash-can"
              onClick={handleDelete}
              key="logout"
            >
              Delete
            </MenuItem>
          </>
        </TooltipContainer>
      }
    >
      <IconButton
        size="medium"
        active={isOpened}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleToggle()
        }}
      >
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
