/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import filter from "lodash/filter"

import TextInput from "@components/ui/TextInput/TextInput"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer, {
  TooltipContainerProps,
} from "@components/ui/Tooltip/TooltipContainer"
import Icon from "@components/ui/Icon/Icon"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import useToggle from "@components/hooks/useToggle"
import useSearch from "@components/hooks/useSearch"
import { queryFilter } from "@utils/textUtils"

import styles from "./SelectOption.module.sass"

export type Unit = string | [string, string]

export interface CompanyPickerProps {
  value?: string
  placeholder?: string
  /**
   * @description unit name to display
   * @example: "item" or ["entity", "entities"]
   */
  unit?: Unit
  options?: string[] | null
  isLoading?: boolean
  disabled?: boolean
  placement?: TooltipContainerProps["placement"]
  label?: (value: string) => string | undefined
  onChange?: (value: string) => void
  menuClassName?: string
  error?: string | null
}

export const SelectOption: React.FC<CompanyPickerProps> = observer(
  ({
    options: initialOptions,
    value = "",
    placeholder,
    unit = "option",
    disabled = false,
    isLoading = false,
    placement,
    menuClassName,
    error = null,
    label,
    onChange,
  }) => {
    const popperToggle = useToggle(false)

    const [searchProps, searchHelpers] = useSearch()

    const renderLabel = (option: string) => {
      return label?.(option) ?? option
    }

    const filteredOptions = React.useMemo(() => {
      const predicate = queryFilter(searchProps.value)
      return filter(initialOptions ?? [], (it) => predicate(renderLabel(it)))
    }, [initialOptions, searchProps.value])

    React.useEffect(() => {
      searchHelpers.setQuery(popperToggle.isOpened ? "" : renderLabel(value))
    }, [popperToggle.isOpened, value])

    const renderContent = (options: string[]) => (
      <TooltipContainer
        className={clsx(
          styles.menuContainer,
          { [styles.disabled]: disabled },
          menuClassName
        )}
        contentClassName={styles.tooltipContainer}
        color="primary"
        placement={placement}
        margin="3px"
      >
        {isLoading ? (
          <Skeleton
            count={4}
            spacing={10}
            lineHeight={32}
            minWidth={100}
            maxWidth={100}
          />
        ) : (
          <div className={styles.menu}>
            {options.map((option, idx, list) => (
              <React.Fragment key={option}>
                <div
                  onClick={() => {
                    onChange?.(option)
                    popperToggle.setOpened(false)
                  }}
                  className={styles.valueItem}
                >
                  <span>{renderLabel(option)}</span>
                  {option === value && (
                    <Icon
                      name="done"
                      className={styles.checkIcon}
                      color="primary"
                    />
                  )}
                </div>
                {idx < list.length - 1 && <span className={styles.divider} />}
              </React.Fragment>
            ))}
            {options?.length === 0 && (
              <div className={styles.noResults}>
                No {getUnitText(unit, true)} found
              </div>
            )}
          </div>
        )}
      </TooltipContainer>
    )

    const renderPlaceholder = (active: string | null) => {
      if (active) return renderLabel(active)

      if (placeholder) return placeholder

      if (!placeholder && unit) {
        const unitText = getUnitText(unit, false)
        return `Select ${unitText}...`
      }

      return "Select..."
    }

    return (
      <Tooltip
        mode="onFocus"
        show={popperToggle.isOpened}
        disabled={disabled}
        onChange={popperToggle.setOpened}
        content={renderContent(filteredOptions)}
        className={styles.root}
      >
        <TextInput
          {...searchProps}
          placeholder={renderPlaceholder(value)}
          className={styles.root}
          withError={!!error}
          disabled={disabled}
          after={
            error && (
              <div className={styles.after}>
                <ErrorChip message={error} messagePlacement="left" />
              </div>
            )
          }
        />
      </Tooltip>
    )
  }
)

const getUnitText = (unit: Unit, multiple: boolean) => {
  if (Array.isArray(unit)) {
    if (multiple) return unit[1] ?? "items"
    return unit[0] ?? "item"
  }

  if (multiple) return `${unit}s` ?? "items"
  return unit ?? "item"
}

export default SelectOption
