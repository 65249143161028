import React from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import Button from "@components/ui/Button/Button"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { useStore } from "@store"

import ManufacturerTable from "./components/ManufacturerTable"

import styles from "./Manufacturer.module.sass"

const ManufacturerPage = observer(() => {
  const navigate = useNavigate()
  const { restrictionsStore: access } = useStore()
  const modal = useModal(ModalsTypes.ADD_MANUFACTURER_MODAL)
  //  uncomment when BE search available
  //   const [searchProps, searchContext] = useSearch()
  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate(-1)}>Manufacturer</BackButton>
            }
            right={
              access.canAddManufacturer && (
                <Button
                  onClick={() => modal.showModal({})}
                  color="primary"
                  size="big"
                >
                  Add Manufacturer
                </Button>
              )
            }
          >
            {/* uncomment when BE search available */}
            {/* <TextInput
              before={<Icon name="search" />}
              placeholder="Search"
              after={
                !!searchProps.value && (
                  <Icon
                    name="cross"
                    onClick={() => searchContext.setQuery("")}
                  />
                )
              }
            /> */}
          </EntityPageHeaderLayout>
          <ManufacturerTable />
        </div>
      </Container>
    </MainLayout>
  )
})

export default ManufacturerPage
