import React from "react"
import { observer } from "mobx-react-lite"

import MenuItem from "@components/containers/Sidebar/MenuItem"
import useToggle from "@components/hooks/useToggle"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import Icon from "@components/ui/Icon/Icon"
import IconButton from "@components/ui/IconButton/IconButton"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import { UserData } from "@framework/types/user"
import { useStore } from "@store/index"

interface ContextMenuProps {
  user: UserData
}

const ContextMenu: React.FC<ContextMenuProps> = observer(({ user }) => {
  const { restrictionsStore: access } = useStore()

  const { isOpened, handleToggle, setOpened } = useToggle()

  const rolesModal = useModal(ModalsTypes.ASSIGN_USER_ROLES_MODAL)
  const deleteModal = useModal(ModalsTypes.CONFIRM_USER_DELETE_MODAL)
  const resetPasswordModal = useModal(ModalsTypes.CHANGE_USER_PASSWORD_MODAL)

  const userId = user.id

  const handleRoles = () => rolesModal.showModal({ userId })

  const handlePasswordReset = () => resetPasswordModal.showModal({ userId })

  const handleDelete = () => deleteModal.showModal({ user })

  return (
    <Tooltip
      show={isOpened}
      onClose={() => setOpened(false)}
      mode="onFocus"
      content={
        <TooltipContainer color="primary" placement="bottom">
          <MenuItem icon="key-2" onClick={handleRoles} key="edit">
            Change Role
          </MenuItem>

          {access.canResetPassword && (
            <MenuItem
              icon="lock-unlock"
              onClick={handlePasswordReset}
              key="reset"
            >
              Reset Password
            </MenuItem>
          )}

          {access.canDeleteUser && (
            <MenuItem
              color="red"
              icon="trash-can"
              onClick={handleDelete}
              key="logout"
            >
              Delete User
            </MenuItem>
          )}
        </TooltipContainer>
      }
    >
      <IconButton size="medium" active={isOpened} onClick={handleToggle}>
        <Icon name="dots" />
      </IconButton>
    </Tooltip>
  )
})

export default ContextMenu
