import React from "react"
import ReactDOM from "react-dom"
import { Grid, GridCellProps, ScrollParams } from "react-virtualized"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import Cell from "./Cell"
import { GridStylesCache } from "./types"
import { useMatrixContext } from "./MatrixContext"
import { renderedSectionToRange } from "./utils"
import SelectionLayer from "./SelectionLayer"

import styles from "./Spreadsheet.module.sass"

interface TableBodyShardProps {
  shardId: string
  width?: number
  height?: number
  xBaseIndex?: number
  yBaseIndex?: number
  totalColumns?: number
  totalRows?: number
  hiddenScroll?: boolean
  scrollTop?: number
  scrollLeft?: number
  onScroll?: (value: ScrollParams) => void
}

export const TableBodyShard: React.FC<TableBodyShardProps> = observer(
  ({
    shardId,
    width = 0,
    height = 0,
    xBaseIndex = 0,
    yBaseIndex = 0,
    totalColumns = 0,
    totalRows = 0,
    hiddenScroll = false,
    scrollTop,
    scrollLeft,
    onScroll,
  }) => {
    const state = useMatrixContext()

    const [gridElement, setGridElement] = React.useState<Element | null>(null)

    const gridRef = React.useRef<Grid | null>()

    const shardGrid = state.grid.getVisibleGridCache(shardId)

    const onBodyRef = (ref: Grid | null) => {
      gridRef.current = ref
      setGridElement(
        (document.getElementById(shardId)?.firstChild as Element) ?? null
      )
    }

    const renderBodyCell = ({
      key,
      columnIndex,
      rowIndex,
      style,
    }: GridCellProps) => {
      return (
        <div key={key} style={{ ...style, overflow: "hidden" }} tabIndex={-1}>
          <Cell
            columnIndex={columnIndex + xBaseIndex}
            rowIndex={rowIndex + yBaseIndex}
            height={style.height}
            width={style.width}
          />
        </div>
      )
    }

    React.useEffect(() => {
      gridRef.current?.recomputeGridSize()
    }, [state.renderTrigger])

    React.useEffect(() => {
      shardGrid.setGridStylesCache(
        // eslint-disable-next-line no-underscore-dangle
        (gridRef.current as any)?._styleCache as GridStylesCache
      )
    }, [shardGrid.visibleGridRect])

    React.useEffect(() => {
      if (state.editManager.isEditing) {
        const cell = state.selectedRange.origin
        gridRef.current?.scrollToCell({
          columnIndex: Math.max(cell.x - xBaseIndex, 0),
          rowIndex: Math.max(cell.y - yBaseIndex, 0),
        })
      }
    }, [state.editManager.isEditing])

    return (
      <>
        <Grid
          className={clsx(styles.grid, { [styles.hiddenScroll]: hiddenScroll })}
          id={shardId}
          ref={onBodyRef}
          cellRenderer={renderBodyCell}
          columnCount={totalColumns}
          rowCount={totalRows}
          width={width}
          height={height}
          scrollTop={scrollTop}
          scrollLeft={scrollLeft}
          overscanRowCount={state.grid.overscanCount}
          overscanColumnCount={state.grid.overscanCount}
          columnWidth={({ index }) =>
            state.grid.getCellWidth(index + xBaseIndex)
          }
          rowHeight={({ index }) =>
            state.grid.getCellHeight(index + yBaseIndex)
          }
          onScroll={onScroll}
          onSectionRendered={(e) => {
            const shift = { x: xBaseIndex, y: yBaseIndex }
            state.grid
              .getVisibleGridCache(shardId)
              .setVisibleRect(renderedSectionToRange(e, shift))
          }}
          tabIndex={-1}
          key={shardId}
        />

        {gridElement != null
          ? ReactDOM.createPortal(
              <SelectionLayer
                shardId={shardId}
                xBaseIndex={xBaseIndex}
                yBaseIndex={yBaseIndex}
              />,
              gridElement as Element
            )
          : null}
      </>
    )
  }
)

export default TableBodyShard
