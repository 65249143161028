import React from "react"
import { useField } from "formik"

import ErrorChip from "@components/ui/ErrorChip/ErrorChip"
import { TextInput, TextInputProps } from "@components/ui/TextInput/TextInput"

import styles from "./FormTextInput.module.sass"

export interface FormTextInputProps extends TextInputProps {
  name: string
}

export const FormTextInput: React.FC<FormTextInputProps> = ({
  name,
  after,
  ...rest
}) => {
  const [field, meta] = useField<any>(name)

  const hasError = !!meta.error && meta.touched

  return (
    <TextInput
      {...rest}
      name={name}
      defaultValue={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      withError={hasError}
      after={
        (after != null || hasError) && (
          <>
            {hasError && (
              <div className={styles.after}>
                <ErrorChip
                  messagePlacement="left"
                  message={meta.error ?? "Unexpected Error"}
                />
              </div>
            )}
            {after}
          </>
        )
      }
    />
  )
}

export default FormTextInput
