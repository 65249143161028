import { nanoid } from "nanoid"

import { AppUIConfig } from "@framework/types/theme"

export const defaultAppConfig: AppUIConfig = {
  id: nanoid(),

  typography: {
    variables: {
      "main-font-family": "'Inter', arial, sans-serif",
    },
  },

  copy: {
    align: "left",
    logo: "NESH",
    mentionNesh: true,
    instanceName: "Nesh",
  },

  support: {
    supportPageURL: "https://nesh.atlassian.net/servicedesk/customer/portals",
    supportButtonName: "Help",
  },

  services: {},

  authorization: {
    signUp: {
      classic: {
        disabled: false,
      },
      external: {
        disabled: true,
        buttonText: "Create Account",
        redirectUrl: "/",
      },
    },
    login: {
      classic: {
        disabled: false,
      },
      SSO: {
        disabled: true,
        buttonText: "Log In with SSO",
      },
    },
  },
}

export default null
