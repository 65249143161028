import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import {
  ContentCitationData,
  ExpertInsightCitationData,
  SearchCitationData,
} from "@store/search/search-citations.store"
import UsersBubbles from "@components/ui/UsersBubbles/UsersBubbles"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import Avatar from "@components/ui/Avatar/Avatar"
import { IconName } from "@components/ui/Icon/IconName"

import { CitationBadge } from "./CitationBadge"

import styles from "./CitationPreview.module.sass"

interface CitationPreviewProps {
  active?: boolean
  mobile?: boolean
  data: SearchCitationData
  onClick: React.MouseEventHandler
}

export const CitationPreview: React.FC<CitationPreviewProps> = observer(
  ({ onClick, data: citationData, mobile, active }) => {
    const renderTitle = () => {
      if (citationData.isExpertAnswer) {
        const title = citationData.question || "N/A"
        return <span title={title}>Q: {title}</span>
      }

      const title = citationData.contentId || "N/A"
      return (
        <span title={title}>
          <b>Content ID:</b> {citationData.contentId}
        </span>
      )
    }

    const renderExpertVerified = () => {
      return (
        <Text variant="h5">
          <Icon name="shield-check-fill" color="green" inline /> Expert Verified
        </Text>
      )
    }

    const renderExperts = (data: ExpertInsightCitationData) => {
      if (data.verifiedBy.length)
        return (
          <UsersBubbles
            size="medium"
            users={data.verifiedBy}
            total={data.verifiedBy.length}
            suffix="Verified by Experts"
          />
        )

      return null
    }

    const renderConnectedBy = (data: ContentCitationData) => {
      if (data.connectedBy?.name)
        return (
          <div className={styles.row}>
            <Avatar name={data.connectedBy.name} width={32} height={32} />
            <div className={styles.content}>
              <Text variant="h6" color="text50Color">
                Connected By
              </Text>

              <Text variant="body2">{data.connectedBy.name}</Text>
            </div>
          </div>
        )

      return null
    }

    const renderSourceName = (data: ContentCitationData) => {
      if (data.contentSourceName) {
        const sourceDescription = getDataSourceNodeContextProps(
          data.contentSourceName
        )

        return (
          <div className={styles.row}>
            <DocumentIcon icon={sourceDescription.icon} size="medium" />{" "}
            <div className={styles.content}>
              <Text variant="h6" color="text50Color">
                Content Source
              </Text>
              <Text variant="body2">{sourceDescription.displayName}</Text>
            </div>
          </div>
        )
      }
      return null
    }

    const renderContentType = (data: ContentCitationData) => {
      if (data.contentType) {
        return (
          <div className={styles.row}>
            <DocumentIcon
              icon={
                data.contentType.iconUrl
                  ? (data.contentType.iconUrl as IconName)
                  : "global"
              }
              size="medium"
            />
            <div className={styles.content}>
              <Text variant="h6" color="text50Color">
                Content Type
              </Text>
              <Text variant="body2">{data.contentType.name}</Text>
            </div>
          </div>
        )
      }
      return null
    }

    const renderConnectorName = (data: ContentCitationData) => {
      if (data.connectorName && data.contentSourceName) {
        const sourceDescription = getDataSourceNodeContextProps(
          data.contentSourceName
        )

        const isManual = sourceDescription.name === "manual"
        const isWebsite = sourceDescription.name === "website"

        if (isManual || isWebsite) return null

        return (
          <div className={styles.row}>
            <DocumentIcon icon={sourceDescription.icon} size="medium" />
            <div className={styles.content}>
              <Text variant="h6" color="text50Color">
                Content Connector
              </Text>
              <Text variant="body2">{data.connectorName}</Text>
            </div>
          </div>
        )
      }
      return null
    }

    return (
      <button
        type="button"
        className={clsx(styles.root, {
          [styles.active]: active,
        })}
        onClick={onClick}
        key={citationData.id}
      >
        <Text
          className={styles.header}
          variant={mobile ? "h5" : "h4"}
          align="center"
        >
          Citation
          <CitationBadge citationNumber={citationData.citationNumber} />
        </Text>

        <Text variant="h6" className={styles.title}>
          {renderTitle()}
        </Text>

        <div className={styles.body}>
          {citationData.isExpertAnswer ? (
            <>
              {/* uncomment if there will be requirement to show */}
              {/* {renderExpertVerified()} */}
              {renderExperts(citationData)}
            </>
          ) : (
            <>
              {renderSourceName(citationData)}
              {renderConnectorName(citationData)}
              {renderConnectedBy(citationData)}
              {renderContentType(citationData)}
            </>
          )}
        </div>
      </button>
    )
  }
)

export default CitationPreview
