import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import { useStore } from "@store"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import mainRoutes from "@root/main.routes"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import Text from "@components/ui/Typography/Text"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { DocumentIconType } from "@framework/types/utils"
import { capitalizeEachFirstLetter, removeHyphens } from "@utils/textUtils"
import { DataSourceStatusReport } from "@framework/types/upload"

import styles from "./HomeInfo.module.sass"

const getSourceName = (source: DataSourceStatusReport) => {
  const isDataConnector = source.sourceType === "data-connector"
  const desc = getDataSourceNodeContextProps(source.name)

  if (desc.name === "manual") {
    return isDataConnector ? desc.displayName : desc.label
  }

  if (desc.name === "website") {
    return `${desc.displayName}${isDataConnector ? "" : " (Old)"}`
  }

  return capitalizeEachFirstLetter(removeHyphens(source.name, true))
}

const DataSources = observer(() => {
  const {
    uploadStore: { dataSourceDetails },
    restrictionsStore: access,
  } = useStore()
  const navigate = useNavigate()
  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)

  const { isLoading, data } = dataSourceDetails.state

  const validOptions = React.useMemo(
    () => data.filter((it) => access.testDataSourceAccess(it.name)),
    [data, access]
  )

  const filteredValidOptions = React.useMemo(() => {
    return validOptions
      .filter((source) => ((source?.totalFiles || source?.count) ?? 0) > 0)
      .sort((a, b) => {
        const countA = (a.totalFiles || a.count) ?? 0
        const countB = (b.totalFiles || b.count) ?? 0
        return countB - countA
      })
  }, [validOptions])

  const navigateToDataSource = (source: DataSourceStatusReport) => {
    const isOldConnector =
      source.sourceType !== "data-connector" &&
      ["website", "manual"].includes(source.name)

    return navigate(
      isOldConnector
        ? mainRoutes.upload([source.name])
        : access.isContentManagerEnabled
        ? `${mainRoutes.contentManager()}?contentSource=${source.name}`
        : mainRoutes.upload()
    )
  }

  if (isLoading) {
    return <Skeleton count={5} lineHeight={30} minWidth={100} />
  }

  if (data.length === 0) {
    return null
  }

  return (
    <>
      <Text variant={isTablet ? "h5" : "h3"}>Content Sources</Text>

      <div className={styles.sectionList}>
        <div className={styles.listWrapper}>
          {filteredValidOptions.map((source) => {
            const desc = getDataSourceNodeContextProps(source.name)
            return (
              <div
                onClick={() => navigateToDataSource(source)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ")
                    navigateToDataSource(source)
                }}
                role="button"
                tabIndex={0}
                key={source.name + source.sourceType}
                className={styles.product}
              >
                <div className={styles.iconWrapper}>
                  <DocumentIcon
                    className={styles.productIcon}
                    icon={desc.icon as DocumentIconType}
                  />
                  <span>{getSourceName(source)}</span>
                </div>
                <span>{source?.totalFiles || source?.count || 0}</span>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
})

export default DataSources
