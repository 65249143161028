import { AxiosResponse } from "axios"

import { UserAndGroup } from "@framework/types/user"

import HttpService from "./http.service"

export interface GetUserAndGroupsResponse {
  data: UserAndGroup[]
  pagination: {
    totalItems: number
    totalPages: number
    page: number
    pageSize: number
  }
}

export interface GetUserAndGroupsRequest {
  page: number
  pageSize?: number
  query?: string
}

class EntitiesApi extends HttpService {
  getUserAndGroups = (
    params: GetUserAndGroupsRequest
  ): Promise<AxiosResponse<GetUserAndGroupsResponse>> =>
    this.get("ts/entities", true, params)
}

export default new EntitiesApi()
