import clsx from "clsx"
import React from "react"

import Icon from "@components/ui/Icon/Icon"

import styles from "./CollapseHeader.module.sass"

export interface CollapseHeaderProps {
  open: boolean
  title?: string
  className?: string
  onToggle?: React.MouseEventHandler
}

export const CollapseHeader: React.FC<CollapseHeaderProps> = ({
  open = false,
  title,
  className,
  children,
  onToggle,
}) => {
  return (
    <button
      title={title}
      type="button"
      onClick={onToggle}
      className={clsx(
        styles.root,
        { [styles.clickable]: onToggle, [styles.active]: open },
        className
      )}
    >
      <div className={styles.content}>{children ?? title}</div>

      <Icon name="arrow-down" rotateAngle={open ? 180 : 0} />
    </button>
  )
}

export default CollapseHeader
