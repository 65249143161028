import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import omit from "lodash/omit"
import find from "lodash/find"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import { useController, useStore } from "@store/index"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"
import Button from "@components/ui/Button/Button"
import SOLUTION_CATEGORY from "@framework/constants/solution-category"
import { getAvailableCategories } from "@utils/product-solution"
import mainRoutes from "@root/main.routes"
import { SolutionCategory, WorkFlowType } from "@framework/types/solution"
import Text from "@components/ui/Typography/Text"
import NotFound from "@components/ui/NotFound/NotFound"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { capitalizeFirstLetter, findByQuery } from "@utils/textUtils"
import Icon from "@components/ui/Icon/Icon"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import useAvatarScope from "./AvatarScopeContext/useAvatarScope"
import AvailableSolutions from "./AvailableSolutions/AvailableSolutions"
import HomeInfo from "./HomeInfo/HomeInfo"
import CategoryInfo from "./HomeInfo/CategoryInfo"

import styles from "./HomeBeta.module.sass"

const DEFAULT_WELCOME_MESSAGE = "What's your focus today?"

const HomePageBeta: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()

  const isTablet = useMediaQuery(AppMediaQueries.maxTablet)
  const isMobile = useMediaQuery(AppMediaQueries.minMobile)
  const maxMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const {
    userStore: { user },
    solutionsStore: { category, solutions, isLoading, setSolution },
    restrictionsStore: access,
  } = useStore()

  const { solutionsController } = useController()

  const { avatar, setAvatar } = useAvatarScope()

  const greeting = `Welcome${user ? `, ${user.firstName}!` : ""}`

  const categories = getAvailableCategories(SOLUTION_CATEGORY, solutions || [])

  const createSolutionModal = useModal(ModalsTypes.SELECT_SOLUTION_TYPE_MODAL)
  const editSolutionModal = useModal(ModalsTypes.EDIT_SOLUTION_MODAL)
  const editSearchPresetModal = useModal(
    ModalsTypes.EDIT_SEARCH_SOLUTION_PRESET_MODAL
  )

  React.useEffect(() => {
    if (
      !category ||
      !find(categories, (it) => it.category === category.category)
    ) {
      solutionsController.setCategory(categories[0])
    }
  }, [category, categories, solutionsController])

  React.useEffect(() => {
    const categoryNameFromPath = new URLSearchParams(location.search).get(
      "category"
    )

    if (categoryNameFromPath && categoryNameFromPath !== category?.category) {
      const categoryFromPath = findByQuery(
        categories,
        categoryNameFromPath,
        (c) => c.category
      )

      solutionsController.setCategory(categoryFromPath || categories[0])

      if (!categoryFromPath) navigate(mainRoutes.home())
    }
  }, [location.search, category, solutionsController, SOLUTION_CATEGORY])

  React.useEffect(() => {
    if (avatar?.id) solutionsController.loadAvatarSolutions(avatar.id)
  }, [avatar?.id])

  React.useEffect(() => {
    const autoPickSingleOption =
      location.state?.action === "OPEN_SINGLE_SOLUTION"

    if (isLoading || !autoPickSingleOption) return

    navigate(location, {
      state: omit(location.state, "action"),
      replace: true,
    })

    if (solutions?.length === 1) {
      setSolution(solutions[0])
      navigate(mainRoutes.solutionView(solutions[0].workflowType))
    }
  }, [isLoading, solutions, location.state])

  const handleNavigateToCategory = (item: SolutionCategory) => {
    navigate(`${mainRoutes.home()}?category=${item.category}`)
  }

  const handleCreate =
    access.canCreateSolution && avatar != null
      ? () => {
          createSolutionModal.showModal({
            onSubmit: (solutionType) =>
              editSolutionModal.showModal({
                workflowType: solutionType,
                category: category?.category,
                onSubmit: async (solution) => {
                  await solutionsController.loadAvatarSolutions(avatar.id)

                  setSolution(solution)

                  navigate(
                    `${mainRoutes.solutionEdit(
                      solution.workflowType,
                      solution.id
                    )}?create=true`
                  )

                  if (solution.workflowType === WorkFlowType.SEARCH) {
                    editSearchPresetModal.showModal({ isCreate: true })
                  }
                },
              }),
          })
        }
      : undefined

  return (
    <MainLayout className={styles.mainLayout}>
      <div className={clsx(styles.root)}>
        <header className={styles.header}>
          <div className={styles.greeting}>
            <Text
              variant={isTablet ? "h3" : isMobile ? "h2" : "h1"}
              weight="bold"
            >
              {greeting}
            </Text>
            <Text variant={isTablet ? "h6" : "h5"}>
              {DEFAULT_WELCOME_MESSAGE}
            </Text>
          </div>

          <AvatarPickerSidebar value={avatar?.id} onChange={setAvatar} />
        </header>

        <div className={styles.content}>
          <div className={styles.mainSection}>
            {categories.length > 1 && (
              <div className={styles.categories}>
                {categories.map((item) => (
                  <Button
                    key={item.category}
                    color={
                      item.category === category?.category
                        ? "primary"
                        : "secondary"
                    }
                    size={isTablet ? "small" : "medium"}
                    variant="contained"
                    onClick={() => handleNavigateToCategory(item)}
                  >
                    {capitalizeFirstLetter(item.category)}
                  </Button>
                ))}
              </div>
            )}

            {maxMobile && category != null && (
              <CategoryInfo
                category={category.category}
                description={category.description}
                heading={category.heading}
              />
            )}

            {isLoading ? (
              <div className={styles.placeholderWrapper}>
                <Skeleton
                  className={styles.skeleton}
                  lineClassName={styles.skeletonLines}
                  lineHeight="360px"
                  count={3}
                  rounded
                />
              </div>
            ) : solutions?.length ? (
              <AvailableSolutions onCreate={handleCreate} />
            ) : (
              <div className={styles.placeholderWrapper}>
                <NotFound
                  retryButton={
                    handleCreate != null ? (
                      <Button
                        size="large"
                        color="primary"
                        className={styles.addButton}
                        before={<Icon name="plus" />}
                        onClick={handleCreate}
                      >
                        Create Solution
                      </Button>
                    ) : null
                  }
                >
                  There are no solutions yet created
                </NotFound>
              </div>
            )}
          </div>

          {access.showHomepageSidebar && (
            <div className={clsx(styles.sidebarSection)}>
              <HomeInfo />
            </div>
          )}
        </div>
      </div>

      <Outlet />
    </MainLayout>
  )
})

export default HomePageBeta
