import { observer } from "mobx-react-lite"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import MainLayout from "@components/layout/MainLayout/MainLayout"
import Container from "@components/ui/Container/Container"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import BackButton from "@components/prototypes/BackButton"
import Button from "@components/ui/Button/Button"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import Pagination from "@components/ui/Pagination/Pagination"
import usePagination from "@components/ui/Pagination/usePagination"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import { ConnectorDocument } from "@framework/types/content-manager"
import useSearch from "@components/hooks/useSearch"
import useDebounce from "@components/hooks/useDebounce"
import useQuery from "@components/hooks/useQuery"

import ContentRow from "./components/ContentRow/ContentRow"
import SyncInfo from "./components/SyncInfo/SyncInfo"
import SearchInfoBanner from "./components/SearchInfoBanner/SearchInfoBanner"
import ContentManagerFilter from "./components/Filter/ContentManagerFilter"

import styles from "./ContentManager.module.sass"

const MAX_RESULTS_LIMIT = 10000

export const ContentManagerPage: React.FC = observer(() => {
  const navigate = useNavigate()
  const location = useLocation()
  const alert = useAlert()
  const params = useQuery()

  const contentTypeId = params.get("contentTypeId")
  const contentSource = params.get("contentSource")

  const contentTypeModal = useModal(ModalsTypes.SELECT_CONTENT_TYPE_MODAL)
  const confirmModal = useModal(ModalsTypes.CONFIRM_MODAL)
  const chooseContentSourceModal = useModal(
    ModalsTypes.CHOOSE_CONTENT_SOURCE_MODAL
  )

  const [selectedRows, setSelectedRows] = React.useState<ConnectorDocument[]>(
    []
  )
  const [searchProps, searchContext] = useSearch()
  const { debouncedValue, setDebounce } = useDebounce()

  const { contentManagerController } = useController()
  const { contentManagerStore, restrictionsStore: access } = useStore()
  const {
    documentsList,
    loadingDocumentsList,
    documentsMeta,
    filterStr,
    loadingSyncJobs,
  } = contentManagerStore

  const paginationProps = usePagination({
    pageSize: documentsMeta?.pageSize || 0,
    totalRows: documentsMeta?.total || 0,
    totalPages: documentsMeta?.totalPages || 0,
  })

  const handleConnect = () => {
    chooseContentSourceModal.showModal({
      onSubmit: () => {
        if (paginationProps?.onPageChange) {
          if (paginationProps.forcePage === 0) {
            loadPage()
          } else {
            paginationProps.onPageChange({ selected: 0 })
          }
        }
      },
    })
  }

  const onDelete = async (docs: ConnectorDocument[]): Promise<void> => {
    const error = await contentManagerController.deleteConnectorDocuments(docs)
    if (error) {
      alert.error(error)
    } else {
      alert.info("Successfully deleted")
      setSelectedRows([])
      setTimeout(loadPage, 1000) // added timeout as it takes sometime to deleted documents refreshed
    }
  }

  const loadPage = () =>
    contentManagerController.loadDocumentsList({
      pageSize: 10,
      pageNum: paginationProps.forcePage + 1,
      query: debouncedValue,
      filter: filterStr,
    })

  const clearSearch = () => {
    contentManagerController.clearSearch()
    searchContext.setQuery("")
    loadPage()
  }

  React.useEffect(() => {
    loadPage()
  }, [paginationProps.forcePage])

  React.useEffect(() => {
    if (paginationProps.forcePage > 0 && paginationProps?.onPageChange) {
      paginationProps.onPageChange({ selected: 0 })
    } else {
      loadPage()
    }
  }, [debouncedValue, filterStr])

  React.useEffect(() => {
    contentManagerController.loadSyncJobs()

    if (!contentTypeId && !contentSource) return

    contentManagerStore.setActiveFilter({
      include: true,
      contentType: contentTypeId ? [contentTypeId] : [],
      contentSource: contentSource ? [contentSource] : [],
    })

    navigate(location.pathname, { replace: true, state: location.state })
  }, [])

  React.useEffect(() => {
    setDebounce(searchProps.value)
  }, [searchProps.value])

  const clearSelection = () => setSelectedRows([])

  const showContentTypeModal = (doc?: ConnectorDocument) => {
    contentTypeModal.showModal({
      documents: doc ? [doc] : selectedRows,
      onAssign: () => {
        setSelectedRows([])
        alert.success("Successfully assigned content type")
        loadPage()
      },
    })
  }

  const showDeleteConfirmModal = (doc?: ConnectorDocument) => {
    const docs = doc ? [doc] : selectedRows
    confirmModal.showModal({
      message: (
        <>
          Delete Document{docs.length > 1 && `'s`}
          <br />
          Are you sure you want to proceed?
        </>
      ),
      confirm: "positive",
      onConfirm: () => onDelete(docs),
      confirmButtonText: "Proceed",
    })
  }

  return (
    <MainLayout noPadding>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            className={styles.header}
            left={
              <BackButton onClick={() => navigate(-1)}>
                Content Manager
              </BackButton>
            }
            right={
              access.canAddContent && (
                <Button color="primary" size="big" onClick={handleConnect}>
                  Add Content
                </Button>
              )
            }
          >
            <TextInput
              {...searchProps}
              before={<Icon name="search" />}
              placeholder="Search"
              after={
                !!searchProps.value && (
                  <Icon name="cross" onClick={clearSearch} />
                )
              }
            />
          </EntityPageHeaderLayout>
          {(documentsMeta?.total || 0) > MAX_RESULTS_LIMIT && (
            <SearchInfoBanner />
          )}
          {loadingSyncJobs && <Skeleton className={styles.syncSkeleton} />}
          {!!contentManagerStore?.syncJobs?.length && (
            <SyncInfo jobs={contentManagerStore.syncJobs} />
          )}
          <div className={styles.filterContainer}>
            <div>
              {!!selectedRows.length && (
                <Button
                  size="medium"
                  before={<Icon onClick={clearSelection} name="cross" />}
                  color="secondary"
                  className={styles.deleteChip}
                >
                  {selectedRows.length} Selected
                  <div className={styles.selectedFilterDelete}>
                    {access.canAssignContentType && (
                      <Icon
                        onClick={() => showContentTypeModal()}
                        name="storage"
                      />
                    )}
                    {access.canDeleteContent && (
                      <Icon
                        onClick={() => showDeleteConfirmModal()}
                        name="trash-can"
                        color="red"
                      />
                    )}
                  </div>
                </Button>
              )}
            </div>

            <ContentManagerFilter />
          </div>
          <LoadingCover
            isLoading={loadingDocumentsList}
            className={styles.body}
          >
            {!loadingDocumentsList && !documentsList?.length && (
              <NotFound className={styles.notFound}>
                <Text variant="h2">No documents found</Text>
              </NotFound>
            )}
            {documentsList?.map((item) => (
              <ContentRow
                selected={selectedRows.map((it) => it.id).includes(item.id)}
                onSelect={(val) => {
                  setSelectedRows((prevSelectedRows) =>
                    prevSelectedRows.map((it) => it.id).includes(val.id)
                      ? prevSelectedRows.filter((row) => row.id !== val.id)
                      : [...prevSelectedRows, val]
                  )
                }}
                key={item.id}
                data={item}
                onDelete={(doc) => showDeleteConfirmModal(doc)}
                showContentTypeModal={() => showContentTypeModal(item)}
              />
            ))}
          </LoadingCover>
          {!!documentsList?.length && (
            <EntityPageHeaderLayout
              className={styles.footer}
              right={<Pagination {...paginationProps} />}
            />
          )}
        </div>
      </Container>
    </MainLayout>
  )
})

export default ContentManagerPage
