import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import { GridStylesCache } from "../types"

class ShardGrid {
  visibleGridRect: Range<Point>

  cellsStyleCache: GridStylesCache | null

  constructor() {
    this.visibleGridRect = { start: { x: 0, y: 0 }, end: { x: 0, y: 0 } }
    this.cellsStyleCache = {}

    makeAutoObservable(this)
  }

  setGridStylesCache = (cache: GridStylesCache = {}) => {
    this.cellsStyleCache = {
      ...this.cellsStyleCache,
      ...cache,
    }
  }

  setVisibleRect = (rect: Range<Point>) => {
    this.visibleGridRect = { ...rect }
  }

  get getCellCoordinates() {
    const sizeCache = this.cellsStyleCache

    return (point: Point) => {
      const cell = `${point.y}-${point.x}`
      return {
        x: sizeCache?.[cell]?.left ?? 0,
        y: sizeCache?.[cell]?.top ?? 0,
      }
    }
  }
}

export default ShardGrid
