import React from "react"
import { FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"
import * as yup from "yup"
import clsx from "clsx"

import { AvatarData } from "@framework/types/avatar"
import { useController, useStore } from "@store/index"
import SearchInput from "@pages/launch-solutions/SolutionTabsContainer/SearchInput"

import styles from "./SearchWidget.module.sass"

const validationSchema = yup.object({
  query: yup.string().trim().min(3).default(""),
})

export interface Props {
  avatar: AvatarData
  className?: string
  disabled?: boolean
  handleSearch: () => void
  moveDown: boolean
}

export const SearchWidget: React.FC<Props> = observer(
  ({ avatar, className, disabled, handleSearch, moveDown }) => {
    const { factFinderSolutionController: controller } = useController()
    const {
      solutionsStore: { solution, appliedFilters },
      contentManagerStore: { companies },
    } = useStore()

    const handleSubmit = (data: any) => {
      if (!moveDown) {
        controller.clearHighLights()
      }
      if (solution?.id)
        controller.search(
          data.query,
          avatar,
          solution.id,
          appliedFilters,
          companies
        )
      handleSearch()
      formik.setFieldValue("query", "")
    }

    const formik = useFormik({
      initialValues: validationSchema.getDefault(),
      validationSchema,
      validateOnChange: true,
      onSubmit: handleSubmit,
    })

    return (
      <div
        className={clsx(styles.root, { [styles.down]: moveDown })}
        aria-disabled={disabled}
      >
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} className={className}>
            <section className={styles.searchContainer}>
              <SearchInput disabled={disabled} />
            </section>
          </form>
        </FormikProvider>
      </div>
    )
  }
)

export default SearchWidget
