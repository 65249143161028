import React from "react"
import { observer } from "mobx-react-lite"

import { Point } from "@framework/types/common"

import { useMatrixContext } from "./MatrixContext"
import TextEditor from "./TextEditorCell/TextEditor"
import { includes } from "./utils"

type EditorCellProps = {
  shardId: string
  cell: Point
  xBaseIndex?: number
  yBaseIndex?: number
}

const EditorCell: React.FC<EditorCellProps> = observer(
  ({ shardId, cell, xBaseIndex = 0, yBaseIndex = 0 }) => {
    const { grid, editManager } = useMatrixContext()

    const shardGrid = grid.getVisibleGridCache(shardId)

    if (!includes(shardGrid.visibleGridRect, cell)) return null

    const position = shardGrid.getCellCoordinates({
      x: cell.x - xBaseIndex,
      y: cell.y - yBaseIndex,
    })

    return (
      <div
        style={{
          position: "absolute",
          left: position.x,
          top: position.y,
          zIndex: 99,
        }}
      >
        {editManager.activeCellState != null && (
          <TextEditor control={editManager.activeCellState} />
        )}
      </div>
    )
  }
)

export default EditorCell
