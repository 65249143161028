import React, { Dispatch, useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import { IconName } from "@components/ui/Icon/IconName"
import Loader from "@components/ui/Loader/BarLoader"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import { ConnectorDocument } from "@framework/types/content-manager"
import { useController, useStore } from "@store"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import { getDataSourceNodeContextProps } from "@framework/constants/upload"
import { DataConnectorSourceName } from "@framework/types/upload"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { capitalizeEachFirstLetter, removeHyphens } from "@utils/textUtils"
import IconButton from "@components/ui/IconButton/IconButton"
import { IdentifierDetails } from "@framework/types/identifier"

import styles from "./LinkedContentTable.module.sass"

import { AddedDeletedType } from "."

export type LinkedContentTableProps = {
  contentIds: AddedDeletedType
  setContentIds: Dispatch<AddedDeletedType>
  identifierDetails: IdentifierDetails
}

const LinkedContentTable: React.FC<LinkedContentTableProps> = observer(
  ({ contentIds, setContentIds, identifierDetails }) => {
    const [allContents, setAllContents] = useState<ConnectorDocument[]>([])

    const { id } = useParams()

    const { identifierStore } = useStore()
    const { contentManagerController, identifierController } = useController()
    const linkContentModal = useModal(ModalsTypes.LINK_CONTENT_MODAL)

    const { isContentsLoading, contentPaginationMeta, identifierContents } =
      identifierStore

    const hasMoreContent =
      (contentPaginationMeta?.pageNum || 0) <
      (contentPaginationMeta?.totalPages || 0)

    const loadMoreContent = useCallback(() => {
      if (hasMoreContent && id) {
        identifierController.loadMoreIdentifierContentIds(
          (contentPaginationMeta?.pageNum || 1) + 1,
          100,
          id || ""
        )
      }
    }, [contentPaginationMeta, identifierController])

    const lastItemRef = useInfiniteScroll({
      callback: loadMoreContent,
      isLoading: isContentsLoading,
      hasMore: hasMoreContent,
    })

    useEffect(() => {
      contentManagerController.getCompanies()
    }, [])

    const handleLinkContentClick = () => {
      linkContentModal.showModal({
        selectedContentIds: allContents.map((content) => content.id),
        identifierId: identifierDetails.category?.id,
        onSave: (docs: ConnectorDocument[]) => {
          const newIds = docs.map((item) => item.id)
          const uniqueNewIds = Array.from(
            new Set([...contentIds.added, ...newIds])
          )
          setContentIds({
            added: uniqueNewIds,
            deleted: contentIds.deleted.filter(
              (id) => !uniqueNewIds.includes(id)
            ),
          })
          const newDocs = [
            ...docs,
            ...allContents.filter(
              (doc) => !docs.some((content) => content.id === doc.id)
            ),
          ]
          setAllContents(newDocs)
        },
      })
    }

    useEffect(() => {
      if (id) {
        identifierController.loadMoreIdentifierContentIds(1, 1000, id || "")
      }
    }, [id])

    useEffect(() => {
      if (identifierContents.length) {
        setAllContents([
          ...allContents,
          ...identifierContents.filter(
            (doc) => !allContents.some((content) => content.id === doc.id)
          ),
        ])
      }
    }, [identifierContents])

    const getContentSource = (source: DataConnectorSourceName) => {
      const docIcon = getDataSourceNodeContextProps(source)
      return (
        <div className={styles.source}>
          {docIcon && <DocumentIcon icon={docIcon.icon} />}
          {capitalizeEachFirstLetter(removeHyphens(source, true))}
        </div>
      )
    }

    const handleRemoveContent = (id: string) => {
      setAllContents(allContents.filter((content) => content.id !== id))

      const deletedIds = Array.from(new Set([...contentIds.deleted, id]))
      setContentIds({
        added: contentIds.added.filter((id) => !deletedIds.includes(id)),
        deleted: deletedIds,
      })
    }

    return (
      <div className={styles.linkedContents}>
        <Text variant="h2" weight="bold">
          Linked Content
        </Text>
        <div className={styles.scrollableTable}>
          <Table
            header={
              <Row>
                <TextColumn>Content ID</TextColumn>
                <TextColumn>Content Type</TextColumn>
                <TextColumn>Content Source</TextColumn>
                <TextColumn>Content Connector</TextColumn>
                <Column width={38}> </Column>
              </Row>
            }
          >
            {allContents.map((item, index) => (
              <>
                <Row key={item.id}>
                  <Column> {item.id}</Column>
                  <Column>
                    <div className={styles.contentType}>
                      {item.contentType?.iconUrl ? (
                        <Icon
                          color="primary"
                          name={item.contentType.iconUrl as IconName}
                        />
                      ) : null}
                      {item.contentType?.name || "N/A"}
                    </div>
                  </Column>
                  <Column>{getContentSource(item.source)}</Column>
                  <Column>
                    {" "}
                    <div className={styles.connectorName}>
                      <Icon name="storage" />
                      {item.connectorName}
                    </div>{" "}
                  </Column>
                  <Column className={styles.actionColumn}>
                    <IconButton onClick={() => handleRemoveContent(item.id)}>
                      <Icon color="red" name="trash-can" />
                    </IconButton>
                  </Column>
                </Row>
                {index === allContents.length - 1 && (
                  <div className={styles.lastElem} ref={lastItemRef} />
                )}
              </>
            ))}
          </Table>
          {isContentsLoading && (
            <div className={styles.loader}>
              <Loader size="small" />
            </div>
          )}
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            size="tiny"
            variant="text"
            before={<Icon name="link" />}
            onClick={handleLinkContentClick}
          >
            Link Content
          </Button>
        </div>
      </div>
    )
  }
)

export default LinkedContentTable
