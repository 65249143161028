import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import max from "lodash/max"
import { useNavigate } from "react-router-dom"

import { useStore } from "@store/index"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import { countSuffix } from "@utils/numberUtils"
import ExpertCard from "@components/ui/EntityCard/ExpertCard"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Text from "@components/ui/Typography/Text"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"
import BackButton from "@components/prototypes/BackButton"
import mainRoutes from "@root/main.routes"

import styles from "./ExpertsPage.module.sass"

export interface ExpertsPageProps {}

export const ExpertsPage: React.FC<ExpertsPageProps> = observer(() => {
  const navigate = useNavigate()

  const {
    userSetupStore: { avatarId, setAvatarId },
    expertStore: { loadRankedExperts, experts, isLoading },
    avatarsStore: { getAvatarById },
  } = useStore()

  const avatar = getAvatarById(avatarId)

  const total = experts?.length ?? 0
  const topScore = max(experts?.map((it) => it.points))

  useEffect(() => {
    if (avatarId) loadRankedExperts(avatarId)
  }, [avatarId])

  return (
    <MainLayout>
      <div className={styles.root}>
        <div className={styles.header}>
          <BackButton onClick={() => navigate(mainRoutes.expertsMenu())}>
            Experts that have trained AI
          </BackButton>

          <AvatarPickerSidebar value={avatar?.id} onChange={setAvatarId} />
        </div>
        <div className={styles.body}>
          {isLoading ? (
            <Loader primary size="huge" fluid />
          ) : (
            <>
              <Text variant="h5" color="text50Color">
                {total} {`Result${countSuffix(total)}`}
              </Text>
              {total > 0 ? (
                <div className={styles.grid}>
                  {experts?.map((expertData) => (
                    <ExpertCard
                      data={expertData}
                      topScore={topScore}
                      key={expertData.id}
                    />
                  ))}
                </div>
              ) : (
                <NotFound>No experts found</NotFound>
              )}
            </>
          )}
        </div>
      </div>
    </MainLayout>
  )
})

export default ExpertsPage
