import React from "react"
import { observer } from "mobx-react-lite"
import { useLocation, useNavigate } from "react-router-dom"
import { toJS } from "mobx"

import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import BackButton from "@components/prototypes/BackButton"
import NotFound from "@components/ui/NotFound/NotFound"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { SearchCitationData } from "@store/search/search-citations.store"

import AnswerOriginalView from "./components/ImageResult/AnswerOriginalView/AnswerOriginalView"

import styles from "./SourceView.module.sass"

export interface SourceViewProps {}

export const SourceView: React.FC<SourceViewProps> = observer(() => {
  const location = useLocation()
  const navigate = useNavigate()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const { searchEntityBlock, searchBlockId, searchEntity, answerIndex } =
    useSearchBlock(SearchSummaryBlockStore)

  const { citations: imageUrls = [], searchAnswers } =
    searchEntityBlock.citationsStore || {}

  const citation = Number(answerIndex || 1)

  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const handleCitationChange = (citation: number = 1) => {
    navigate(`../${searchEntity.id}/${searchBlockId}/${citation}`, {
      state: location.state,
      replace: true,
    })
  }

  const handleImageChange = (imageDetails: SearchCitationData) => {
    const newAnswer = searchAnswers?.find(
      (item) => item.name === imageDetails?.id
    )
    if (newAnswer) handleCitationChange(imageDetails.citationNumber)
  }

  const handleContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current === e.target) navigate(-1)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === "Escape") {
      navigate(-1)
    }
  }

  const answer: SearchCitationData | undefined = imageUrls?.[citation - 1]

  return (
    <div
      className={styles.root}
      ref={containerRef}
      {...(!isTablet
        ? {
            onClick: handleContainerClick,
            onKeyDown: handleKeyDown,
            role: "button",
            tabIndex: -1,
          }
        : null)}
    >
      <div className={styles.header}>
        <BackButton size="small">Back</BackButton>
      </div>

      <div className={styles.body}>
        {answer != null ? (
          <AnswerOriginalView
            citation={answer}
            images={imageUrls}
            onSelect={handleImageChange}
          />
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  )
})

export default SourceView
