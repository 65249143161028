import React from "react"
import clsx from "clsx"

import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"

import styles from "./Question.module.sass"

export type QuestionProps = {
  question: string
}
const Question: React.FC<QuestionProps> = ({ question }) => {
  const {
    solutionsStore: { solution, appliedFilters },
    factFinderSolutionStore: { searchFlowStore },
    contentManagerStore: { companies },
  } = useStore()
  const { avatar } = useActiveAvatar()
  const isSearching = searchFlowStore.isAnyLoading()

  const { factFinderSolutionController } = useController()

  const handleSearch = () => {
    if (isSearching) return
    if (solution?.id)
      factFinderSolutionController.search(
        question,
        avatar,
        solution.id,
        appliedFilters,
        companies
      )
  }
  return (
    <div
      className={clsx(styles.root, { [styles.disabled]: isSearching })}
      onClick={handleSearch}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") handleSearch()
      }}
    >
      <span>{question}</span>
      <Icon name="arrow-up" rotateAngle={90} />
    </div>
  )
}

export default Question
