import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"

import styles from "./CitationBadge.module.sass"

interface Props {
  className?: string
  citationNumber: number
}

export const CitationBadge: React.FC<Props> = observer(
  ({ citationNumber: index, className }) => {
    const { searchEntityBlock } = useSearchBlock(SearchSummaryBlockStore)

    const isVerified = !!searchEntityBlock.citationsStore?.getByIndex(index - 1)
      ?.isExpertAnswer

    return (
      <span
        className={clsx(
          styles.root,
          { [styles.verified]: isVerified },
          className
        )}
        data-index={index}
      >
        <Icon
          name={isVerified ? "shield-fill" : "checkbox-blank-circle-fill"}
          color={isVerified ? "green" : "primary"}
        />
      </span>
    )
  }
)

export default CitationBadge
