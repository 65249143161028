import React from "react"
import { observer } from "mobx-react-lite"
import capitalize from "lodash/capitalize"

import usePopper from "@components/hooks/usePopper"
import { direction2D, Direction2D } from "@framework/types/common"
import { Option } from "@framework/types/utils"

import RangeSquare from "./SelectionRange"
import Popper from "../Dropdown/Popper"
import { includes, rangeToCode } from "./utils"
import { useMatrixContext } from "./MatrixContext"
import Button from "../Button/Button"
import Text from "../Typography/Text"
import Switch from "../Switch/Switch"
import RectSelection from "./state/RectSelection"
import SpreadRangeForm from "./Tooltips/SpreadRangeForm"

import styles from "./Spreadsheet.module.sass"

const options: Option<Direction2D>[] = direction2D.map((name) => ({
  name,
  value: capitalize(name),
}))

export const PreciseSpreadRange: React.FC<{
  selection: RectSelection
  direction: Direction2D
  shardId: string
  xBaseIndex?: number
  yBaseIndex?: number
}> = observer(
  ({ selection, direction, shardId, xBaseIndex = 0, yBaseIndex = 0 }) => {
    const { selectedRange, grid } = useMatrixContext()

    const [rootNode, setRootNode] = React.useState<HTMLElement | null>(null)
    const [popperNode, setPepperNode] = React.useState<HTMLElement | null>(null)

    const shardGrid = grid.getVisibleGridCache(shardId)

    const { style } = usePopper(rootNode, popperNode, {
      defaultValue: includes(shardGrid.visibleGridRect, selectedRange.origin),
      placement: "right-start",
    })

    return (
      <>
        <RangeSquare
          shardId={shardId}
          ref={setRootNode}
          range={selection.range}
          xBaseIndex={xBaseIndex}
          yBaseIndex={yBaseIndex}
        />

        <Popper ref={setPepperNode} style={style} isActive>
          <SpreadRangeForm
            direction={direction}
            from={selectedRange.range}
            to={selection.range}
          />
        </Popper>
      </>
    )
  }
)

export default PreciseSpreadRange
