import React, { useCallback, useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { useParams } from "react-router-dom"
import { observer } from "mobx-react-lite"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import Container from "@components/ui/Container/Container"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import Icon from "@components/ui/Icon/Icon"
import { useController, useStore } from "@store"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import useInfiniteScroll from "@components/hooks/useInfiniteScroll"
import Loader from "@components/ui/Loader/BarLoader"
import Skeleton from "@components/ui/Skeleton/Skeleton"
import NotFound from "@components/ui/NotFound/NotFound"
import { ConnectorDocument } from "@framework/types/content-manager"
import Table from "@components/ui/BaseTable/Table"
import Row from "@components/ui/BaseTable/Row"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Column from "@components/ui/BaseTable/Column"

import styles from "./index.module.sass"

const IdentifierViewPage = observer(() => {
  const { id } = useParams()

  const { identifierStore } = useStore()
  const { identifierController } = useController()
  const [allContents, setAllContents] = useState<ConnectorDocument[]>([])

  const {
    identifierDetails,
    loading,
    isContentsLoading,
    contentPaginationMeta,
    identifierContents,
  } = identifierStore

  const hasMoreContent =
    (contentPaginationMeta?.pageNum || 0) <
    (contentPaginationMeta?.totalPages || 0)

  const loadMoreContent = useCallback(() => {
    if (hasMoreContent && id) {
      identifierController.loadMoreIdentifierContentIds(
        (contentPaginationMeta?.pageNum || 1) + 1,
        100,
        id || ""
      )
    }
  }, [contentPaginationMeta, identifierController])

  const lastItemRef = useInfiniteScroll({
    callback: loadMoreContent,
    isLoading: isContentsLoading,
    hasMore: hasMoreContent,
  })

  useEffect(() => {
    if (id) {
      identifierController.loadMoreIdentifierContentIds(1, 100, id || "")
      identifierController.getIdentifier(id)
    }
  }, [id])

  useEffect(() => {
    if (identifierContents.length) {
      setAllContents([
        ...allContents,
        ...identifierContents.filter(
          (doc) => !allContents.some((content) => content.id === doc.id)
        ),
      ])
    }
  }, [identifierContents])

  useEffect(() => {
    setAllContents([])
  }, [])

  const { category, childCategories, products } = identifierDetails || {}

  return (
    <MainLayout>
      <Container>
        <div className={styles.root}>
          <EntityPageHeaderLayout
            left={
              <BackButton>
                {loading ? (
                  <Skeleton
                    className={styles.nameSkeleton}
                    lineHeight={30}
                    minWidth={200}
                  />
                ) : (
                  category?.name
                )}
              </BackButton>
            }
          />
          <LoadingCover isLoading={loading} className={styles.container}>
            <div className={styles.mainContent}>
              <Chip variant="rounded" color="green">
                {category?.company?.name || "N/A"}
              </Chip>
              <Text weight="bold" variant="h1" className={styles.mainHeading}>
                {category?.name}
              </Text>
              {!!childCategories?.length && (
                <>
                  <Text variant="h3" className={styles.header}>
                    Child Identifiers
                  </Text>
                  <div className={clsx(styles.identifiers, styles.card)}>
                    {childCategories.map((category) => (
                      <Chip
                        key={category.id}
                        variant="rounded"
                        color="secondary-solid"
                      >
                        {category.name}
                      </Chip>
                    ))}
                  </div>
                </>
              )}
              {!!products?.length && (
                <>
                  <Text variant="h3" className={styles.header}>
                    Products
                  </Text>
                  <div className={clsx(styles.card, styles.attributes)}>
                    <Table
                      hideBorder
                      header={
                        <Row>
                          <TextColumn>Product Name</TextColumn>
                          <TextColumn>Description</TextColumn>
                        </Row>
                      }
                    >
                      {products?.map((product) => (
                        <Row key={product.id}>
                          <Column> {product.name}</Column>
                          <Column> {product.description || "N/A"}</Column>
                        </Row>
                      ))}
                    </Table>
                  </div>
                </>
              )}
            </div>
            <div className={clsx(styles.linkedContent, styles.card)}>
              <Text weight="bold" variant="h3">
                Linked Content
              </Text>
              <div className={styles.linkedContentList}>
                {allContents.map((content, index) => (
                  <div
                    key={content.id}
                    className={styles.content}
                    ref={index === allContents.length - 1 ? lastItemRef : null}
                  >
                    <span>
                      <b>Content</b> : {content.id}
                    </span>
                    <Icon name="arrow-up" rotateAngle={90} />
                  </div>
                ))}
                {!allContents.length && !isContentsLoading && <NotFound />}
                {isContentsLoading && (
                  <div className={styles.loader}>
                    <Loader size="small" />
                  </div>
                )}
              </div>
            </div>
          </LoadingCover>
        </div>
      </Container>
    </MainLayout>
  )
})
export default IdentifierViewPage
