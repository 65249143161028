import React from "react"
import { nanoid } from "nanoid"
import { AutoSizer, ScrollParams } from "react-virtualized"
import scrollbarSize from "dom-helpers/scrollbarSize"
import { observer } from "mobx-react-lite"
import clsx from "clsx"

import useEventListener from "@components/hooks/useEventListener"
import useEvent from "@components/hooks/useEvent"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"

import MatrixStore from "./state/MatrixStore"
import { MatrixSnapshot } from "./types"
import { MatrixContext } from "./MatrixContext"
import Axis from "./Axis"
import CellControlHeader from "./CellControlHeader"
import EmptyAxisCell from "./EmptyAxisCell"
import ToolBar from "./ToolBar"
import useDefaultContextMenu from "./useDefaultContextMenu"
import { TableBodyShard } from "./TableBodyShard"

import styles from "./Spreadsheet.module.sass"

type SpreadsheetProps = {
  instance: MatrixStore
  onUpdate?: (snapshot: MatrixSnapshot) => void
}

const Spreadsheet: React.FC<SpreadsheetProps> = observer((props) => {
  const state = props.instance

  const isMobile = useMediaQuery(AppMediaQueries.minTablet)

  const [rootId] = React.useState(nanoid)

  const [bodyScroll, setBodyScroll] = React.useState<ScrollParams>()

  React.useEffect(() => {
    return () => {
      if (state.editManager.isEditing) state.editManager.cancelCell()
    }
  }, [])

  const handleUpdate = useEvent((snapshot) => props.onUpdate?.(snapshot))

  React.useEffect(() => {
    if (props.onUpdate == null || state.editManager.isEditing) return undefined

    const handler = () => {
      state.serialize().then(handleUpdate)
    }

    const interval = setInterval(handler, 1000)
    return () => clearInterval(interval)
  }, [props.onUpdate != null, state.editManager.isEditing])

  useEventListener({
    type: "mouseup",
    element: document,
    listener: () => {
      state.selectedRange.endRange()

      state.editManager.endRefSelecting()

      state.endManualSpreading()

      state.abortPreciseSpreading()

      state.resizer.end((axis, cellIndex, shift) => {
        const resize =
          axis === "x"
            ? state.grid.resizeCellWidth
            : state.grid.resizeCellHeight

        resize(cellIndex, shift)

        state.render()
      })

      // Add other handlers here
    },
  })

  useDefaultContextMenu(state)

  const totalDynamicColumns = state.grid.totalColumns - state.grid.frozenPoint.x
  const totalDynamicRows = state.grid.totalRows - state.grid.frozenPoint.y

  const borderWidth = 2

  const bodyStyles = {
    "--static-body-width": `${
      state.grid.getWidth(0, state.grid.frozenPoint.x - 1) + borderWidth
    }px`,
    "--static-body-height": `${
      state.grid.getHeight(0, state.grid.frozenPoint.y - 1) + borderWidth
    }px`,
    "--divider-border-width": `${borderWidth}px`,
  } as React.CSSProperties

  const refreshSeed = `${state.grid.frozenPoint.x}_${state.grid.frozenPoint.y}_${rootId}`

  return (
    <MatrixContext.Provider value={state}>
      <div
        className={styles.root}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          const direction = e.shiftKey ? -1 : 1

          if (!state.editManager.isEditing) {
            e.preventDefault()
            e.stopPropagation()

            const point = state.selectedRange.origin

            if (e.key.length === 1 && !(e.ctrlKey || e.metaKey)) {
              state.editManager.editCell(point, { initialValue: e.key })
              return
            }

            if (e.key === "Enter") {
              const cell = state.editManager.getCellAtPoint(point)

              if (cell.readonly && cell.isExecutable) cell.executeFormula()
              else state.editManager.editCell(point)
            }

            if (e.key === "Delete" || e.key === "Backspace") {
              state.editManager.cleanUpRange(state.selectedRange.range)
            }

            if (e.key === "ArrowUp") state.moveSelection(0, -1)

            if (e.key === "ArrowDown") state.moveSelection(0, 1)

            if (e.key === "ArrowLeft") state.moveSelection(-1, 0)

            if (e.key === "ArrowRight") state.moveSelection(1, 0)

            if (e.key === "Tab") state.moveSelection(direction, 0)

            if (e.ctrlKey || e.metaKey) {
              if (e.code === "KeyC") state.editManager.copy()

              if (e.code === "KeyV") state.editManager.paste()

              if (e.code === "KeyX") state.editManager.cut()
            }

            return
          }

          if (e.key === "Escape") state.editManager.cancelCell()

          if (e.key === "Enter" && !e.shiftKey) {
            state.editManager.submitCell()

            state.moveSelection(0, 1)

            e.stopPropagation()
            e.preventDefault()
          }

          if (e.key === "Tab") {
            state.editManager.submitCell()

            state.moveSelection(direction, 0)

            e.stopPropagation()
            e.preventDefault()
          }
        }}
      >
        {isMobile ? (
          <>
            <CellControlHeader />

            <ToolBar />
          </>
        ) : (
          <>
            <ToolBar />

            <CellControlHeader />
          </>
        )}

        <div className={styles.body} style={bodyStyles}>
          {/* AXIS */}

          <EmptyAxisCell />

          <div
            className={clsx(styles.gridShard, styles.headerBumper)}
            style={{ width: scrollbarSize() }}
          >
            <EmptyAxisCell />
          </div>

          <div
            className={clsx(styles.gridShard, styles.sidebarBumper)}
            style={{ height: scrollbarSize() }}
          >
            <EmptyAxisCell />
          </div>

          <div className={clsx(styles.gridShard, styles.staticHeader)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Axis
                    axis="x"
                    height={height}
                    width={width}
                    xBaseIndex={0}
                    totalColumns={state.grid.frozenPoint.x}
                    totalRows={state.grid.totalRows}
                    key={`static_sidebar_${refreshSeed}`}
                  />
                )
              }}
            </AutoSizer>
          </div>

          <div className={clsx(styles.gridShard, styles.dynamicHeader)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Axis
                    axis="x"
                    height={height}
                    width={width}
                    xBaseIndex={state.grid.frozenPoint.x}
                    totalColumns={totalDynamicColumns}
                    totalRows={state.grid.totalRows}
                    scrollLeft={bodyScroll?.scrollLeft}
                    key={`body_sidebar_${refreshSeed}`}
                  />
                )
              }}
            </AutoSizer>
          </div>

          <div className={clsx(styles.gridShard, styles.staticSidebar)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Axis
                    axis="y"
                    height={height}
                    width={width}
                    yBaseIndex={0}
                    totalColumns={state.grid.totalColumns}
                    totalRows={state.grid.frozenPoint.y}
                    key={`static_header_${refreshSeed}`}
                  />
                )
              }}
            </AutoSizer>
          </div>

          <div className={clsx(styles.gridShard, styles.dynamicSidebar)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <Axis
                    axis="y"
                    height={height}
                    width={width}
                    yBaseIndex={state.grid.frozenPoint.y}
                    totalColumns={state.grid.totalColumns}
                    totalRows={totalDynamicRows}
                    scrollTop={bodyScroll?.scrollTop}
                    key={`body_header_${refreshSeed}`}
                  />
                )
              }}
            </AutoSizer>
          </div>

          {/* BODY */}

          <div className={clsx(styles.gridShard, styles.bodyTop)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <TableBodyShard
                    shardId={`body_top_${refreshSeed}`}
                    width={width}
                    height={height}
                    xBaseIndex={state.grid.frozenPoint.x}
                    yBaseIndex={0}
                    totalColumns={totalDynamicColumns}
                    totalRows={state.grid.frozenPoint.y}
                    hiddenScroll
                    scrollLeft={bodyScroll?.scrollLeft}
                  />
                )
              }}
            </AutoSizer>
          </div>

          <div className={clsx(styles.gridShard, styles.bodyLeft)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <TableBodyShard
                    shardId={`body_left_${refreshSeed}`}
                    width={width}
                    height={height}
                    xBaseIndex={0}
                    yBaseIndex={state.grid.frozenPoint.y}
                    totalColumns={state.grid.frozenPoint.x}
                    totalRows={totalDynamicRows}
                    hiddenScroll
                    scrollTop={bodyScroll?.scrollTop}
                  />
                )
              }}
            </AutoSizer>
          </div>

          <div className={clsx(styles.gridShard, styles.staticIntersection)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <TableBodyShard
                    shardId={`static_body_${refreshSeed}`}
                    width={width}
                    height={height}
                    xBaseIndex={0}
                    yBaseIndex={0}
                    totalColumns={state.grid.frozenPoint.x}
                    totalRows={state.grid.frozenPoint.y}
                    hiddenScroll
                  />
                )
              }}
            </AutoSizer>
          </div>

          <div className={clsx(styles.gridShard, styles.dynamicIntersection)}>
            <AutoSizer>
              {({ width, height }) => {
                return (
                  <TableBodyShard
                    shardId={`main_body_${refreshSeed}`}
                    width={width}
                    height={height}
                    xBaseIndex={state.grid.frozenPoint.x}
                    yBaseIndex={state.grid.frozenPoint.y}
                    totalColumns={totalDynamicColumns}
                    totalRows={totalDynamicRows}
                    onScroll={setBodyScroll}
                  />
                )
              }}
            </AutoSizer>
          </div>
        </div>
      </div>
    </MatrixContext.Provider>
  )
})

export default Spreadsheet
