import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import { Editor } from "@components/ui/Editor/Editor"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"
import Loader from "@components/ui/Loader/BarLoader"
import { ExpertQuestion, KnowledgeQuestion } from "@framework/types/question"
import Templates from "@components/ui/Templates"
import { countSuffix } from "@utils/numberUtils"
import Icon from "@components/ui/Icon/Icon"

import BaseModal from "../components/BaseModal/BaseModal"
import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import { ModalsTypes } from "../constants"
import useModal from "../useModal"
import { ExpertSearch } from "./ExpertSearch"
import useExpertsList from "./useExpertsList"
import QuestionPreview from "./QuestionPreview"
import ExpertsPreview from "./ExpertsPreview"

import styles from "./AssignExpertToQuestionModal.module.sass"

export interface AssignExpertToQuestionModalProps {
  title?: string
  questions: KnowledgeQuestion[] | ExpertQuestion[] | string[]
  avatarId: string
  onCopy?: () => Promise<void | boolean> | void | boolean
  onSubmit?: (
    expertId: string[],
    questionNote: string
  ) => Promise<void | boolean> | void | boolean
}

export const AssignExpertToQuestionModal: React.FC<AssignExpertToQuestionModalProps> =
  observer(
    ({ title = "Answer questions", questions, avatarId, onCopy, onSubmit }) => {
      const { hideModal } = useModal(
        ModalsTypes.ASSIGN_EXPERTS_TO_QUESTIONS_MODAL
      )

      const [loading, setLoading] = React.useState(false)
      const [expertIds, setExpertIds] = React.useState<string[]>([])
      const [questionNote, setQuestionNote] = React.useState<string>("")

      const [isUserSelectorShown, showUserSelector] = React.useState(true)

      const experts = useExpertsList({ avatarId })

      const handleSubmit = async () => {
        setLoading(true)
        const shouldClose = (await onSubmit?.(expertIds, questionNote)) ?? true
        setLoading(false)
        if (shouldClose) hideModal()
      }

      const handleCopyLink = async () => {
        setLoading(true)
        const shouldClose = (await onCopy?.()) ?? true
        setLoading(false)
        if (shouldClose) hideModal()
      }

      const handleNext = () =>
        isUserSelectorShown ? showUserSelector(false) : handleSubmit()

      const handleBack = () => showUserSelector(true)

      const handleClose = hideModal

      return (
        <BaseModal
          title={<ModalTitle titleText={title} />}
          className={styles.root}
          containerClassName={styles.container}
          onClose={hideModal}
        >
          <Templates.RollScript
            gutter="24"
            footerSocket={
              <ModalFooterContainer>
                <Button
                  disabled={loading}
                  variant="outlined"
                  size="big"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  disabled={!expertIds.length || loading}
                  variant="contained"
                  color="primary"
                  size="big"
                  onClick={handleNext}
                  after={loading && <Loader />}
                >
                  {isUserSelectorShown ? "Next" : "Assign"}
                </Button>
              </ModalFooterContainer>
            }
          >
            <List gutter="24">
              <Label
                id="selected_questions"
                label={`Question${countSuffix(questions.length)} to answer`}
              >
                <QuestionPreview questions={questions} />
              </Label>

              {isUserSelectorShown ? (
                <>
                  {onCopy != null && (
                    <>
                      <Label id="share_by_link" label="Share by Direct Link">
                        <Button
                          className={styles.fullWidth}
                          variant="outlined"
                          color="default"
                          onClick={handleCopyLink}
                          disabled={loading}
                          before={<Icon name="link" />}
                          after={loading && <Loader />}
                        >
                          Copy Link
                        </Button>
                      </Label>

                      <div className={styles.divider}>or</div>
                    </>
                  )}

                  <List gutter="4" className={styles.listContainer}>
                    <ExpertSearch
                      items={experts.list}
                      isLoading={experts.isLoading}
                      value={expertIds}
                      onChange={setExpertIds}
                    />
                  </List>
                </>
              ) : (
                <>
                  <Label id="selected_experts" label="Experts to assign">
                    <ExpertsPreview
                      value={expertIds}
                      onClick={handleBack}
                      experts={experts.list}
                    />
                  </Label>

                  <Label id="question_note" label="Add Notes (Optional)">
                    <Editor
                      placeholder="Add additional context to expert"
                      onChange={setQuestionNote}
                      defaultValue={questionNote}
                    />
                  </Label>
                </>
              )}
            </List>
          </Templates.RollScript>
        </BaseModal>
      )
    }
  )

export default AssignExpertToQuestionModal
