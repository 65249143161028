import React from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import ScrollableContainer from "@components/ui/ScrollableContainer/ScrollableContainer"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store/index"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import CheckboxCard from "@components/ui/CheckboxCard/CheckboxCard"
import List from "@components/ui/List/List"
import UserCard from "@components/ui/UserCard/UserCard"
import ModalFooterContainer from "@components/modals/components/ControlFooter/ModalFooterContainer"
import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import { ModalsTypes } from "@components/modals/constants"
import useModal from "@components/modals/useModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import ListItem from "@components/ui/ListItem/ListItem"
import Text from "@components/ui/Typography/Text"

import styles from "./AssignUserRolesModal.module.sass"

export interface AssignUserRolesModalProps {
  userId: string
}

export const AssignUserRolesModal: React.FC<AssignUserRolesModalProps> =
  observer(({ userId }) => {
    const alert = useAlert()
    const modal = useModal(ModalsTypes.ASSIGN_USER_ROLES_MODAL)

    const { rolesStore, adminUsersStore } = useStore()
    const { rolesController, adminUsersController } = useController()

    const user = adminUsersStore.getUserById(userId)

    const currentSelected = React.useMemo(
      () => user?.userRoles?.map((it) => it.id) ?? [],
      [user?.userRoles]
    )

    const [selected, setSelected] = React.useState<string | null>(() =>
      currentSelected.length === 1 ? currentSelected[0] : null
    )

    const handleSubmit = async () => {
      if (selected == null) return

      const error = await adminUsersController.changeUserRoles(userId, [
        selected,
      ])
      if (error) alert.error(error)
      else {
        alert.success(<>Role for user {user?.email} was successfully updated</>)
        modal.hideModal()
      }
    }

    const handleCancel = modal.hideModal

    React.useEffect(() => {
      const { roleIds, error, isLoading } = rolesStore
      if (!roleIds.length && !error && !isLoading) rolesController.init()
    }, [])

    const total = rolesStore.roleIds.length

    const isLoading = rolesStore.isLoading || adminUsersStore.isLoading
    const isValid =
      selected != null &&
      (currentSelected.length !== 1 || currentSelected[0] !== selected)

    return (
      <BaseModal
        title={
          <ModalTitle
            titleText="Edit User Role"
            subtitleText="Select role you'd like to assign to user"
          />
        }
        containerClassName={styles.root}
        onClose={modal.hideModal}
      >
        {user != null && (
          <UserCard
            variant="card"
            className={styles.userCard}
            fullName={`${user.firstName} ${user.lastName}`}
            metaInfo={user.email}
          />
        )}

        <ScrollableContainer className={styles.listContainer}>
          {isLoading ? (
            <Loader size="large" primary fluid />
          ) : total ? (
            <List gutter="4">
              {rolesStore.roleIds.map((roleId) => {
                const item = rolesStore.roles[roleId]
                if (item == null) return null
                return (
                  <ListItem
                    active={selected === item.id}
                    onClick={() => setSelected(item.id)}
                    key={item.id}
                  >
                    <Text title={item.name} upperCase>
                      {item.name}
                    </Text>
                  </ListItem>
                )
              })}
            </List>
          ) : (
            <NotFound>No roles found</NotFound>
          )}
        </ScrollableContainer>

        <ModalFooterContainer className={styles.footer}>
          <Button
            onClick={handleCancel}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isLoading || !isValid}
            color="primary"
          >
            Save
          </Button>
        </ModalFooterContainer>
      </BaseModal>
    )
  })

export default AssignUserRolesModal

const hasDifference = (arr1: string[], arr2: string[]) =>
  arr1.length !== arr2.length || arr1.find((it) => !arr2.includes(it))
