import { FieldProps } from "formik"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useEffect, useMemo, useState } from "react"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListProps,
} from "react-virtualized"

import Dropdown from "@components/prototypes/FilterSidebar/components/Dropdown/Dropdown"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import ListItem from "@components/ui/ListItem/ListItem"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import Templates from "@components/ui/Templates"
import Text from "@components/ui/Typography/Text"
import { useController, useStore } from "@store"
import { ProductCategory } from "@store/product/product.store"
import { searchBy } from "@utils/optionsUtils"

import styles from "./ProductFilterModal.module.sass"

interface ProductFilterIdentifierFieldProps extends FieldProps<string[]> {
  label?: string
  field: any
  form: any
}

const ProductFilterIdentifierField: React.FC<ProductFilterIdentifierFieldProps> =
  observer(({ label, field, form }) => {
    const [open, setOpen] = useState(false)

    const { value: fieldValue = [], name } = field
    const { setFieldValue } = form

    const value = [...fieldValue]

    const { contentManagerController } = useController()
    const {
      contentManagerStore: { categories, loadingCategories },
      productComparisonStore: {
        productStore: { filterSearchQuery },
      },
    } = useStore()

    useEffect(() => {
      contentManagerController.getCategories("")
    }, [])

    const productCategories: ProductCategory[] = useMemo(() => {
      return Array.from(
        searchBy(categories, filterSearchQuery, (category) => category.name),
        (category) => toJS(category)
      )
    }, [categories, filterSearchQuery])

    const handleCategoryClick = (newValue: ProductCategory) => {
      const foundIdx = value?.findIndex((value) => value.id === newValue.id)
      if (foundIdx === -1) {
        setFieldValue(name, [...value, newValue])
        return
      }
      value.splice(foundIdx, 1)
      setFieldValue(name, value)
    }

    const renderRow: ListProps["rowRenderer"] = ({
      index,
      key,
      parent,
      style,
    }) => {
      const category = productCategories[index]

      if (!category)
        return (
          <div style={style} key={key}>
            <ListItem>
              <Text color="text50Color" variant="inherit">
                loading...
              </Text>
            </ListItem>
          </div>
        )

      const active = !!value?.find((cat) => cat.id === category.id)

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
          key={`${key}-cached`}
        >
          <div style={style} key={key}>
            <ListItem
              active={active}
              onClick={() => handleCategoryClick(category as ProductCategory)}
            >
              <Text>{category.name}</Text>
            </ListItem>
          </div>
        </CellMeasurer>
      )
    }

    const cache = useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 42,
      })
    }, [])

    const isChecked = !!value?.length

    const handleUncheck = (e: React.MouseEvent) => {
      if (isChecked) setFieldValue(name, undefined)
      else setOpen(true)
      e.stopPropagation()
    }

    return (
      <Dropdown
        title={label}
        opened={open}
        onClick={() => setOpen((value) => !value)}
        before={
          <CheckboxWithLabel
            checked={!!value?.length}
            onClick={handleUncheck}
          />
        }
      >
        <Templates.RollScript className={styles.root}>
          <div className={styles.container}>
            {productCategories.length === 0 ? (
              loadingCategories ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No Identifiers found</NotFound>
              )
            ) : (
              <InfiniteLoader
                isRowLoaded={({ index }) => productCategories[index] != null}
                loadMoreRows={async () => {}}
                rowCount={productCategories.length}
                minimumBatchSize={0}
                threshold={0}
              >
                {({ onRowsRendered, registerChild }) => (
                  <AutoSizer>
                    {({ width }) => (
                      <List
                        rowCount={productCategories.length}
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        rowHeight={cache.rowHeight}
                        rowRenderer={renderRow}
                        height={300}
                        width={width}
                      />
                    )}
                  </AutoSizer>
                )}
              </InfiniteLoader>
            )}
          </div>
        </Templates.RollScript>
      </Dropdown>
    )
  })

export default ProductFilterIdentifierField
