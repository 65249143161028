import clsx from "clsx"
import React from "react"
import { NavLink, useLocation } from "react-router-dom"

import CitationBadge from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/ImageResult/AnswerOriginalView/components/CitationBadge"

import styles from "./MarkdownNavLink.module.sass"

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode & React.ReactNode[]
}

const MarkdownNavLink: React.FC<Props> = ({
  href = "/",
  className,
  children,
  ...restProps
}) => {
  const location = useLocation()

  return (
    <NavLink
      to={href}
      {...restProps}
      className={clsx(className, {
        [styles.citation]: isCitation(children),
      })}
      state={{ backTo: location.pathname }}
    >
      {isCitation(children) ? (
        <CitationBadge citationNumber={Number(children)} />
      ) : (
        children
      )}
    </NavLink>
  )
}

export default MarkdownNavLink

const isCitation = (children: React.ReactNode) => {
  return (
    Array.isArray(children) &&
    children.length === 1 &&
    typeof children[0] === "string" &&
    !Number.isNaN(Number(children[0]))
  )
}
