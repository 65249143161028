import React from "react"
import { useField } from "formik"

import Switch, { SwitchProps } from "@components/ui/Switch/Switch"

export interface FormSwitchProps extends SwitchProps<string> {
  name: string
}

export const FormSwitch: React.FC<FormSwitchProps> = ({ name, ...rest }) => {
  const [field, _, helpers] = useField<any>(name)

  return (
    <Switch
      {...rest}
      checked={field.value}
      onChange={(value) => helpers.setValue(value)}
    />
  )
}

export default FormSwitch
