import MatrixStore from "./state/MatrixStore"
import { CellValidationRule, MatrixSnapshot } from "./types"

type CellValidationConfig = {
  ref: string
  rule: string
}

export const createMatrix = (
  config: {
    initialValue?: string[][]
    validationRules?: Record<string, CellValidationRule>
    validations?: CellValidationConfig[]
    readonly?: boolean
  } = {}
) => {
  const instance = new MatrixStore({ readonlyMode: config.readonly })

  if (config.validationRules) {
    Object.entries(config.validationRules).forEach(([ruleId, rule]) => {
      if (!instance.isValidationRuleRegistered(ruleId))
        instance.registerValidationRule(ruleId, rule)
    })
  }

  instance.init({ initialValue: config.initialValue })

  if (config.validations) {
    config.validations.forEach((it) =>
      instance.applyValidationRule(it.ref, it.rule)
    )
  }

  return instance
}

export const createMatrixFromSnapshot = (
  config: {
    snapshot?: MatrixSnapshot | null
    validationRules?: Record<string, CellValidationRule>
    readonly?: boolean
  } = {}
) => {
  const instance = new MatrixStore({
    snapshot: config.snapshot || undefined,
    readonlyMode: config.readonly,
  })

  if (config.validationRules) {
    Object.entries(config.validationRules).forEach(([ruleId, rule]) => {
      if (!instance.isValidationRuleRegistered(ruleId))
        instance.registerValidationRule(ruleId, rule)
    })
  }

  instance.init()

  return instance
}
