import { toJS, makeAutoObservable } from "mobx"

import {
  DigestSubscriptionOption,
  UpdateUserFormData,
  UserData,
} from "@framework/types/user"
import userService from "@services/user.service"

export class UserStore {
  constructor() {
    makeAutoObservable(this)
  }

  isUserLoading: boolean = false

  isUserActionsLoading: boolean = false

  isSubscriptionOptionsLoading: boolean = false

  errorMessage: string = ""

  loadActionsErrorMessage: string | null = null

  loadSubscriptionsErrorMessage: string | null = null

  user: UserData | null = null

  userActions: string[] | null = null

  subscriptionOptions: DigestSubscriptionOption[] = []

  get fullName() {
    if (!this.user) return "User Name"
    return `${this.user.firstName} ${this.user.lastName}`
  }

  get isSuperAdmin() {
    if (!this.user) return false
    return this.user.userRoles?.some(
      (role) => role.name.toLowerCase() === "superadmin"
    )
  }

  loadUserData = async () => {
    this.isUserLoading = true
    this.errorMessage = ""
    this.isUserActionsLoading = true
    this.loadActionsErrorMessage = null
    try {
      const [profileResp, actionsResp] = await Promise.all([
        userService.getUserData(),
        userService.getUserActions(),
      ])

      this.setUserData(profileResp.data.data)
      this.setUserActions(actionsResp.data.data ?? [])
    } catch (error: any) {
      this.errorMessage = "Loading failed"
    } finally {
      this.isUserActionsLoading = false
      this.isUserLoading = false
    }
    return this.errorMessage
  }

  loadSubscriptionOptions = async () => {
    this.isSubscriptionOptionsLoading = true
    this.loadSubscriptionsErrorMessage = ""
    try {
      const response = await userService.getDigestSubscriptionOptions()

      this.subscriptionOptions = response.data.data || []
    } catch (error: any) {
      this.loadSubscriptionsErrorMessage = "Loading failed"
    } finally {
      this.isSubscriptionOptionsLoading = false
    }
    return this.loadSubscriptionsErrorMessage
  }

  loadAllowedUserActions = async () => {
    try {
      this.isUserActionsLoading = true
      this.loadActionsErrorMessage = null
      const actionsResp = await userService.getUserActions()

      this.setUserActions([...(actionsResp.data.data ?? [])])
    } catch (error: any) {
      this.loadActionsErrorMessage = "Failed to load user actions"
    } finally {
      this.isUserActionsLoading = false
    }
    return this.loadActionsErrorMessage
  }

  setUserData = (user: UserData | null = null) => {
    this.user = user
  }

  setUserActions = (actions: string[] | null = null) => {
    this.userActions = actions
  }

  uploadAvatarPending: boolean = false

  uploadAvatarError: string | null = null

  uploadAvatar = async (file: File) => {
    this.uploadAvatarPending = true
    this.uploadAvatarError = ""
    try {
      const form = new FormData()
      form.append("file", file)

      const response = await userService.updateUserAvatar(form)

      if (response.data) {
        this.user = { ...toJS(this.user), ...response.data }
        return
      }
    } catch (error: any) {
      const defaultError = "Failed to update user photo upload"

      const { response } = error

      if (!response) {
        this.uploadAvatarError = defaultError
        return
      }
      if (response.status === 413) {
        this.uploadAvatarError = "Image file is to large"
        return
      }
      this.uploadAvatarError = defaultError
    } finally {
      this.uploadAvatarPending = false
    }
  }

  setUploadAvatarError = (error: string = "") => {
    this.uploadAvatarError = error
  }

  isUserProfileUpdating = false

  profileUpdateError = ""

  uploadProfile = async (form: UpdateUserFormData) => {
    this.isUserProfileUpdating = true
    this.profileUpdateError = ""
    try {
      const response = await userService.updateUserProfile(form)

      if (response?.data?.data) {
        this.user = { ...this.user, ...response.data.data }
        return true
      }
      throw new Error("Unknown error")
    } catch (error: any) {
      this.profileUpdateError = "Failed to update form"
    } finally {
      this.isUserProfileUpdating = false
    }
    return false
  }

  setProfileUpdateError = (error: string = "") => {
    this.profileUpdateError = error
  }
}

export default UserStore
