import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import Button from "@components/ui/Button/Button"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import { MANUAL_UPLOAD_FILE_STATUS } from "@framework/types/content-manager"
import { getUniqueNameForFile } from "@utils/file"
import MultiSelect from "@components/ui/MultiSelect/MultiSelect"

import { ModalsTypes } from "../constants"
import SelectedFile from "./SelectedFile"
import DocumentDropZoneContainer from "./DocumentDropZoneContainer"

import styles from "./ContentManagerUploadFormModal.module.sass"

export type ContentManagerUploadFormModalProps = {
  label: string
  onSubmit: () => void
}

const ContentManagerUploadFormModal: React.FC<ContentManagerUploadFormModalProps> =
  observer(({ label, onSubmit }) => {
    const [state, setState] = useState<{
      files: File[]
      contentType?: string
      companyId?: string
      fileContentTypes: Record<string, string>
    }>({
      files: [],
      fileContentTypes: {},
    })
    const [loading, setLoading] = useState(false)
    const alert = useAlert()

    const { hideModal } = useModal(
      ModalsTypes.CONTENT_MANAGER_UPLOAD_FORM_MODAL
    )

    const {
      contentManagerStore,
      datatypesStore: { data: allDataTypes },
    } = useStore()
    const {
      contentManagerController,
      datatypesController: { loadAllDataTypes },
    } = useController()
    const { contentType, files, fileContentTypes } = state
    const { companies } = contentManagerStore || {}

    const handleFileUpload = async () => {
      const filesToUpload = files.filter(
        (file) =>
          contentManagerStore?.uploads[getUniqueNameForFile(file)]?.status !==
            MANUAL_UPLOAD_FILE_STATUS.COMPLETED &&
          contentManagerStore?.uploads[getUniqueNameForFile(file)]?.status !==
            MANUAL_UPLOAD_FILE_STATUS.UPLOADING
      )
      if (!filesToUpload.length) return
      const uploadPromises = filesToUpload.map(async (file) => {
        await contentManagerController.uploadDocument(
          file,
          contentType,
          state.companyId
        )
      })
      setLoading(true)
      await Promise.all(uploadPromises)
      setLoading(false)
    }

    const onFilesDrop = (newFiles: File[]) => {
      const existingFiles = files.map((file) => {
        return file.name
      })

      setState({ ...state, fileContentTypes })
      const uniqueFiles = newFiles.filter((file) => {
        const cType = allDataTypes?.find((ct) => ct.id === contentType)
        fileContentTypes[file.name] = cType?.name || ""
        return !existingFiles.includes(file.name)
      })

      setState({
        ...state,
        files: [...uniqueFiles, ...files],
      })
    }

    useEffect(() => {
      handleFileUpload()
    }, [state.files])

    useEffect(() => {
      contentManagerController.getCompanies()
      loadAllDataTypes()
    }, [])

    const closeModal = () => {
      if (Object.keys(contentManagerStore.uploads).length) {
        alert.success(
          "Documents are uploaded successfully, it may take few minutes to show in a list"
        )
        onSubmit()
      }
      contentManagerStore.uploads = {}
      hideModal()
    }

    return (
      <BaseModal
        className={styles.root}
        title={
          <ModalTitle
            titleText={<div className={styles.titleContainer}>{label}</div>}
          />
        }
        containerClassName={styles.container}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button variant="outlined" size="medium" onClick={closeModal}>
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={loading || !files.length}
                size="medium"
                onClick={closeModal}
              >
                Done
              </Button>
            </div>
          }
        >
          <div className={styles.inputsWrapper}>
            <div className={styles.inputContainer}>
              <Text variant="body2">Manufacturer *</Text>
              <MultiSelect
                options={companies?.map((item) => ({
                  id: item.id,
                  name: item.name,
                  value: item.name,
                }))}
                placeholder="Select Manufacturer"
                isRounded
                isSingleSelect
                variant="secondary"
                defaultSelectedItems={state.companyId ? [state.companyId] : []}
                onSelectionChange={(selectedItems) => {
                  setState({
                    ...state,
                    companyId: selectedItems?.[0],
                  })
                }}
              />
            </div>
            <div className={styles.inputContainer}>
              <Text variant="body2">Content Type</Text>
              <MultiSelect
                options={(allDataTypes || [])?.map((item) => ({
                  id: item.id,
                  name: item.name,
                  value: item.name,
                }))}
                placeholder="Select content type"
                defaultSelectedItems={
                  state.contentType ? [state.contentType] : []
                }
                isRounded
                variant="secondary"
                isSingleSelect
                onSelectionChange={(selectedItems) => {
                  setState({
                    ...state,
                    contentType: selectedItems[0] || "",
                  })
                }}
              />
            </div>
            <Text variant="body2">
              <b>Note</b>: Content type can be updated at a later stage once you
              have uploaded the contents.
            </Text>
            <DocumentDropZoneContainer
              disabled={!state.companyId}
              onFilesDrop={onFilesDrop}
            />
            <div className={styles.fileList}>
              {files.map((file) => (
                <SelectedFile
                  contentType={fileContentTypes[file.name]}
                  key={file.name}
                  file={file}
                />
              ))}
            </div>
          </div>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default ContentManagerUploadFormModal
