import React from "react"
import { observer } from "mobx-react-lite"

import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import MenuItem from "@components/containers/Sidebar/MenuItem"
import { ContextOption } from "@framework/types/common"
import IconButton from "@components/ui/IconButton/IconButton"
import { useStore } from "@store"

type ContextMenuProps = {
  showContentTypeModal: () => void
  deleteDocument: () => void
}

const ContextMenu: React.FC<ContextMenuProps> = observer(
  ({ showContentTypeModal, deleteDocument }) => {
    const { isOpened, handleToggle, setOpened } = useToggle()
    const { restrictionsStore: access } = useStore()

    const contextOptions: ContextOption<string>[] = []

    if (access.canAssignContentType) {
      contextOptions.push({
        label: "Assign Content Type",
        icon: "edit",
        type: "Assign",
        onClick: () => {
          showContentTypeModal()
          setOpened(false)
        },
      })
    }

    if (access.canDeleteContent) {
      contextOptions.push({
        label: "Remove",
        icon: "trash-can",
        color: "red",
        type: "Remove",
        onClick: deleteDocument,
      })
    }

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            {contextOptions.map(({ label, type, ...rest }) => (
              <MenuItem key={type} onClick={() => setOpened(false)} {...rest}>
                {label}
              </MenuItem>
            ))}
          </TooltipContainer>
        }
      >
        <IconButton size="medium" active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
