import React from "react"
import { observer } from "mobx-react-lite"

import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { runTask } from "@utils/promise"

import { useMatrixContext } from "./MatrixContext"
import { rangeToCode } from "./utils"
import HighlightedText from "./parser/FormulaPreview"
import Text from "../Typography/Text"
import RichTextarea from "./TextEditorCell/RichTextarea"
import Icon from "../Icon/Icon"

import styles from "./CellControlHeader.module.sass"

interface CellControlHeaderProps {}

export const CellControlHeader: React.FC<CellControlHeaderProps> = observer(
  () => {
    const inputRef = React.useRef<HTMLTextAreaElement>(null)

    const isMobile = useMediaQuery(AppMediaQueries.minTablet)

    const { selectedRange, editManager } = useMatrixContext()

    const control = editManager.activeCellState

    const cell = editManager.getCellAtPoint(selectedRange.origin)

    const inputProps = control
      ? {
          value: control.input,
          onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const text = e.target.value
            control.setInput(text)
          },
          onSelect: (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
            control.setSelection({
              start: e.currentTarget.selectionStart,
              end: e.currentTarget.selectionEnd,
            })
          },
        }
      : {
          value: cell.state.input,
          onFocus: () =>
            editManager.editCell(selectedRange.origin, {
              focusCell: false,
            }),
        }

    const formula = control ? control.formula : cell.state.formula

    React.useEffect(() => {
      if (control != null && control?.autoFocusCell && isMobile) {
        runTask(() => {
          inputRef.current?.focus()
          window.scrollTo({ top: inputRef.current?.clientTop })
        })
      }
    }, [isMobile, control])

    return (
      <div className={styles.root}>
        <Text className={styles.address} variant="caption1">
          {rangeToCode(selectedRange.range)}
        </Text>

        <Text className={styles.status} color="text40Color">
          {cell.readonly ? <Icon name="lock" title="Readonly" /> : null}
        </Text>

        <div className={styles.input}>
          <RichTextarea
            ref={inputRef}
            className={styles.textarea}
            disabled={cell.readonly}
            placeholder={isMobile ? "Enter value..." : undefined}
            {...inputProps}
            style={{ width: "100%" }}
          >
            <HighlightedText value={inputProps.value} tree={formula} />
          </RichTextarea>
        </div>
      </div>
    )
  }
)

export default CellControlHeader
