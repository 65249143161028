import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useAlert } from "react-alert"
import debounce from "lodash/debounce"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import List from "@components/ui/List/List"
import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import { useController, useStore } from "@store"
import ListItem from "@components/ui/ListItem/ListItem"
import { IconName } from "@components/ui/Icon/IconName"
import { ConnectorDocument } from "@framework/types/content-manager"
import { DataCategoryEntity } from "@framework/types/knowledge"
import Loader from "@components/ui/Loader/BarLoader"
import Text from "@components/ui/Typography/Text"
import Templates from "@components/ui/Templates"
import NotFound from "@components/ui/NotFound/NotFound"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import Icon from "@components/ui/Icon/Icon"

import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import InfiniteCollection from "../EditModal/components/BaseContainer/InfiniteCollection"

import styles from "./SelectContentTypeModal.module.sass"

export type SelectContentTypeModalProps = {
  documents: ConnectorDocument[]
  onAssign: () => void
}
const SelectContentTypeModal: React.FC<SelectContentTypeModalProps> = observer(
  ({ documents, onAssign }) => {
    const [selectedId, setSelectedId] = useState<string>()
    const { hideModal } = useModal(ModalsTypes.SELECT_CONTENT_TYPE_MODAL)
    const { allDatatypesStore, contentManagerStore } = useStore()
    const { contentManagerController } = useController()
    const [searchTerm, setSearchTerm] = useState("")
    const alert = useAlert()

    const assignContentType = async () => {
      if (!selectedId) return
      const error =
        await contentManagerController.updateContentTypesForDocuments(
          selectedId,
          documents
        )
      if (error) {
        alert.error(error)
      } else {
        hideModal()
        onAssign()
      }
    }

    const debouncedSearch = React.useCallback(
      debounce(allDatatypesStore.search, 300),
      []
    )

    React.useEffect(() => {
      allDatatypesStore.search(searchTerm)
    }, [])

    const renderRow = (item: DataCategoryEntity) => {
      const active = selectedId === item.id

      return (
        <ListItem active={active} onClick={() => setSelectedId(item.id)}>
          <List direction="row" gutter="8" align="center">
            <DocumentIcon
              icon={(item.iconUrl as IconName) ?? "global"}
              size="big"
            />
            {item.name}
          </List>
        </ListItem>
      )
    }

    return (
      <BaseModal
        className={styles.root}
        containerClassName={styles.container}
        title={<ModalTitle titleText="Assign Content Type" />}
      >
        <Templates.RollScript
          gutter="24"
          footerSocket={
            <ModalFooterContainer>
              <Button
                disabled={contentManagerStore.loadingUpdateContentTypes}
                variant="outlined"
                onClick={hideModal}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                after={
                  contentManagerStore.loadingUpdateContentTypes && <Loader />
                }
                disabled={
                  contentManagerStore.loadingUpdateContentTypes || !selectedId
                }
                onClick={assignContentType}
              >
                Assign
              </Button>
            </ModalFooterContainer>
          }
        >
          <div className={styles.search}>
            <Text variant="body2">Select the content type</Text>
            <TextInput
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
                debouncedSearch(e.target.value)
              }}
              before={<Icon name="search" />}
            />
          </div>
          <div className={styles.list}>
            {allDatatypesStore.state.total === 0 ? (
              allDatatypesStore.state.isLoading ? (
                <Loader size="large" fluid />
              ) : (
                <NotFound>No Content Types found</NotFound>
              )
            ) : (
              <InfiniteCollection<DataCategoryEntity>
                total={allDatatypesStore.state.total}
                getItem={allDatatypesStore.getByIndex}
                renderItem={renderRow}
              />
            )}
          </div>
        </Templates.RollScript>
      </BaseModal>
    )
  }
)

export default SelectContentTypeModal
