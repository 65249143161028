import { makeAutoObservable } from "mobx"

import { Point, Range } from "@framework/types/common"

import {
  getFocusedNode,
  Parser,
  ParseResult,
  traceFocusedNode,
  tokenize,
  setFocusedNode,
} from "../parser"
import { stringifyFormula } from "../parser/utils"
import { rangeToCode, refToRange } from "../utils"
import FunctionManager from "./FunctionManager"
import { FunctionDescription } from "../types"
import { Function, Reference } from "../parser/types"

const formulaParser = new Parser()

class CellEditorState {
  // injections

  functionManager: FunctionManager

  // state

  input: any

  touched: boolean

  formula: ParseResult | null

  error: string | null

  inputSelection: Range<number>

  autoFocusCell: boolean = true

  suggestion: {
    formula: ParseResult
    nodePath: (string | number)[]
    functions: FunctionDescription[]
  } | null

  constructor(options: {
    initialValue: any
    functionManager: FunctionManager
    autoFocusCell?: boolean
  }) {
    this.functionManager = options.functionManager

    this.input = options.initialValue ?? ""
    this.formula = null
    this.suggestion = null
    this.error = null
    this.autoFocusCell = options.autoFocusCell ?? true
    this.touched = false
    this.inputSelection = { start: -1, end: -1 }

    this.process()

    makeAutoObservable(this)
  }

  get validRefEntries(): [string, Range<Point>][] {
    const refs = this.formula?.refs ?? []
    return refs.reduce<[string, Range<Point>][]>((acc, it) => {
      try {
        acc.push([it, refToRange(it)])
      } catch (error) {
        // ignore
      }
      return acc
    }, [])
  }

  setInput = (value?: string) => {
    this.input = value
    this.touched = true
    this.process()
  }

  process = () => {
    const text = this.input

    this.formula = null
    this.error = null

    if (typeof text !== "string") return

    if (!text?.startsWith("=")) return

    const tokens = tokenize(text.substring(1))

    this.formula = formulaParser.parse(tokens)

    this.getSuggestions()
  }

  normalize = () => {
    try {
      if (this.formula != null) {
        if (!this.formula?.isValid) throw new Error("Formula parsing error")

        const normalFormulaStr = stringifyFormula(this.formula.matched, true)

        this.formula = formulaParser.parse(tokenize(normalFormulaStr))
        this.input = `=${normalFormulaStr}`
      }
    } catch (error: any) {
      this.error =
        typeof error.message === "string" ? error.message : "Unexpected error"
    }
    return this.input
  }

  setSelection = (selection: Range<number>) => {
    this.inputSelection = selection
    this.getSuggestions()
  }

  getSuggestions = () => {
    const { inputSelection, formula, input } = this

    this.suggestion = null

    if (formula == null) return
    if (inputSelection.start < 0 || inputSelection.end < 0) return
    if (inputSelection.start !== inputSelection.end) return

    const nodePath = traceFocusedNode(formula.matched, inputSelection.end - 1)

    if (nodePath == null) return

    const node = getFocusedNode(formula.matched, nodePath)

    if (node.type !== "unknown" && node.type !== "const") return

    if (!node.token.trim() && input.trim() !== "=") return

    const functions = this.functionManager.findFunctions(node.token)

    this.suggestion = {
      formula,
      nodePath,
      functions,
    }
  }

  applyFunctionSuggestion = (func: FunctionDescription) => {
    if (this.suggestion == null) return

    const { formula, nodePath } = this.suggestion

    const newNode: Function = {
      type: "func",
      name: func.name,
      token: func.name,
      open: true,
      closed: false,
      arguments: [],
    }

    formula.matched = setFocusedNode(formula.matched, nodePath, newNode)

    const newInput = `=${stringifyFormula(formula.matched)}`

    this.setInput(newInput)
    this.setSelection({ start: newInput.length, end: newInput.length })
  }

  applySelectedRef = (point: Range<Point>) => {
    const { inputSelection, formula } = this

    if (formula == null) return
    if (inputSelection.end <= 0) return

    const nodePath = traceFocusedNode(formula.matched, inputSelection.end - 1)

    if (nodePath == null) return

    const refToken = rangeToCode(point)

    const newNode: Reference = {
      type: "ref",
      name: refToken,
      token: refToken,
    }

    formula.matched = setFocusedNode(formula.matched, nodePath, newNode)

    const newInput = `=${stringifyFormula(formula.matched)}`

    this.setInput(newInput)
    this.setSelection({
      start: Math.max(newInput.length - refToken.length, 0),
      end: newInput.length,
    })
  }

  isRefSelectionAvailable = () => {
    const { inputSelection, formula } = this

    if (formula == null) return false
    if (inputSelection.start < 0 || inputSelection.end < 0) return false

    const nodePath = traceFocusedNode(formula.matched, inputSelection.end - 1)

    if (nodePath == null) return false

    const node = getFocusedNode(formula.matched, nodePath)

    const selectionSize = inputSelection.end - inputSelection.start

    return (
      node.token.trim() === "" ||
      (node.type === "ref" && node.token.length === selectionSize)
    )
  }
}

export default CellEditorState
