import { makeAutoObservable } from "mobx"

import EntitiesApi, {
  GetUserAndGroupsRequest,
} from "@services/entities.service"
import RootStore from "@store/RootStore"
import { UserAndGroup } from "@framework/types/user"
import SolutionAPI from "@services/solutions.service"

import EntitiesStore from "./entities.store"

export default class EntitiesController {
  entitiesStore: EntitiesStore

  constructor(injections: RootStore) {
    this.entitiesStore = injections.entitiesStore

    makeAutoObservable(this)
  }

  initializeSelectedUsers = (solutionUsers: UserAndGroup[], query?: string) => {
    const store = this.entitiesStore
    const lowerCaseQuery = query?.toLowerCase()

    const filteredUsers = lowerCaseQuery
      ? solutionUsers.filter((user) => {
          const fullName = `${user.firstName ?? ""} ${user.lastName ?? ""}`
            .toLowerCase()
            .trim()
          return (
            user.name?.toLowerCase().includes(lowerCaseQuery) ||
            fullName.includes(lowerCaseQuery) ||
            user.email?.toLowerCase().includes(lowerCaseQuery)
          )
        })
      : solutionUsers

    store.selectedUserAndGroups = [...solutionUsers]
    store.allUserAndGroups = [...filteredUsers]
  }

  getUserAndGroups = async (params: GetUserAndGroupsRequest) => {
    const store = this.entitiesStore
    if (store.loadingUserAndGroups) return
    store.loadingUserAndGroups = true
    store.errorUserAndGroups = null
    if (params.page === 1) {
      store.allUserAndGroups = []
      this.initializeSelectedUsers(store.selectedUserAndGroups, params.query)
    }

    try {
      const response = await EntitiesApi.getUserAndGroups(params)
      store.usersAndGroups = response.data.data
      store.userAndGroupsPagination = response.data.pagination
      const uniqueUsersAndGroups = response.data.data.filter(
        (newUser) =>
          !store.allUserAndGroups.some(
            (existingUser) => existingUser.id === newUser.id
          )
      )
      store.allUserAndGroups = [
        ...store.allUserAndGroups,
        ...uniqueUsersAndGroups,
      ]
    } catch (error: any) {
      store.errorUserAndGroups = error.message
    } finally {
      store.loadingUserAndGroups = false
    }
  }

  addOrRemoveSelectedUserAndGroup = (user: UserAndGroup) => {
    const store = this.entitiesStore

    const index = store.selectedUserAndGroups.findIndex((u) => u.id === user.id)
    if (index === -1) {
      store.selectedUserAndGroups.push(user)
    } else {
      store.selectedUserAndGroups.splice(index, 1)
    }
  }

  isUserGroupSelected = (userId: string): boolean => {
    const store = this.entitiesStore
    return store.selectedUserAndGroups.some((u) => u.id === userId)
  }

  updateSolutionPermission = async (
    solutionId: string,
    solutionUsers: UserAndGroup[],
    allowAllEntities: boolean
  ): Promise<boolean> => {
    const store = this.entitiesStore
    let addEntities: { entityId: string; entityType: string }[] = []
    let removeEntities: { entityId: string; entityType: string }[] = []

    if (!allowAllEntities) {
      addEntities = store.selectedUserAndGroups
        .filter(
          (user) =>
            !solutionUsers.some((solutionUser) => solutionUser.id === user.id)
        )
        ?.map((item) => ({ entityId: item.id, entityType: item.type }))
      removeEntities = solutionUsers
        .filter(
          (solutionUser) =>
            !store.selectedUserAndGroups.some(
              (user) => user.id === solutionUser.id
            )
        )
        ?.map((item) => ({ entityId: item.id, entityType: item.type }))
    }

    store.loadingUpdatePermissions = true

    try {
      const res = await SolutionAPI.updateSolutionPermissions(
        solutionId,
        addEntities,
        removeEntities,
        allowAllEntities
      )
      return res.status === 200
    } catch (error: any) {
      store.errorUserAndGroups = error.message
    } finally {
      store.loadingUpdatePermissions = false
    }

    return false
  }
}
