import React, { Dispatch, useState } from "react"
import { observer } from "mobx-react-lite"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Table from "@components/ui/BaseTable/Table"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import TextColumn from "@components/ui/SimpleTable/TextColumn"
import Text from "@components/ui/Typography/Text"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import IconButton from "@components/ui/IconButton/IconButton"
import { V2_Products } from "@store/product/product.store"

import styles from "./ProductsTable.module.sass"

import { AddedDeletedType } from "."

export type ProductsTableProps = {
  productIds: AddedDeletedType
  setProductIds: Dispatch<AddedDeletedType>
  products: V2_Products[]
  companyId: string
}

const ProductsTable: React.FC<ProductsTableProps> = observer(
  ({ products, productIds, setProductIds, companyId }) => {
    const [productsList, setProductsList] = useState(products)
    const linkContentModal = useModal(ModalsTypes.LINK_PRODUCTS_MODAL)
    const deleteModal = useModal(ModalsTypes.CONFIRM_MODAL)

    const handleAddChildClick = () => {
      linkContentModal.showModal({
        companyId,
        invalidProductIds: productsList.map((p) => p.id) || [],
        onSave: (products) => {
          const newProducts = products.filter(
            (p) =>
              !productsList.some(
                (existingProduct) => existingProduct.id === p.id
              )
          )
          setProductIds({
            ...productIds,
            added: [...productIds.added, ...newProducts.map((p) => p.id)],
          })
          setProductsList([...productsList, ...newProducts])
        },
      })
    }
    const handleRemove = (item: V2_Products) => {
      setProductIds({
        ...productIds,
        deleted: [...productIds.deleted, item.id],
      })
      setProductsList(productsList.filter((product) => product.id !== item.id))
    }
    return (
      <div className={styles.root}>
        <Text variant="h2" weight="bold">
          Products
        </Text>
        <div className={styles.scrollableTable}>
          <Table
            header={
              <Row>
                <TextColumn width={100}>Name</TextColumn>
                <TextColumn width={100}>Description</TextColumn>
                <Column> </Column>
              </Row>
            }
          >
            {productsList.map((item) => (
              <>
                <Row key={item.id}>
                  <Column> {item.name}</Column>
                  <Column> {item.description}</Column>
                  <Column className={styles.actionColumn}>
                    <IconButton onClick={() => handleRemove(item)}>
                      <Icon color="red" name="trash-can" />
                    </IconButton>
                  </Column>
                </Row>
              </>
            ))}
          </Table>
        </div>
        <div className={styles.footerButtonWrapper}>
          <Button
            size="tiny"
            variant="text"
            before={<Icon name="plus" />}
            onClick={handleAddChildClick}
          >
            Link Products
          </Button>
        </div>
      </div>
    )
  }
)

export default ProductsTable
