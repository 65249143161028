import React from "react"
import { observer } from "mobx-react-lite"
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  IndexRange,
  InfiniteLoader,
  List,
  ListProps,
} from "react-virtualized"

import Skeleton from "@components/ui/Skeleton/Skeleton"

const BATCH_SIZE = 30

const defaultLoadMore = async () => {}

interface InfiniteCollectionProps<T> {
  total: number
  unit?: string
  minRowHeight?: number
  getItem: (index: number) => T | null
  renderItem: (item: T) => React.ReactNode
  loadMore?: (index: IndexRange) => Promise<void>
}

const InfiniteCollection = observer(
  <T extends string | number | object>({
    total,
    minRowHeight = 58,
    getItem,
    renderItem,
    loadMore = defaultLoadMore,
  }: InfiniteCollectionProps<T>) => {
    const cache = React.useMemo(() => {
      return new CellMeasurerCache({
        fixedWidth: true,
        minHeight: minRowHeight,
      })
    }, [minRowHeight])

    const renderRow: ListProps["rowRenderer"] = ({
      index,
      key,
      parent,
      style,
    }) => {
      const itemData = getItem(index)
      if (!itemData)
        return (
          <div style={style} key={key}>
            <Skeleton
              count={1}
              minWidth={100}
              lineHeight={(style.height as number) - 4}
              rounded
            />
          </div>
        )

      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
          key={`${key}-cached`}
        >
          <div style={style} key={key}>
            {renderItem(itemData)}
          </div>
        </CellMeasurer>
      )
    }

    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => getItem(index) != null}
        loadMoreRows={loadMore}
        rowCount={total}
        minimumBatchSize={BATCH_SIZE}
        threshold={BATCH_SIZE}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                rowCount={total}
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowHeight={cache.rowHeight}
                rowRenderer={renderRow}
                height={height}
                width={width}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }
)

export default InfiniteCollection
