import { makeAutoObservable } from "mobx"
import max from "lodash/max"

import { Point, Range } from "@framework/types/common"
import { copyToClipboard, readClipboard } from "@utils/clipboard"
import { initArray } from "@utils/numberUtils"

import CellManager from "./CellManager"
import {
  stringifyPoint,
  parsePointString,
  contains,
  forEachOfRange,
  includes,
  intersection,
  makeRange,
  totalRangeCells,
  refToRange,
  rangeSize,
  rangeToCode,
  refToPoint,
} from "../utils"
import CellEditorState from "./CellEditorState"
import MatrixStore from "./MatrixStore"
import FunctionManager from "./FunctionManager"
import { CellFormat, CellSnapshot, RefPoint } from "../types"
import RectSelection from "./RectSelection"

class EditManager {
  // injections
  private context: MatrixStore

  // state
  activeCellId: string | null

  activeCellState: CellEditorState | null

  functionManager: FunctionManager

  refSelectingRange: RectSelection | null

  get isEditing() {
    return this.activeCellId != null
  }

  get isCellEditing() {
    const { activeCellId } = this
    return (point: Point) => activeCellId === stringifyPoint(point)
  }

  data: Map<string, CellManager>

  constructor(config: {
    context: MatrixStore
    snapshot?: Record<string, CellSnapshot>
  }) {
    this.context = config.context

    this.activeCellId = null

    this.activeCellState = null

    this.refSelectingRange = null

    this.functionManager = new FunctionManager({ manager: this })

    this.data = config.snapshot
      ? this.dataFromSnapshot(config.snapshot)
      : new Map()

    makeAutoObservable(this)
  }

  dataFromSnapshot = (snapshot: Record<string, CellSnapshot>) => {
    const cellEntries = Object.entries(snapshot).map<[string, CellManager]>(
      ([cellId, cellSnapshot]) => [
        cellId,
        new CellManager({
          manager: this,
          validationManager: this.context.validationManager,
          id: cellId,
          initialState: cellSnapshot.state,
          initialValidationRuleId: cellSnapshot.validationRuleId,
          readonly: cellSnapshot.readonly,
        }),
      ]
    )

    return new Map(cellEntries)
  }

  init = () => {
    this.data.forEach((cell) => cell.init())
  }

  editCell = (
    point: Point,
    options: { initialValue?: string; focusCell?: boolean } = {}
  ) => {
    const cell = this.getCellAtPoint(point)

    if (cell.readonly) return

    this.activeCellState = new CellEditorState({
      functionManager: this.functionManager,
      initialValue: options.initialValue ?? cell.state.input,
      autoFocusCell: options.focusCell,
    })

    this.activeCellId = stringifyPoint(point)
  }

  submitCell = () => {
    const cell = this.getActiveCell()

    if (cell == null) return

    cell.setInput(this.activeCellState?.normalize())
    cell.apply()

    this.activeCellId = null
    this.activeCellState = null
  }

  cancelCell = () => {
    this.activeCellId = null
    this.activeCellState = null
  }

  getRefValuesAndSubscribe = async (
    cellID: string,
    refs: string[]
  ): Promise<Record<string, unknown>> => {
    const refRanges = refs.map<[string, Range<RefPoint>]>((ref) => {
      return [ref, refToRange(ref)]
    })

    const evaluatedEntries = await Promise.all(
      refRanges.map(async ([ref, refRange]) => {
        const values = await this.getRefValue(refRange)
        return [ref, values]
      })
    )

    refRanges.forEach(([_, refRange]) => {
      forEachOfRange(refRange, (refPoint) => {
        this.getCellAtPoint(refPoint).subscribe(cellID)
      })
    })

    return Object.fromEntries(evaluatedEntries)
  }

  subscribeRefs = (cellID: string, refs: string[]) => {
    refs.forEach((ref) => {
      const refRange = refToRange(ref)
      forEachOfRange(refRange, (refPoint) => {
        this.getCellAtPoint(refPoint).subscribe(cellID)
      })
    })
  }

  unsubscribeRefs = (cellID: string, refs: string[]) => {
    refs.forEach((ref) => {
      const refRange = refToRange(ref)
      forEachOfRange(refRange, (refPoint) => {
        this.getCellAtPoint(refPoint).unsubscribe(cellID)
      })
    })
  }

  notifyValueUpdated = (cellID: string) => {
    this.getCellById(cellID).apply()
  }

  getRefValue = async (refRange: Range<RefPoint>): Promise<unknown> => {
    if (totalRangeCells(refRange) === 1) {
      return this.getCellAtPoint(refRange.start).getFinalValue()
    }

    const cols = refRange.end.x - refRange.start.x + 1
    const rows = refRange.end.y - refRange.start.y + 1

    const valueResolvers: Promise<unknown>[] = []

    for (let xi = 0; xi < cols; xi += 1) {
      for (let yi = 0; yi < rows; yi += 1) {
        const cell = this.getCellAtPoint({
          x: refRange.start.x + xi,
          y: refRange.start.y + yi,
        })

        valueResolvers.push(cell.getFinalValue())
      }
    }

    return Promise.all(valueResolvers)
  }

  validateRefs = (cellId: string, refs: string[]) => {
    const origin = parsePointString(cellId)

    refs.forEach((ref) => {
      const refRange = refToRange(ref)

      this.checkRangeBoundaries(refRange)

      this.checkCircularRefs(origin, refRange, [])
    })
  }

  protected checkRangeBoundaries = (range: Range<Point>) => {
    const boundary = this.context.grid.rect

    if (!contains(boundary, range))
      throw new Error(`Reference ${rangeToCode(range)} is out of boundary`)
  }

  protected checkCircularRefs = (
    origin: Point,
    range: Range<Point>,
    callStack: string[]
  ) => {
    if (includes(range, origin)) throw new Error("Circular dependency detected")

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      cell.state.formula?.refs.forEach((ref) => {
        if (callStack.includes(ref)) {
          throw new Error("Circular dependency detected")
        }

        this.checkCircularRefs(origin, refToRange(ref), [...callStack, ref])
      })
    })
  }

  get findCell() {
    return (point: Point) => {
      const id = stringifyPoint(point)

      const cell = this.data.get(id)

      return cell ?? null
    }
  }

  get getCellAtPoint() {
    const { getCellById } = this
    return (point: Point) => {
      const id = stringifyPoint(point)
      return getCellById(id)
    }
  }

  get getActiveCell() {
    const { activeCellId, getCellById } = this
    return () => {
      if (activeCellId == null) return null
      return getCellById(activeCellId)
    }
  }

  get getCellById() {
    const { data, context } = this
    return (id: string) => {
      const cell = data.get(id)
      if (cell != null) return cell

      data.set(
        id,
        new CellManager({
          id,
          manager: context.editManager,
          validationManager: context.validationManager,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return data.get(id)!
    }
  }

  cleanUpRange = (range: Range<Point>) => {
    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      if (cell.readonly) return

      this.getCellAtPoint(point).cleanUp()
    })
  }

  insertValues = (value: string[][]) => {
    const rows = value.length
    for (let y = 0; y < rows; y += 1) {
      const cols = value[y].length
      for (let x = 0; x < cols; x += 1) {
        this.getCellAtPoint({ x, y }).setInput(value[y][x])
      }
    }
  }

  copy = () => {
    const { range } = this.context.selectedRange

    const size = rangeSize(range)

    const result: unknown[][] = initArray(size.y, () => Array(size.x))

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      const yIndex = point.y - range.start.y
      const xIndex = point.x - range.start.x

      result[yIndex][xIndex] = cell.value
    })

    copyToClipboard(stringifyClipboardMatrix(result))
  }

  cut = () => {
    const { range } = this.context.selectedRange

    const size = rangeSize(range)

    const result: unknown[][] = initArray(size.y, () => Array(size.x))

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      const yIndex = point.y - range.start.y
      const xIndex = point.x - range.start.x

      result[yIndex][xIndex] = cell.value

      if (cell.readonly) return

      cell.cleanUp()
    })

    copyToClipboard(stringifyClipboardMatrix(result))
  }

  reset = () => {
    const { range } = this.context.selectedRange

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)
      if (!cell.readonly) cell.reset()
    })
  }

  setRangeReadonlyMode = (value: boolean) => {
    const { range } = this.context.selectedRange

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)
      cell.setReadonlyMode(value)
    })
  }

  applyFormattingToRange = (format: Partial<CellFormat>) => {
    const { range } = this.context.selectedRange

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)
      if (cell.readonly) return

      this.getCellAtPoint(point).appendFormatting(format)
    })
  }

  resetFormattingForRange = () => {
    const { range } = this.context.selectedRange

    forEachOfRange(range, (point) => {
      this.getCellAtPoint(point).resetFormatting()
    })
  }

  paste = async () => {
    const text = await readClipboard()

    if (!text) return

    const values = parseClipboardMatrix(text)

    const size = getMatrixSize(values)

    const { origin } = this.context.selectedRange

    const range = intersection(
      makeRange(origin, {
        x: origin.x + size.x - 1,
        y: origin.y + size.y - 1,
      }),
      this.context.grid.rect
    )

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      if (cell.readonly) return

      const yIndex = point.y - range.start.y
      const xIndex = point.x - range.start.x

      const value = values[yIndex][xIndex] ?? ""

      this.getCellAtPoint(point).setInput(value)
    })

    forEachOfRange(range, (point) => {
      const cell = this.getCellAtPoint(point)

      if (cell.readonly) return

      this.getCellAtPoint(point).apply()
    })

    this.context.selectedRange.selectRange(range)
  }

  setValueToRange = async (
    ref: string,
    value: string = "",
    option?: { autoSelect?: boolean }
  ) => {
    const range = refToRange(ref)

    forEachOfRange(range, (point) => {
      this.getCellAtPoint(point).setInput(value)
    })

    forEachOfRange(range, (point) => {
      this.getCellAtPoint(point).apply()
    })

    if (option?.autoSelect) this.context.selectedRange.selectRange(range)
  }

  findEmptyPoint = (
    startXIndex: number,
    startYIndex: number,
    axis: "x" | "y"
  ) => {
    const { totalColumns } = this.context.grid
    const { totalRows } = this.context.grid
    const xStep = axis === "x" ? 1 : 0
    const yStep = axis === "y" ? 1 : 0

    for (
      let x = startXIndex, y = startYIndex;
      x < totalColumns && y < totalRows;
      x += xStep, y += yStep
    ) {
      const point = { x, y }
      const cell = this.getCellAtPoint(point)

      if (!cell.value && !cell.readonly) return point
    }

    return null
  }

  setToFirstEmptyCell = (
    startWith: string,
    value: string,
    option?: { autoSelect?: boolean; axis?: "x" | "y" }
  ) => {
    const range = refToPoint(startWith)

    if (range.x > this.context.grid.totalColumns - 1) return null
    if (range.y > this.context.grid.totalRows - 1) return null

    const point = this.findEmptyPoint(range.x, range.y, option?.axis ?? "x")

    if (point == null) return null

    const cell = this.getCellAtPoint(point)

    cell.setInput(value)
    cell.apply()

    if (option?.autoSelect)
      this.context.selectedRange.selectRange({ start: point, end: point })

    return point
  }

  serialize = () => {
    return [...this.data.entries()].reduce<Record<string, CellSnapshot>>(
      (acc, [cellId, cell]) => {
        acc[cellId] = cell.serialize()
        return acc
      },
      {}
    )
  }

  startRefSelecting = (point: Point) => {
    this.refSelectingRange = new RectSelection()
    this.refSelectingRange.startRange(point)
  }

  updateRefSelecting = (point: Point) => {
    if (this.refSelectingRange == null) return

    this.refSelectingRange.updateRange(point)
  }

  endRefSelecting = () => {
    if (this.refSelectingRange == null) return

    this.refSelectingRange?.endRange()

    this.activeCellState?.applySelectedRef(this.refSelectingRange.range)

    this.refSelectingRange = null
  }
}

export default EditManager

const getMatrixSize = (values: string[][]): Point => {
  const rows = values.length
  const cols = max(values.map((it) => it.length)) ?? 0
  return { x: cols, y: rows }
}

const stringifyClipboardMatrix = (values: unknown[][]) => {
  return values.map((it) => it.join("\t")).join("\r\n")
}

const parseClipboardMatrix = (text: string) => {
  return text.split("\r\n").map((it) => it.split("\t"))
}
