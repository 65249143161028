import React from "react"

import {
  DocumentIconType,
  getDocumentIconSrc,
  isDocumentIconName,
} from "@framework/types/utils"

import Icon from "./Icon"
import IconChip, { IconChipProps } from "../Chip/IconChip"
import { IconName } from "./IconName"

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  icon: DocumentIconType | IconName
  color?: IconChipProps["color"]
  src?: never
  /**
   * @deprecated use size instead
   */
  height?: number
  /**
   * @deprecated use size instead
   */
  width?: number
  size?: "small" | "medium" | "big"
}

enum Sizes {
  small = 24,
  medium = 32,
  big = 45,
}

export const DocumentIcon: React.FC<Props> = ({
  icon,
  color = "primary",
  width,
  height,
  size = "big",
  onClick,
  className,
  ...rest
}) => {
  if (isDocumentIconName(icon))
    return (
      <img
        alt={icon}
        height={height ?? (size ? Sizes[size] : undefined)}
        width={width ?? (size ? Sizes[size] : undefined)}
        {...rest}
        src={getDocumentIconSrc(icon)}
        className={className}
      />
    )
  return (
    <IconChip
      color={color}
      className={className}
      onClick={onClick}
      iconSize={size}
    >
      <Icon name={icon} />
    </IconChip>
  )
}

export default DocumentIcon
