import React from "react"
import clsx from "clsx"

import { IconName } from "@components/ui/Icon/IconName"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import IconButton from "@components/ui/IconButton/IconButton"

import styles from "./NotificationBanner.module.sass"

export type NotificationType = "warning" | "info" | "error" | "success"

export type AlertConfig = { className: string; iconName?: IconName }

const alertConfig: Record<NotificationType, AlertConfig> = {
  warning: {
    className: styles.colorWarning,
    iconName: "info",
  },
  info: {
    className: styles.colorInfo,
    iconName: "info",
  },
  success: {
    className: styles.colorSuccess,
    iconName: "checkbox-circle-line",
  },
  error: {
    className: styles.colorError,
    iconName: "alert",
  },
}

export interface NotificationBannerProps {
  type?: NotificationType
  title: string
  className?: string
  onClose?: () => void
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  type = "info",
  title,
  children,
  className,
  onClose,
}) => {
  const config = type ? alertConfig[type] : alertConfig.info

  return (
    <div className={clsx(styles.root, config.className, className)}>
      <Icon className={styles.icon} name={config.iconName || "info"} />

      <div className={styles.content}>
        <Text variant="h3" color="text100Color">
          {title}
        </Text>
        <Text variant="body2" color="text100Color" className={styles.message}>
          {children}
        </Text>
      </div>
      <IconButton size="medium" onClick={onClose}>
        <Icon name="cross" />
      </IconButton>
    </div>
  )
}

export default NotificationBanner
