import React from "react"
import { observer } from "mobx-react-lite"
import moment from "moment"
import pickBy from "lodash/pickBy"
import { FormikContextType } from "formik"

import { useStore } from "@store/index"
import FilterButton from "@components/ui/Button/FilterButton"
import FilterSidebar from "@components/prototypes/FilterSidebar"
import { SearchContext } from "@components/prototypes/SearchContext"
import { DD_MM_YYYY_FORMAT } from "@framework/constants/global"
import { ConnectorDocumentsFilters } from "@framework/types/content-manager"
import { countActiveFilters } from "@utils/filters"
import { SearchContextInput } from "@components/prototypes/SearchContext/SearchContextInput"

import ContentManagerFilterForm, {
  FilterForm,
} from "./ContentManagerFilterForm"

const ContentManagerFilter: React.FC = observer(() => {
  const { contentManagerStore } = useStore()

  const { filters, setActiveFilter } = contentManagerStore

  const [searchQuery, setSearchQuery] = React.useState("")
  const [formInstance, setFormInstance] =
    React.useState<FormikContextType<FilterForm>>()

  const appliedFiltersLength = React.useMemo(() => {
    return countActiveFilters({ ...filters, include: undefined })
  }, [filters])

  const handleApplyFilter = (data: FilterForm) => {
    setActiveFilter(transformFilterForAPI(data))
  }

  const searchContext = React.useMemo(
    () => ({ query: searchQuery, setQuery: setSearchQuery }),
    [searchQuery]
  )

  React.useMemo(() => {
    if (formInstance)
      formInstance.resetForm({ values: transformFilterForUI(filters) })
  }, [filters, formInstance])

  return (
    <SearchContext.Provider value={searchContext}>
      <FilterSidebar<FilterForm>
        getFormInstance={setFormInstance}
        initialValue={transformFilterForUI(filters)}
        defaultValue={{ mode: "include" }}
        onChange={handleApplyFilter}
        targetSlot={({ onToggle, onReset }) => (
          <FilterButton
            size="big"
            variant="contained"
            color="secondary"
            counter={appliedFiltersLength}
            onOpen={onToggle}
            onClean={onReset}
          >
            Filter
          </FilterButton>
        )}
        headerSlot={<SearchContextInput />}
      >
        {({ isOpen }) => <ContentManagerFilterForm visible={isOpen} />}
      </FilterSidebar>
    </SearchContext.Provider>
  )
})

export default ContentManagerFilter

const transformFilterForUI = (
  filters: ConnectorDocumentsFilters
): FilterForm => {
  const { include, connectedDate, ...rest } = filters

  return {
    mode: include || include == null ? "include" : "exclude",
    ...pickBy(
      {
        connectedDate:
          connectedDate != null
            ? {
                start: moment(connectedDate.dateRangeStart).format(
                  DD_MM_YYYY_FORMAT
                ),
                end: moment(connectedDate.dateRangeEnd).format(
                  DD_MM_YYYY_FORMAT
                ),
              }
            : undefined,
        ...rest,
      },
      (it) => it != null
    ),
  }
}

const transformFilterForAPI = (
  filters: FilterForm
): ConnectorDocumentsFilters => {
  const { mode, connectedDate, ...rest } = filters

  return pickBy(
    {
      include: mode === "include",
      connectedDate:
        connectedDate != null
          ? {
              dateRangeEnd: moment(connectedDate.end, DD_MM_YYYY_FORMAT)
                .endOf("day")
                .unix(),
              dateRangeStart: moment(connectedDate.start, DD_MM_YYYY_FORMAT)
                .startOf("day")
                .unix(),
            }
          : undefined,
      ...rest,
    },
    (it) => it != null
  )
}
