import { makeAutoObservable } from "mobx"

import { CompanyWithMetaInfo } from "@framework/types/company"

class CompanyStore {
  companies: CompanyWithMetaInfo[] = []

  trialInstanceCompanies: CompanyWithMetaInfo[] = []

  loading: boolean = false

  loadingCompanies: boolean = false

  error: string | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setLoading(loading: boolean) {
    this.loading = loading
  }

  setError(error: string | null) {
    this.error = error
  }
}

export default CompanyStore
