import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"

import EntityPageHeaderLayout from "@components/layout/EntityPageHeaderLayout/EntityPageHeaderLayout"
import TextInput from "@components/ui/TextInput/TextInput"
import Icon from "@components/ui/Icon/Icon"
import useActiveAvatar from "@pages/search/ActiveAvatarContext/useActiveAvatar"
import { useSearchContext } from "@components/prototypes/SearchContext"
import AvatarPickerSidebar from "@components/prototypes/AvatarPickerSidebar"
import BackButton from "@components/prototypes/BackButton"
import mainRoutes from "@root/main.routes"

interface HeaderProps {
  className?: string
}

export const Header: React.FC<HeaderProps> = observer(({ className }) => {
  const navigate = useNavigate()

  const searchContext = useSearchContext()

  const { avatar, selectAvatar } = useActiveAvatar()

  const handleChangeAvatar = (avatarId: string) => {
    selectAvatar(avatarId)
  }

  return (
    <EntityPageHeaderLayout
      className={className}
      left={
        <BackButton onClick={() => navigate(mainRoutes.expertsMenu())}>
          Expert Insights
        </BackButton>
      }
      right={
        <AvatarPickerSidebar value={avatar.id} onChange={handleChangeAvatar} />
      }
    >
      <TextInput
        before={<Icon name="search" />}
        placeholder="Search"
        value={searchContext.query}
        onChange={(e) => searchContext.setQuery(e.target.value)}
        after={
          searchContext.query ? (
            <Icon name="cross" onClick={() => searchContext.setQuery("")} />
          ) : null
        }
      />
    </EntityPageHeaderLayout>
  )
})

export default Header
