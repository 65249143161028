import React from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Chip from "@components/ui/Chip/Chip"
import Text from "@components/ui/Typography/Text"
import { useStore } from "@store"

interface ProductFilterHeaderProps {}

type ChipLabel = "Manufacturer" | "Identifier"

const ProductFilterHeader: React.FC<ProductFilterHeaderProps> = observer(() => {
  const {
    productComparisonStore: {
      productStore: { activeFilter, updateActiveFilter },
    },
  } = useStore()

  const maxChipsToDisplay = 4

  const allChips = React.useMemo(() => {
    return [
      ...activeFilter.identifiers.map((identifier) => ({
        label: "Identifier" as ChipLabel,
        value: identifier.name,
        id: identifier.id,
      })),
      ...activeFilter.manufacturers.map((manufacturer) => ({
        label: "Manufacturer" as ChipLabel,
        value: manufacturer.name,
        id: manufacturer.id,
      })),
    ]
  }, [activeFilter.identifiers, activeFilter.manufacturers])

  const removeChip = (chipId: string, label: ChipLabel) => {
    if (label === "Manufacturer") {
      updateActiveFilter({
        ...activeFilter,
        manufacturers: activeFilter.manufacturers.filter((manufacturer) => {
          return manufacturer.id !== chipId
        }),
      })
    } else {
      updateActiveFilter({
        ...activeFilter,
        identifiers: activeFilter.identifiers.filter((identifier) => {
          return identifier.id !== chipId
        }),
      })
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Text>Applied Filters: </Text>

      {allChips.slice(0, maxChipsToDisplay).map((chipData) => (
        <Chip
          key={chipData.id}
          variant="rounded"
          color="secondary"
          onRemove={() => {
            removeChip(chipData.id, chipData.label)
          }}
          style={{
            border: "none",
            marginLeft: "0.5rem",
          }}
        >
          {chipData.label}: {chipData.value}
        </Chip>
      ))}

      {allChips.length > maxChipsToDisplay && (
        <Text style={{ marginLeft: "0.5rem" }}>
          +{allChips.length - maxChipsToDisplay} more
        </Text>
      )}

      <Button
        variant="slim"
        color="default"
        size="small"
        style={{
          marginLeft: "1rem",
        }}
        onClick={() => {
          updateActiveFilter({
            ...activeFilter,
            manufacturers: [],
            identifiers: [],
          })
        }}
      >
        Clear All
      </Button>
    </div>
  )
})

export default ProductFilterHeader
