import React from "react"
import { Form, FormikContextType, FormikProvider, useFormik } from "formik"
import { observer } from "mobx-react-lite"

import useToggle from "@components/hooks/useToggle"
import Button from "@components/ui/Button/Button"
import SidebarPopup from "@components/modals/components/SidebarPopup"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"

import FilterSidebarHeader from "./components/Header"

type ChildRenderCallbackOptions = {
  isOpen: boolean
  onToggle: () => void
  onReset: () => void
}

interface FilterSidebarProps<T extends Partial<object>> {
  initialValue?: T
  defaultValue?: any
  children?:
    | React.ReactNode
    | ((options: ChildRenderCallbackOptions) => React.ReactNode)
  onChange?: (newFilters: T) => void
  targetSlot?: (options: ChildRenderCallbackOptions) => React.ReactNode
  headerSlot?: React.ReactNode
  getFormInstance?: (formik: FormikContextType<T>) => void
}

const FilterSidebar = observer(
  <T extends Partial<object>>(props: FilterSidebarProps<T>) => {
    const {
      getFormInstance: getFormRef,
      initialValue,
      defaultValue = {},
      targetSlot,
      headerSlot,
      onChange,
      children,
    } = props

    const { isOpened, handleToggle, setOpened } = useToggle()

    const handleSubmit = (values: any) => {
      onChange?.(values)
      setOpened(false)
    }

    const formik = useFormik<T>({
      initialValues: initialValue ?? ({} as T),
      onSubmit: handleSubmit,
    })

    const childProps = {
      isOpen: isOpened,
      onToggle: handleToggle,
      onReset: () => {
        formik.resetForm({ values: defaultValue })
        formik.submitForm()
      },
    }

    React.useLayoutEffect(() => {
      getFormRef?.(formik)
    }, [])

    return (
      <FormikProvider value={formik}>
        {targetSlot?.(childProps)}

        <SidebarPopup open={isOpened} onClose={handleToggle}>
          <Templates.RollScript
            gutter="24"
            headerSocket={
              <List gutter="16" overflow="initial">
                <FilterSidebarHeader
                  onClear={() => formik.resetForm({ values: defaultValue })}
                />
                {headerSlot}
              </List>
            }
            footerSocket={
              <List overflow="initial">
                <Button
                  variant="outlined"
                  color="default"
                  onClick={formik.submitForm}
                >
                  Apply
                </Button>
              </List>
            }
          >
            <Form>
              {typeof children === "function" ? children(childProps) : children}
            </Form>
          </Templates.RollScript>
        </SidebarPopup>
      </FormikProvider>
    )
  }
)

export default FilterSidebar
