import React from "react"

import Row from "@components/ui/BaseTable/Row"
import TextColumn from "@components/ui/SimpleTable/TextColumn"

const IdentifierTableHeader: React.FC = () => {
  return (
    <Row>
      <TextColumn>Name</TextColumn>
      <TextColumn>parent identifier</TextColumn>
      <TextColumn>Manufacturer</TextColumn>
      <TextColumn>Content</TextColumn>
      <TextColumn>products</TextColumn>
      <TextColumn width={38}> </TextColumn>
    </Row>
  )
}

export default IdentifierTableHeader
