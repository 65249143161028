import { makeAutoObservable, autorun } from "mobx"

import { AnswerData } from "@framework/types/search"
import { Option } from "@framework/types/utils"
import RestrictionsStore from "@store/restrictions/restrictions.store"

import { getSourcesSortingHandler } from "./utils"
import FilterEntityStore from "./filter-entity.store"
import { AnswerSource, transformAnswers } from "./dataTransformers"
import SearchPassagesStore from "./search-passages.store"

/**
 * @deprecated passages sidebar is deprecated to the store no longer needed
 */
export class SearchSourcesStore {
  restrictionsStore: RestrictionsStore

  sourcesList: AnswerSource[]

  sourceAnswers: Map<string, Option<string, AnswerData>[]> = new Map()

  filter: FilterEntityStore

  searchPassages: SearchPassagesStore

  constructor(config: {
    restrictionsStore: RestrictionsStore
    searchPassages: SearchPassagesStore
    filter: FilterEntityStore
    sourcesList: AnswerSource[]
  }) {
    this.filter = config.filter
    this.searchPassages = config.searchPassages
    this.sourcesList = config.sourcesList

    this.restrictionsStore = config.restrictionsStore

    autorun(() => {
      const { topAnswers, leftoverAnswers } = this.searchPassages ?? {}

      this.sourceAnswers = transformAnswers(this.sourcesList, [
        ...(topAnswers ?? []),
        ...(leftoverAnswers ?? []),
      ])
    })

    makeAutoObservable(this)
  }

  get sourcesSortingHandler() {
    const access = this.restrictionsStore
    return getSourcesSortingHandler(
      access.isAnswersSortingByModifiedDate
        ? { dateFieldName: "createdDate" }
        : { dateFieldName: "sourceDate" }
    )
  }

  get totalSources() {
    return this.sourcesList.length
  }

  get sortedSources(): AnswerSource[] {
    const { orderBy } = this.filter
    return this.sourcesSortingHandler(this.sourcesList, orderBy)
  }

  get sources(): Map<string, AnswerSource> {
    return new Map(this.sourcesList.map((it) => [it.id, it]))
  }

  applySourcesMetaData = async (
    info: {
      source: string
      file_size: number
    }[]
  ) => {
    try {
      if (!Array.isArray(info)) throw new Error("Unexpected response format")

      info.forEach((it) => {
        const file = this.sources.get(it.source)
        if (file != null) file.fileSize = it.file_size ?? 0
      })
    } catch (error) {
      // ignore errors
    }
  }
}

export default SearchSourcesStore
