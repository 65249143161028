import React, { useEffect, useRef, useState, useCallback } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useFormikContext } from "formik"

import {
  CategoryInfo,
  DataFilterKeys,
  ProductFilterKeys,
  QueryFilterCategory,
  QueryFilterData,
  SubFilterInfo,
} from "@framework/types/query-filter"
import { useController, useStore } from "@store"
import { Company } from "@framework/types/company"
import Breadcrumbs from "@components/ui/Breadcrumbs/Breadcrumbs"
import Loader from "@components/ui/Loader/BarLoader"

import CategoryList from "./CategoryList"
import FilterList from "./FilterList"
import { FormData } from "./FilterSubForm"

import styles from "./ProductsSection.module.sass"

const findSubcategoryData = (
  filters: QueryFilterCategory | undefined,
  subcategoryId: string
): QueryFilterData | undefined => {
  return filters?.items.find((item) => item.id === subcategoryId)
}

export type ProductsSectionProps = {
  selectedCompany?: Company
  setSelectedCompany: (company?: Company) => void
}

const ProductsSection: React.FC<ProductsSectionProps> = observer(
  ({ selectedCompany, setSelectedCompany }) => {
    const { solutionsStore, contentManagerStore } = useStore()
    const { availableFilters, isPaginationLoading, isFiltersLoading } =
      solutionsStore
    const { solutionsController } = useController()
    const { loadMoreFiltersData } = solutionsController
    const { companies } = contentManagerStore

    const formik = useFormikContext<FormData>()

    const activeFilters = formik.values.filters ?? []

    const checkAndApplyFilter = (filter: QueryFilterData) => {
      const found = activeFilters.find(
        (curFilter) => curFilter.id === filter.id
      )

      formik.setFieldValue(
        "filters",
        found
          ? activeFilters.filter((it) => it.id !== found.id)
          : [...activeFilters, { ...filter, companyId: selectedCompany?.id }]
      )
    }

    const lastItemRef = useRef<HTMLDivElement | null>(null)

    const [selectedCategory, setSelectedCategory] = useState<CategoryInfo>()
    const [subCategoryData, setSubCategoryData] = useState<QueryFilterData[]>(
      []
    )
    const [subFilters, setSubFilters] = useState<SubFilterInfo[]>([])

    const observerCallback = useCallback(
      async (entries) => {
        if (
          entries[0].isIntersecting &&
          !selectedCategory?.subcategoryId &&
          !isPaginationLoading &&
          !selectedCategory?.queryFilterCategory?.noMoreData
        ) {
          await loadMoreFiltersData({
            category: selectedCategory?.name || "",
            mainCategory: selectedCategory?.parentCategory || "",
            page: (selectedCategory?.queryFilterCategory?.page || 1) + 1,
            pageSize: selectedCategory?.queryFilterCategory?.pageSize || 10,
          })
        }
      },
      [selectedCategory, isPaginationLoading, loadMoreFiltersData]
    )

    useEffect(() => {
      const observer = new IntersectionObserver(observerCallback, {
        threshold: 0.1,
      })

      if (lastItemRef.current) {
        observer.observe(lastItemRef.current)
      }

      return () => {
        if (lastItemRef.current) {
          observer.unobserve(lastItemRef.current)
        }
      }
    }, [lastItemRef.current, observerCallback])

    const getFilterData = () => {
      const filterData: QueryFilterData[] =
        (selectedCategory?.parentCategory === "product"
          ? availableFilters?.product[
              selectedCategory?.name as ProductFilterKeys
            ]
          : availableFilters?.data[selectedCategory?.name as DataFilterKeys]
        )?.items ?? []

      return filterData.map((tempFilter) => ({
        ...tempFilter,
        type: selectedCategory?.name,
      }))
    }

    useEffect(() => {
      if (selectedCategory?.subcategoryId && !isPaginationLoading) {
        const filters =
          availableFilters?.product[
            selectedCategory?.parentCategory as ProductFilterKeys
          ]
        setSubCategoryData(
          (
            findSubcategoryData(filters, selectedCategory.subcategoryId)
              ?.items || []
          ).map((item) => ({ ...item, type: selectedCategory?.parentCategory }))
        )
      }
    }, [selectedCategory?.subcategoryId, availableFilters, isPaginationLoading])

    const handleSubcategorySelect = async (
      filter: QueryFilterData,
      parentCategory: string,
      mainCategory: string
    ) => {
      setSubCategoryData([])
      setSelectedCategory((prev) => ({
        parentCategory,
        name: filter.name,
        queryFilterCategory: prev?.queryFilterCategory,
        subcategoryId: filter.id,
        mainCategory,
      }))

      await loadMoreFiltersData({
        category: parentCategory,
        mainCategory,
        subCategoryId: filter.id,
      })
    }

    const handleCategoryBack = () => {
      if (subFilters.length > 0) {
        setSubFilters((prev) => prev.slice(0, -1))
      } else {
        setSelectedCategory(undefined)
      }
    }

    const subFilterSelect = async (filter: QueryFilterData, parent: string) => {
      const currentFilter = {
        filterId: filter.id,
        heading: filter.name,
        parent,
      }

      setSubFilters((p) => [...p, currentFilter])
      const data = await loadMoreFiltersData({
        subCategoryId: filter.id,
        category: parent,
        mainCategory: selectedCategory?.mainCategory || "",
        ...(filter.type?.toLowerCase() === "data"
          ? { page: 1, pageSize: 1000 }
          : {}),
      })
      setSubFilters((prev) => {
        const updatedSubfilters = [...prev]
        updatedSubfilters[updatedSubfilters.length - 1].item =
          data?.[selectedCategory?.mainCategory as keyof typeof data]?.[
            parent as keyof (typeof data)[keyof typeof data]
          ]
        return updatedSubfilters
      })
    }

    const getSubFiltersItems = () => {
      let filterItems: QueryFilterData[] = []
      const isData = selectedCategory?.mainCategory === "data"
      if (isData && subFilters.length === 0) {
        const data =
          availableFilters?.data[selectedCategory?.name as DataFilterKeys]
        filterItems = data?.items || []
      } else {
        filterItems =
          subFilters.length > 0
            ? subFilters[subFilters.length - 1]?.item?.items || []
            : subCategoryData
      }

      return (
        filterItems?.map((item) => ({
          ...item,
          type: isData
            ? selectedCategory?.name
            : selectedCategory?.parentCategory,
        })) || []
      )
    }

    const items = !selectedCategory?.subcategoryId
      ? getFilterData()
      : getSubFiltersItems()

    const subFilterName = subFilters?.[subFilters.length - 1]?.heading
    return (
      <div className={styles.productsSection}>
        <div
          className={clsx(styles.container, {
            [styles.scrollable]: !selectedCategory,
          })}
        >
          {companies.length > 1 && selectedCompany && (
            <Breadcrumbs
              items={[selectedCompany.name]}
              className={styles.breadCrumps}
              onClick={() => setSelectedCompany()}
            />
          )}

          {selectedCategory ? (
            <CategoryList
              selectedCategory={selectedCategory}
              items={items}
              subFilterName={subFilterName}
              handleCategoryBack={handleCategoryBack}
              checkAndApplyFilter={checkAndApplyFilter}
              subFilterSelect={subFilterSelect}
              filtersToApply={activeFilters}
              isPaginationLoading={isPaginationLoading}
              lastItemRef={lastItemRef}
              setSelectedCategory={setSelectedCategory}
            />
          ) : isFiltersLoading ? (
            <div className={styles.filtersLoader}>
              <Loader />
            </div>
          ) : (
            <FilterList
              availableFilters={availableFilters}
              setSelectedCategory={setSelectedCategory}
              handleSubcategorySelect={handleSubcategorySelect}
            />
          )}
        </div>
      </div>
    )
  }
)

export default ProductsSection
