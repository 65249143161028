import React from "react"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import MenuItem from "@components/containers/Sidebar/MenuItem"
import { ContextOption } from "@framework/types/common"
import IconButton from "@components/ui/IconButton/IconButton"
import { useController, useStore } from "@store"
import { Identifier } from "@framework/types/identifier"

type ContextMenuProps = {
  identifier: Identifier
  onDelete: () => void
}

const ContextMenu: React.FC<ContextMenuProps> = observer(
  ({ identifier, onDelete }) => {
    const { isOpened, handleToggle, setOpened } = useToggle()
    const { restrictionsStore: access } = useStore()
    const navigate = useNavigate()
    const { identifierController } = useController()
    const alert = useAlert()

    const contextOptions: ContextOption<string>[] = []

    if (access.canUpdateIdentifier) {
      contextOptions.push({
        label: "Edit",
        icon: "edit",
        type: "Edit",
        onClick: () => {
          navigate(`/product-identifiers/edit/${identifier.id}`)
        },
      })
      contextOptions.push({
        label: "Delete",
        icon: "trash-can",
        color: "red",
        type: "Delete",
        onClick: async () => {
          const success = await identifierController.deleteIdentifier(
            identifier.id
          )
          if (success) {
            alert.success(`Successfully deleted ${identifier.name}`)
            onDelete()
          } else {
            alert.error(`Failed to delete ${identifier.name}`)
          }
        },
      })
    }

    return (
      <Tooltip
        show={isOpened}
        onClose={() => setOpened(false)}
        mode="onFocus"
        content={
          <TooltipContainer color="primary" placement="left">
            {contextOptions.map(({ label, type, ...rest }) => (
              <MenuItem key={type} onClick={() => setOpened(false)} {...rest}>
                {label}
              </MenuItem>
            ))}
          </TooltipContainer>
        }
      >
        <IconButton size="medium" active={isOpened} onClick={handleToggle}>
          <Icon name="dots" />
        </IconButton>
      </Tooltip>
    )
  }
)

export default ContextMenu
