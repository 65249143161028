import React from "react"
import clsx from "clsx"
import { AutoSizer } from "react-virtualized"

import useToggle from "@components/hooks/useToggle"
import Icon from "@components/ui/Icon/Icon"
import Text from "@components/ui/Typography/Text"
import HTMLText from "@components/ui/Typography/HTMLText"
import TextResult from "@pages/launch-solutions/SolutionTabsContainer/components/Tabs/FactFinder/components/SourceView/components/TextResult/TextResult"
import Button from "@components/ui/Button/Button"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { SearchCitationData } from "@store/search/search-citations.store"

import PreviewList from "./components/PreviewList"
import AnswerViewer from "./components/AnswerViewer"
import Title from "./components/Title"
import CitationBadge from "./components/CitationBadge"

import styles from "./AnswerOriginalView.module.sass"

interface PDFViewerProps {
  citation: SearchCitationData
  images: SearchCitationData[]
  onSelect: (imageDetails: SearchCitationData) => void
}

const AnswerOriginalView: React.FC<PDFViewerProps> = ({
  citation,
  images = [],
  onSelect,
}) => {
  const isTablet = useMediaQuery(AppMediaQueries.minTablet)

  const { isOpened, handleToggle, setOpened } = useToggle()
  const [showText, setShowText] = React.useState(false)

  const handleImageChange = (newImage: SearchCitationData) => {
    onSelect(newImage)
  }

  const toggleTextView = () => {
    setShowText((p) => !p)
  }

  React.useEffect(() => {
    if (isOpened && !citation?.url) setOpened(false)
  }, [citation])

  return (
    <div className={clsx(styles.root, { [styles.fullscreen]: isOpened })}>
      {!isOpened && (
        <div className={styles.header}>
          <Text
            variant={isTablet ? "h5" : "h2"}
            align="center"
            className={styles.citationHeader}
          >
            Citation
            <CitationBadge citationNumber={citation.citationNumber} />
          </Text>

          {citation.answerSource.source_name && <Title answer={citation} />}
        </div>
      )}

      <div className={styles.view}>
        <AnswerViewer
          citation={citation}
          fullscreen={isOpened}
          onFullScreenToggle={handleToggle}
        />
      </div>

      <div className={styles.sidebar}>
        <AutoSizer>
          {(size) => (
            <div
              style={{
                ...size,
                overflow: "hidden",
              }}
            >
              <PreviewList
                image={citation}
                images={images}
                onSelect={handleImageChange}
                orientation={isTablet ? "horizontal" : "vertical"}
              />
            </div>
          )}
        </AutoSizer>
      </div>

      {!isOpened && !!citation?.url && (
        <div className={styles.footer}>
          {!citation?.isExpertAnswer && (
            <Button
              color="secondary"
              size="small"
              className={styles.textViewToggleButton}
              onClick={toggleTextView}
              after={
                <Icon name="line-arrow-down" rotateAngle={showText ? 180 : 0} />
              }
            >
              Extracted Text
            </Button>
          )}

          {showText &&
            (citation?.isExpertAnswer ? (
              <HTMLText className={styles.textContent}>
                {citation?.description}
              </HTMLText>
            ) : (
              <TextResult
                className={styles.textContent}
                content={citation?.description}
              />
            ))}
        </div>
      )}
    </div>
  )
}

export default AnswerOriginalView
