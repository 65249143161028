import React from "react"
import { useAlert } from "react-alert"
import sortBy from "lodash/sortBy"
import { observer } from "mobx-react-lite"

import { useStore } from "@store"
import { useUnstructuredSearchEntity } from "@pages/search/SearchContext/SearchResultContext"
import { SummaryQueryType } from "@store/search/types"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import { AnswerStatus } from "@framework/types/question"
import { SearchBlock } from "@store/search/search-entity.store"

import VerifyButton from "./VerifyButton"

type VerifyButtonProps = {
  disabled?: boolean
}

const SUCCESS_MESSAGE = "Answer verified successfully!"
const ERROR_MESSAGE = "There was an error verifying the answer."

const VerifySearchAnswerButton: React.FC<VerifyButtonProps> = observer(
  ({ disabled = false }) => {
    const [loading, setLoading] = React.useState(false)

    const {
      knowledgeStore: { assignExpertQuestionToSelf, updateExpertQuestion },
      userStore: { user },
    } = useStore()

    const { searchEntity } = useUnstructuredSearchEntity()

    const { isVerifiedSummary, setVerifiedSummary } = searchEntity

    const rawAnswer = searchEntity.allRawData

    const alert = useAlert()

    const handleVerify = async () => {
      if (disabled || loading || !user?.id) return

      if (!rawAnswer) return

      setLoading(true)

      try {
        const summary = searchBlocksToSummary(searchEntity.blocks)

        const data = await assignExpertQuestionToSelf(
          searchEntity.filter.searchAvatar,
          searchEntity.filter.searchAvatarId,
          searchEntity.filter.searchQuery,
          [user.id],
          rawAnswer,
          "",
          summary,
          true
        )

        if (!data?.status) {
          alert.error("There was an error verifying the answer.")
          return
        }

        const questions = data.data as { id: string }[]

        const success = await updateExpertQuestion(
          questions[0].id,
          AnswerStatus.ANSWERED,
          summary
        )

        if (!success) {
          alert.error(ERROR_MESSAGE)
          return
        }

        setVerifiedSummary(true)

        alert.success(SUCCESS_MESSAGE)
      } catch (error) {
        alert.error(ERROR_MESSAGE)
      } finally {
        setLoading(false)
      }
    }

    return (
      <VerifyButton
        onClick={handleVerify}
        loading={loading}
        isVerified={isVerifiedSummary}
      />
    )
  }
)

export default VerifySearchAnswerButton

const searchBlocksToSummary = (blocks: SearchBlock[]) => {
  return sortBy(blocks, (block) => {
    if (block instanceof SearchSummaryBlockStore) {
      if (block.queryType === "PRODUCT_FINDER") return 1
      if (block.queryType === "TABLE") return 1
      if (block.queryType === "BASIC") return 2
      if (block.queryType === "ADVANCED") return 3
    }
    return 0
  })
    .reduce<string[]>((summaries, block) => {
      if (block instanceof SearchSummaryBlockStore) {
        const title = getSummaryTitle(block.queryType)
        summaries.push(`<h3>${title}</h3><p>${block.searchSummary.summary}</p>`)
      }
      return summaries
    }, [])
    .join("<br/>")
}

const getSummaryTitle = (queryType: SummaryQueryType) => {
  switch (queryType) {
    case "ADVANCED":
      return "Revised Summary"
    case "TABLE":
      return "Table Summary"
    case "PRODUCT_FINDER":
      return "Product Recommendation"
    default:
      return "Summary"
  }
}
