import React from "react"
import { observer, useLocalObservable } from "mobx-react-lite"
import { useNavigate, useParams } from "react-router-dom"
import { useAlert } from "react-alert"

import Spreadsheet from "@components/ui/Spreadsheet/Spreadsheet"
import Templates from "@components/ui/Templates"
import List from "@components/ui/List/List"
import { useController, useStore } from "@store"
import Loader from "@components/ui/Loader/BarLoader"
import NotFound from "@components/ui/NotFound/NotFound"
import { MatrixSnapshot } from "@components/ui/Spreadsheet/types"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import MainLayout from "@components/layout/MainLayout/MainLayout"
import BackButton from "@components/prototypes/BackButton"
import EditUnifiedMatrixStore from "@store/unified-matrix/edit-unified-matrix.store"
import Text from "@components/ui/Typography/Text"
import { SolutionEntity } from "@framework/types/solution"
import mainRoutes from "@root/main.routes"
import useQueryParams from "@components/hooks/useQueryParams"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"

import { initCustomFunctions } from "./custom-functions"
import { customContextMenuBuilder } from "./custom-context-menu"

import styles from "./PresetEditor.module.sass"

interface Props {}

const PresetEditor: React.FC<Props> = observer(() => {
  const alert = useAlert()
  const navigate = useNavigate()

  const isTablet = useMediaQuery(AppMediaQueries.minTablet)
  const isMobile = useMediaQuery(AppMediaQueries.maxMobile)

  const buttonSize = isMobile ? "tiny" : isTablet ? "small" : "medium"

  const { session, create } = useQueryParams()

  const isCreateFlow = create === "true"
  const accessPermissionModal = useModal(ModalsTypes.ACCESS_PERMISSIONS_MODAL)

  const { solutionId } = useParams<{
    solutionId: string
  }>()

  if (solutionId == null)
    throw new Error(
      "The solutionId params is required for the PresetEditor page"
    )

  const [snapshot, setSnapshot] = React.useState<MatrixSnapshot>()

  const {
    solutionsStore,
    unifiedMatrixStore,
    restrictionsStore: access,
  } = useStore()
  const { unifiedMatrixController, solutionsController } = useController()

  const solution = solutionsStore.getById(solutionId)

  const localUnifiedMatrixStore = useLocalObservable(
    () => new EditUnifiedMatrixStore({ controller: unifiedMatrixController })
  )

  const matrixStore = localUnifiedMatrixStore.getMatrixInstance()

  const initNewInstance = async (solution?: SolutionEntity) => {
    const snapshot = solution?.workbookData

    const matrixStore = await localUnifiedMatrixStore.initNewInstance(snapshot)

    if (matrixStore) initCustomFunctions(matrixStore, unifiedMatrixController)
  }

  const handleSubmit = async () => {
    if (solution == null || snapshot == null) return

    const result = await solutionsController.updateSolution(solution.id, {
      workbookData: snapshot,
    })

    if (result.status === "FAILED") {
      alert.error(result.message)
      return
    }

    alert.success("Solution Preset was updated successfully")

    unifiedMatrixStore.dropInstance(solutionId)

    if (session === "reload") {
      solutionsStore.setSolution(result.data)
      navigate(mainRoutes.solutionView(solution.workflowType))
      return
    }
    if (isCreateFlow && access.canUpdateSolutionPermissions) {
      accessPermissionModal.showModal({
        solutionId: solution.id,
        allowAllEntities: solution.allowAllEntities,
        selectedUsersFunc: async () => {
          const permissions = await solutionsController.getSolutionPermissions(
            solution.id
          )
          return permissions || []
        },
      })
    }

    navigate(mainRoutes.home())
  }

  React.useEffect(() => {
    if (matrixStore != null && localUnifiedMatrixStore.error == null) {
      matrixStore.addCellContextMenuBuilder(customContextMenuBuilder)
      return () => {
        matrixStore.removeCellContextMenuBuilder(customContextMenuBuilder)
      }
    }

    return undefined
  }, [matrixStore])

  React.useEffect(() => {
    if (matrixStore != null) return

    initNewInstance(solution)
  }, [solution])

  return (
    <MainLayout noPadding>
      <div className={styles.root}>
        <Templates.Header
          className={styles.mainHeader}
          left={
            <BackButton
              before={<Icon name="line-arrow-down" rotateAngle={90} />}
              variant="text"
              size={buttonSize}
            >
              Edit Workbook Preset
            </BackButton>
          }
          right={
            <List direction="row" justify="flex-end">
              <BackButton
                variant="outlined"
                size={buttonSize}
                before={undefined}
              >
                Cancel
              </BackButton>
              <Button
                color="primary"
                size={buttonSize}
                onClick={handleSubmit}
                after={solutionsStore.isLoading ? <Loader /> : undefined}
                disabled={snapshot == null || solutionsStore.isLoading}
              >
                Save
              </Button>
            </List>
          }
        />

        {solution ? (
          <div className={styles.body}>
            {!isTablet && (
              <Templates.Header
                className={styles.header}
                left={
                  <List overflow="initial" gutter="0">
                    <Text color="text50Color" variant="caption2">
                      Workbook
                    </Text>
                    <Text color="text100Color" variant="h4">
                      {solution.name}
                    </Text>
                  </List>
                }
              />
            )}

            <div className={styles.tableContainer}>
              {matrixStore == null ? (
                localUnifiedMatrixStore.error == null ? (
                  <Loader size="large" fluid />
                ) : (
                  <NotFound>{localUnifiedMatrixStore.error}</NotFound>
                )
              ) : (
                <Spreadsheet instance={matrixStore} onUpdate={setSnapshot} />
              )}
            </div>
          </div>
        ) : (
          <NotFound>Solution not exist or was removed</NotFound>
        )}
      </div>
    </MainLayout>
  )
})

export default PresetEditor
