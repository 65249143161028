import { makeAutoObservable } from "mobx"

import RootStore from "@store/RootStore"
import productsService from "@services/products.service"
import { PatchProductRequest } from "@framework/types/product"

import ProductStore from "./product.store"

export default class ProductController {
  protected productStore: ProductStore

  constructor(injections: RootStore) {
    this.productStore = injections.productComparisonStore.productStore

    makeAutoObservable(this)
  }

  getProductDetails = async (id: string) => {
    const store = this.productStore
    store.isProductDetailsLoading = true
    store.error = ""
    try {
      store.productDetails = null
      const response = await productsService.getProductDetails(id)
      store.productDetails = response.data
    } catch (error) {
      store.error = "Failed to load products"
    } finally {
      store.isProductDetailsLoading = false
    }
  }

  loadMoreProductContentIds = async (
    pageNum: number,
    pageSize: number,
    productId: string
  ) => {
    const store = this.productStore
    store.isContentsLoading = true
    store.error = ""
    try {
      store.productContents = []
      store.contentPaginationMeta = null
      const response = await productsService.getProductContents(
        pageNum,
        pageSize,
        productId
      )
      store.productContents = response.data.data
      store.contentPaginationMeta = response.data.pagination
    } catch (error) {
      store.error = "Failed to load content"
    } finally {
      store.isContentsLoading = false
    }
  }

  updateProductDetails = async (id: string, data: PatchProductRequest) => {
    const store = this.productStore
    store.isUpdateProductLoading = true
    store.error = ""
    try {
      await productsService.patchProduct(id, data)
      return true
    } catch (error) {
      store.error = "Failed to update product"
      return false
    } finally {
      store.isUpdateProductLoading = false
    }
  }

  deleteProduct = async (id: string) => {
    const store = this.productStore
    store.deletingProducts[id] = true
    store.error = ""
    try {
      await productsService.deleteProduct(id)
      return true
    } catch (error) {
      store.error = "Failed to delete product"
      return false
    } finally {
      store.deletingProducts[id] = false
    }
  }
}
