import { AxiosResponse } from "axios"
import { makeAutoObservable } from "mobx"

import {
  Synonym,
  SynonymDTO,
  SynonymsGroupsByLetter,
} from "@framework/types/glossary"
import glossaryService from "@services/glossary.service"
import { UploadFilesErrorResponse } from "@services/upload.service"
import { isDeepEqual } from "@utils/collections"

const transformAndGroupSynonyms = (
  synonymsDTO: SynonymDTO[]
): SynonymsGroupsByLetter => {
  const transformedSynonyms: Synonym[] = synonymsDTO.map((dto) => {
    const [mainWord, ...synonymsByMainWord] = dto.synonyms
    return {
      id: dto.id,
      mainWord,
      synonymsByMainWord,
    }
  })

  return transformedSynonyms.reduce<SynonymsGroupsByLetter>((acc, synonym) => {
    const firstLetter = synonym.mainWord.charAt(0).toUpperCase()
    if (!acc[firstLetter]) {
      acc[firstLetter] = []
    }
    acc[firstLetter].push(synonym)
    return acc
  }, {})
}

export class SynonymsStore {
  constructor() {
    makeAutoObservable(this)
  }

  isSynonymsLoading: boolean = false

  synonymsCollection: SynonymsGroupsByLetter | null = null

  errorMessage: string | null = null

  errorMessageUploadFile: string | null = null

  loadSynonyms = async (query: string = "") => {
    try {
      this.setSynonymsLoading(true)

      const {
        data: { data },
        status,
      } = await glossaryService.getAllSynonyms(query)

      this.synonymsCollection =
        status === 200
          ? transformAndGroupSynonyms(data)
          : this.synonymsCollection
    } catch (error: any) {
      this.errorMessage = "Loading failed"
    } finally {
      this.setSynonymsLoading(false)
    }
  }

  removeSynonym = async (managedSynonym: Synonym) => {
    const synonymsCollectionBackup = this.synonymsCollection

    try {
      this.errorMessage = null

      // optimistic changes
      this.synonymsCollection = Object.keys(
        this.synonymsCollection ?? {}
      ).reduce<SynonymsGroupsByLetter>(
        (acc, letter) => ({
          ...acc,
          [letter]: (this.synonymsCollection?.[letter] ?? []).filter(
            (synonym) => synonym.id !== managedSynonym.id
          ),
        }),
        {}
      )

      // request
      await glossaryService.deleteSynonym(managedSynonym.id)
    } catch (error: any) {
      this.errorMessage = "Removing of synonym failed"
      // rollback
      this.synonymsCollection = synonymsCollectionBackup
    }
    return this.errorMessage
  }

  updateSynonym = async (managedSynonym: Synonym) => {
    this.setSynonymsLoading(true)
    this.errorMessage = null

    const synonymsCollectionBackup = this.synonymsCollection

    let exist = false

    this.synonymsCollection = Object.keys(
      this.synonymsCollection ?? {}
    ).reduce<SynonymsGroupsByLetter>(
      (acc, letter) => ({
        ...acc,
        [letter]: (this.synonymsCollection?.[letter] ?? []).map((synonym) => {
          exist = !exist ? synonym.id === managedSynonym.id : exist
          return synonym.id === managedSynonym.id
            ? {
                ...managedSynonym,
                synonymsByMainWord: managedSynonym.synonymsByMainWord.filter(
                  (synonymByMainWord) => !!synonymByMainWord
                ),
              }
            : synonym
        }),
      }),
      {}
    )

    if (!exist) {
      let newSynonym: SynonymsGroupsByLetter | null = null
      try {
        // request
        newSynonym = transformAndGroupSynonyms([
          (
            await glossaryService.createSynonym([
              managedSynonym.mainWord,
              ...managedSynonym.synonymsByMainWord,
            ])
          ).data.data,
        ])

        const firstLetter = Object.keys(newSynonym)[0]

        this.synonymsCollection = {
          ...this.synonymsCollection,
          [firstLetter]: [
            ...(this.synonymsCollection?.[firstLetter] ?? []),
            ...Object.values(newSynonym?.[firstLetter] ?? []),
          ],
        }
      } catch (error: any) {
        if (error) {
          this.errorMessage = "Creating synonym was failed"
          // rollback
          this.synonymsCollection = synonymsCollectionBackup
        }
      } finally {
        this.setSynonymsLoading(false)
      }
    } else {
      try {
        const updatedSynonym: Synonym = Object.values(
          transformAndGroupSynonyms([
            (
              await glossaryService.updateSynonym(managedSynonym.id, [
                managedSynonym.mainWord,
                ...managedSynonym.synonymsByMainWord,
              ])
            ).data.data,
          ])
        )[0][0]

        if (!isDeepEqual(managedSynonym, updatedSynonym)) {
          this.synonymsCollection = Object.keys(
            this.synonymsCollection ?? {}
          ).reduce<SynonymsGroupsByLetter>(
            (acc, letter) => ({
              ...acc,
              [letter]: (this.synonymsCollection?.[letter] ?? []).map(
                (synonym) => {
                  return synonym.id === updatedSynonym?.id
                    ? {
                        ...updatedSynonym,
                        synonymsByMainWord:
                          updatedSynonym.synonymsByMainWord.filter(
                            (synonymByMainWord) => !!synonymByMainWord
                          ),
                      }
                    : synonym
                }
              ),
            }),
            {}
          )
        }
      } catch (error: any) {
        if (error) {
          this.errorMessage = "Updating synonym was failed"
          // rollback
          this.synonymsCollection = synonymsCollectionBackup
        }
      } finally {
        this.setSynonymsLoading(false)
      }
    }
    return this.errorMessage
  }

  uploadSynonymsFromFile = async (files: File[]) => {
    try {
      this.setSynonymsLoading(true)
      this.errorMessageUploadFile = null

      const form = new FormData()
      form.append("file", files[0])

      // request
      const {
        data: { data },
        status,
      } = await glossaryService.uploadSynonymFromFile(form)

      if (status === 201) {
        const uploadedSynonyms = transformAndGroupSynonyms(data)
        this.synonymsCollection = Object.keys(uploadedSynonyms).reduce(
          (acc, firstLetter) => {
            acc[firstLetter] = [
              ...(acc[firstLetter] ?? []),
              ...Object.values(uploadedSynonyms?.[firstLetter] ?? []),
            ]
            return acc
          },
          { ...this.synonymsCollection }
        )
      }
    } catch (error: any) {
      const { response } = error

      if (!response) {
        this.errorMessageUploadFile = "Unknown error while uploading files"
        return false
      }

      const typedResponse: AxiosResponse<UploadFilesErrorResponse> = response

      if (typedResponse.status === 413) {
        this.errorMessageUploadFile = "File is too large"
        return false
      }

      if (typedResponse.data.status === "BODY_INCOMPLETE") {
        this.errorMessageUploadFile = "No files added"
        return false
      }
      if (typedResponse.data.status === "INCORRECT_URL") {
        this.errorMessageUploadFile = "Current source type not maintenance"
        return false
      }
      return false
    } finally {
      this.setSynonymsLoading(false)
    }
    return true
  }

  setSynonymsLoading = (isLoading: boolean) => {
    this.isSynonymsLoading = isLoading
  }

  clearErrorMessage = () => {
    this.errorMessage = null
  }
}

export default SynonymsStore
