import React from "react"
import clsx from "clsx"

import Text from "@components/ui/Typography/Text"
import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import CheckboxWithLabel from "@components/ui/Checkbox/CheckboxWithLabel"
import Loader from "@components/ui/Loader/BarLoader"
import {
  camelCaseToWords,
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  removeHyphens,
} from "@utils/textUtils"
import { CategoryInfo, QueryFilterData } from "@framework/types/query-filter"
import IconButton from "@components/ui/IconButton/IconButton"

import styles from "./ProductsSection.module.sass"

interface CategoryListProps {
  selectedCategory: CategoryInfo
  items: QueryFilterData[]
  handleCategoryBack: () => void
  checkAndApplyFilter: (filter: QueryFilterData) => void
  subFilterSelect: (filter: QueryFilterData, parent: string) => void
  filtersToApply: QueryFilterData[]
  isPaginationLoading: boolean
  lastItemRef: React.RefObject<HTMLDivElement>
  subFilterName?: string
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<CategoryInfo | undefined>
  >
}

const CategoryList: React.FC<CategoryListProps> = ({
  selectedCategory,
  items,
  handleCategoryBack,
  checkAndApplyFilter,
  subFilterSelect,
  filtersToApply,
  isPaginationLoading,
  lastItemRef,
  subFilterName,
  setSelectedCategory,
}) => {
  const isData = selectedCategory?.name?.startsWith("data")
  let label = subFilterName || selectedCategory?.name
  if (isData && label === "manual") {
    label = "Manual Upload"
  }
  label = label?.replace(/^data/, "content ") || ""
  const buttonLabel = capitalizeEachFirstLetter(camelCaseToWords(label))

  const getFilterName = (name: string) => {
    const filterName = name === "manual" ? "Manual Upload" : name
    return capitalizeFirstLetter(removeHyphens(filterName))
  }

  return (
    <>
      <Text variant="body2" className={styles.filtersHeader}>
        <Button
          onClick={handleCategoryBack}
          variant="text"
          size="tiny"
          before={<Icon name="line-arrow-down" rotateAngle={90} />}
        >
          {buttonLabel}
        </Button>
      </Text>
      <div className={styles.scrollable}>
        <div className={clsx(styles.categories, styles.loaderList)}>
          {items.map((filter, index) => (
            <div
              className={styles.checkboxWrapper}
              ref={index === items.length - 1 ? lastItemRef : null}
              key={filter.id || filter.name}
            >
              <CheckboxWithLabel
                key={filter.id}
                className={clsx(styles.checkboxFilterName)}
                onCheck={() => checkAndApplyFilter(filter)}
                label={
                  <Text variant="body2" className={styles.name}>
                    {getFilterName(filter.name)}
                    &nbsp;
                    {filter.hasChildren && (
                      <Text variant="body2" className={styles.count}>
                        ({filter?.count})
                      </Text>
                    )}
                  </Text>
                }
                checked={
                  !!filtersToApply.find(
                    (curFilter) => curFilter.id === filter.id
                  )
                }
              />
              {!!filter?.hasChildren && (
                <IconButton
                  onClick={() => {
                    if (isData) {
                      setSelectedCategory({
                        ...selectedCategory,
                        subcategoryId: filter.id,
                      })
                      subFilterSelect(filter, selectedCategory?.name || "")
                    } else {
                      subFilterSelect(
                        filter,
                        selectedCategory?.parentCategory || ""
                      )
                    }
                  }}
                >
                  <Icon name="line-arrow-down" rotateAngle={-90} />
                </IconButton>
              )}
            </div>
          ))}
          {isPaginationLoading && (
            <div className={styles.loaderWrapper}>
              <Loader size="small" />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default CategoryList
