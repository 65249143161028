import { makeAutoObservable } from "mobx"

import IdentifierAPI from "@services/identifier.service"
import RootStore from "@store/RootStore"
import {
  AddIdentifierRequest,
  IdentifierDetails,
  IdentifierFilter,
  IdentifierListQueryRequest,
  UpdateIdentifierRequest,
  UpdateIdentifierResponse,
} from "@framework/types/identifier"

import IdentifierStore from "./identifier.store"

export default class IdentifierController {
  identifierStore: IdentifierStore

  constructor(injections: RootStore) {
    this.identifierStore = injections.identifierStore

    makeAutoObservable(this)
  }

  deleteIdentifier = async (id: string): Promise<boolean> => {
    const store = this.identifierStore
    store.error = null
    store.loadingDelete = true
    try {
      const response = await IdentifierAPI.deleteIdentifier(id)
      return response.status === 200
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingDelete = false
    }
    return false
  }

  createIdentifier = async (
    data: AddIdentifierRequest
  ): Promise<IdentifierDetails | null> => {
    const store = this.identifierStore
    store.loadingCreate = true
    store.error = null
    try {
      const response = await IdentifierAPI.createIdentifier(data)
      return response.status === 200 ? response.data.data : null
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingCreate = false
    }
    return null
  }

  getIdentifiers = async (body: IdentifierListQueryRequest) => {
    const store = this.identifierStore
    store.loadingList = true
    store.error = null
    try {
      const response = await IdentifierAPI.getIdentifierList(body)
      store.identifiers = response.data.data || []
      store.identifiersMetaData = response.data.meta
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingList = false
    }
  }

  getIdentifier = async (id: string) => {
    const store = this.identifierStore
    store.loading = true
    store.error = null
    try {
      store.identifierDetails = null
      const response = await IdentifierAPI.getIdentifierById(id)
      store.identifierDetails = response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loading = false
    }
  }

  updateIdentifier = async (
    id: string,
    body: UpdateIdentifierRequest
  ): Promise<UpdateIdentifierResponse | null> => {
    const store = this.identifierStore
    store.loadingUpdate = true
    store.error = null
    try {
      const response = await IdentifierAPI.updateIdentifier(id, body)
      return response.data.data
    } catch (error: any) {
      store.error = error.message
    } finally {
      store.loadingUpdate = false
    }
    return null
  }

  loadMoreIdentifierContentIds = async (
    pageNum: number,
    pageSize: number,
    identifierId: string
  ) => {
    const store = this.identifierStore
    store.isContentsLoading = true
    store.error = ""
    try {
      store.identifierContents = []
      store.contentPaginationMeta = null
      const response = await IdentifierAPI.getIdentifierContents(
        pageNum,
        pageSize,
        identifierId
      )
      store.identifierContents = response.data.data
      store.contentPaginationMeta = response.data.meta
    } catch (error) {
      store.error = "Failed to load content"
    } finally {
      store.isContentsLoading = false
    }
  }

  updateSearchQuery = (query: string) => {
    const store = this.identifierStore
    store.searchQuery = query
  }

  updateFilterSearchQuery = (query: string) => {
    const store = this.identifierStore
    store.filterSearchQuery = query
  }

  updateActivePage = (page: number) => {
    const store = this.identifierStore
    store.activePage = page
  }

  updateActiveFilter = (filter: IdentifierFilter) => {
    const store = this.identifierStore
    store.activeFilter = filter
  }
}
