import React from "react"
import { observer } from "mobx-react-lite"

import Icon from "@components/ui/Icon/Icon"
import Button from "@components/ui/Button/Button"
import { useController } from "@store/index"
import Text from "@components/ui/Typography/Text"
import Chip from "@components/ui/Chip/Chip"
import useMediaQuery from "@components/hooks/useMediaQuery"
import { AppMediaQueries } from "@framework/constants/app"
import { SearchCitationData } from "@store/search/search-citations.store"

import styles from "./Title.module.sass"

const Title: React.FC<{ answer: SearchCitationData }> = observer(
  ({ answer }) => {
    const isTablet = useMediaQuery(AppMediaQueries.minTablet)

    const { dataConnectorController } = useController()

    const isExpertKnowledge = answer.isExpertAnswer

    const handleSourceClick = (sourceURL: string) => async () => {
      const res = await dataConnectorController.getSignedDataURL(sourceURL)
      if (res.status === "SUCCESS") window.open(res.data, "_blank")
    }

    return (
      <div className={styles.titleContainer}>
        {!isExpertKnowledge && (
          <Chip color="green-solid" variant="rounded">
            New
          </Chip>
        )}
        <div className={styles.title}>
          {isExpertKnowledge ? (
            <>
              <Icon color="green" name="user-insight" inline />

              <Text variant="inherit" className={styles.titleText}>
                Expert Insights
              </Text>
            </>
          ) : answer.contentId ? (
            <>
              <Text variant="inherit" weight="bold" className={styles.prefix}>
                Content ID:{" "}
              </Text>
              <Text
                variant="inherit"
                className={styles.titleText}
                title={answer.contentId}
              >
                {answer.contentId}
              </Text>
            </>
          ) : (
            <>
              <Icon color="gold" name="global" inline />

              <Text
                variant="inherit"
                className={styles.titleText}
                title={answer.sourceName || "N/A"}
              >
                {answer.sourceName || "N/A"}
              </Text>
            </>
          )}

          {isExpertKnowledge ? (
            <div className={styles.expertBadge}>
              <Icon name="shield-check-fill" color="green" inline />
              <Text variant="h5">Expert Verified</Text>
            </div>
          ) : (
            !!answer.sourceURL && (
              <Button
                onClick={handleSourceClick(answer.sourceURL)}
                className={styles.source}
                color="secondary"
                size={isTablet ? "tiny" : "small"}
                before={<Icon name="external-link" />}
              >
                Open file
              </Button>
            )
          )}
        </div>
      </div>
    )
  }
)

export default Title
