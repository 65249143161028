import { Company } from "@framework/types/company"
import { Product } from "@framework/types/product"

export type LineItemOption = {
  name: string
  value: string
  type: "product" | "account" | "url"
  product?: Product
  company?: Company
}

export const getProductOptions = (product: Product): LineItemOption[] => [
  {
    name: "Product Overview",
    value: "Product Overview",
    type: "product",
    product,
  },
  {
    name: "Product Positioning",
    value: "Product Positioning",
    type: "product",
    product,
  },
  {
    name: "Product Comparison",
    value: "Product Comparison",
    type: "product",
    product,
  },
]

export const getCompanyOptions = (company: Company): LineItemOption[] => [
  {
    name: "Account Website",
    value: company?.url || "",
    type: "url",
  },
  {
    name: "Account Research",
    value: "Account Research",
    type: "account",
    company,
  },
  {
    name: "Account Planning",
    value: "Account Planning",
    type: "account",
    company,
  },
]
