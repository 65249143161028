import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"

import Text from "@components/ui/Typography/Text"
import useModal from "@components/modals/useModal"
import { ModalsTypes } from "@components/modals/constants"
import { useController, useStore } from "@store"
import NotFound from "@components/ui/NotFound/NotFound"
import LoadingCover from "@components/ui/Loader/LoadingCover"
import Table from "@components/ui/BaseTable/Table"

import ManufacturerTableHeader from "./ManufacturerTableHeader"
import ManufacturerRow from "./ManufacturerRow"

import styles from "./ManufacturerTable.module.sass"

const ManufacturerTable = observer(() => {
  const linkPublicDataModal = useModal(ModalsTypes.LINK_PUBLIC_DATA_MODAL)
  const { companyStore, restrictionsStore: access } = useStore()
  const { companyController } = useController()
  const { companies, loadingCompanies } = companyStore

  useEffect(() => {
    companyController.fetchCompanies()
  }, [])

  const showMenuOption =
    access.canAddManufacturer || access.canConnectPublicData

  return (
    <LoadingCover isLoading={loadingCompanies} className={styles.body}>
      {companies.length ? (
        <Table header={<ManufacturerTableHeader />}>
          {companies.map((item) => (
            <ManufacturerRow
              showMenuOption={showMenuOption}
              canConnectPublicData={access.canConnectPublicData}
              openLinkPublicData={(item) =>
                linkPublicDataModal.showModal({ selectedCompany: item })
              }
              item={item}
              key={item.id}
            />
          ))}
        </Table>
      ) : (
        <NotFound className={styles.notFound}>
          <Text variant="h2" align="center">
            Manufacturer list is empty.
          </Text>
          <Text variant="body2" align="center">
            Start adding your first manufacturer.
          </Text>
        </NotFound>
      )}
    </LoadingCover>
  )
})

export default ManufacturerTable
