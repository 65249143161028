import React from "react"

import Column from "@components/ui/BaseTable/Column"
import Row from "@components/ui/BaseTable/Row"
import Skeleton from "@components/ui/Skeleton/Skeleton"

const ProductListRowSkeleton: React.FC = () => {
  return (
    <Row>
      <Column>
        <Skeleton lineHeight={20} />
      </Column>
      <Column>
        <Skeleton lineHeight={20} />
      </Column>
      <Column>
        <Skeleton lineHeight={20} />
      </Column>
      <Column>
        <Skeleton lineHeight={20} />
      </Column>
      <Column>
        <Skeleton lineHeight={20} />
      </Column>
    </Row>
  )
}

export default ProductListRowSkeleton
