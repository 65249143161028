import { observer } from "mobx-react-lite"
import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useAlert } from "react-alert"

import Button from "@components/ui/Button/Button"
import Icon from "@components/ui/Icon/Icon"
import Templates from "@components/ui/Templates"
import SearchSummaryBlockStore from "@store/search/search-summary-block.store"
import StarRatings from "@components/prototypes/StarRatings/StarRatings"
import { useSearchBlock } from "@pages/search/SearchContext/SearchResultContext"
import Expert from "@pages/search/SearchFlow/Expert"
import { useStore } from "@store"
import SuggestButton from "@components/prototypes/ExpertOptions/SuggestImprovements/SuggestImprovements"
import VerifySearchAnswerButton from "@components/prototypes/ExpertOptions/VerifyAnswer/VerifyAnswer"

import styles from "./AnswerSummary.module.sass"

interface AnswerControlProps {
  hidePostToExpert?: boolean
  setIsEditingAnswer?: (value: boolean) => void
}

export const AnswerControl: React.FC<AnswerControlProps> = observer(
  ({ hidePostToExpert = false, setIsEditingAnswer }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const alert = useAlert()

    const { restrictionsStore: access } = useStore()

    const { searchEntityBlock, searchBlockId, searchEntity } = useSearchBlock(
      SearchSummaryBlockStore
    )
    const { searchSummary: summary, citationsStore } = searchEntityBlock

    const handleSourceClick = () => {
      navigate(`${searchEntity.id}/${searchBlockId}/1`, {
        state: { backTo: location.pathname },
      })
    }

    const handleCopyClick = () => {
      const textToCopy = summary?.summary || ""
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert.success("Text copied successfully")
        })
        .catch(() => {
          alert.error("Failed to copy text")
        })
    }

    return (
      <section className={styles.control}>
        <Templates.Header
          align="start"
          left={
            <div className={styles.action}>
              {!!citationsStore?.totalSources && (
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  before={<Icon name="file-chart" />}
                  onClick={handleSourceClick}
                >
                  Source
                </Button>
              )}

              <Button
                size="small"
                variant="contained"
                color="secondary"
                before={<Icon name="checkbox-multiple-blank" />}
                onClick={handleCopyClick}
              >
                Copy
              </Button>

              {!hidePostToExpert && access.canPostToExpert && (
                <Expert color="secondary" variant="contained" />
              )}
            </div>
          }
          right={
            <div className={styles.rightContainer}>
              {access.canVerifyAnswer && <VerifySearchAnswerButton />}

              {access.canSuggestImprovements && (
                <SuggestButton
                  onClick={() => {
                    if (!setIsEditingAnswer) return
                    setIsEditingAnswer(true)
                  }}
                />
              )}

              {!hidePostToExpert && access.canStarRate && (
                <div className={styles.rating}>
                  <span>How helpful was this?</span>
                  <StarRatings
                    className={styles.fitContent}
                    disabled={!!searchEntityBlock.feedback}
                    message={searchEntityBlock.feedback?.comments || ""}
                  />
                </div>
              )}
            </div>
          }
          rightClassName={styles.ratingWrapper}
        />
      </section>
    )
  }
)

export default AnswerControl
