import React from "react"
import { observer } from "mobx-react-lite"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import useModal from "@components/modals/useModal"
import Text from "@components/ui/Typography/Text"
import { SimpleUserData } from "@framework/types/user"
import Avatar from "@components/ui/Avatar/Avatar"
import MarkdownWithCitations from "@components/prototypes/ResultsCard/AnswerSection/MarkdownWithCitations"

import { ModalsTypes } from "../constants"

import styles from "./QueryAnswerModal.module.sass"

export type QueryAnswerModalProps = {
  answer: string
  question: string
  user: SimpleUserData
}
const QueryAnswerModal: React.FC<QueryAnswerModalProps> = observer(
  ({ answer, question, user }) => {
    const modal = useModal(ModalsTypes.QUERY_ANSWER_MODAL)
    return (
      <BaseModal
        onClose={modal.hideModal}
        className={styles.root}
        title={
          <Text
            variant="body1"
            weight="bold"
            align="center"
            className={styles.header}
          >
            <Avatar
              className={styles.avatar}
              name={user.name}
              src={user.avatarUrl}
              height={24}
              width={24}
            />
            {question}
          </Text>
        }
      >
        <div className={styles.answer}>
          <MarkdownWithCitations>{answer}</MarkdownWithCitations>
        </div>
      </BaseModal>
    )
  }
)

export default QueryAnswerModal
