import React, { useState } from "react"
import { observer } from "mobx-react-lite"

import Button from "@components/ui/Button/Button"
import Label from "@components/ui/Label/Label"
import List from "@components/ui/List/List"
import Loader from "@components/ui/Loader/BarLoader"
import { ExpertQuestion, KnowledgeQuestion } from "@framework/types/question"
import Templates from "@components/ui/Templates"
import { countSuffix } from "@utils/numberUtils"
import Text from "@components/ui/Typography/Text"

import BaseModal from "../components/BaseModal/BaseModal"
import ModalFooterContainer from "../components/ControlFooter/ModalFooterContainer"
import ModalTitle from "../components/ModalTitle/ModalTitle"
import { ModalsTypes } from "../constants"
import useModal from "../useModal"
import { ExpertSearch } from "./ExpertSearch"
import useExpertsList from "./useExpertsList"
import QuestionPreview from "./QuestionPreview"

import styles from "./AssignExpertToQuestionModal.module.sass"

export interface ReassignExpertToQuestionModalProps {
  avatarId: string
  title?: string
  questions: KnowledgeQuestion[] | ExpertQuestion[] | string[]
  selectedQuestions?: string[]
  onSubmit?: (expertId: string[]) => Promise<void> | void
}

/**
 * @deprecated no longer in use, consider to remove
 */
export const ReassignExpertToQuestionModal: React.FC<ReassignExpertToQuestionModalProps> =
  observer(
    ({
      title = "Assign questions",
      questions,
      selectedQuestions,
      avatarId,
      onSubmit,
    }) => {
      const { hideModal } = useModal(ModalsTypes.ADD_EXPERTS_TO_QUESTIONS_MODAL)

      const [loading, setLoading] = useState(false)
      const [expertIds, setExpertIds] = useState<string[]>([])

      const experts = useExpertsList({ avatarId })

      const handleSubmit = async () => {
        setLoading(true)
        await onSubmit?.(expertIds)
        setLoading(false)
      }

      const handleClose = hideModal

      return (
        <BaseModal
          title={<ModalTitle titleText={title} />}
          className={styles.root}
          containerClassName={styles.container}
          onClose={hideModal}
        >
          <Templates.RollScript
            gutter="24"
            footerSocket={
              <ModalFooterContainer>
                <Button
                  disabled={loading}
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>

                <Button
                  disabled={!expertIds.length || loading}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  after={loading && <Loader />}
                >
                  Assign
                </Button>
              </ModalFooterContainer>
            }
          >
            <List gutter="24">
              <Label
                id="selected_questions"
                label={`Question${countSuffix(questions.length)} to answer`}
              >
                <QuestionPreview questions={questions} />
              </Label>

              <List gutter="4" className={styles.listContainer}>
                <Text variant="h5" upperCase>
                  {expertIds.length ? "Experts to assign" : "Select expert"}
                </Text>
                <ExpertSearch
                  items={experts.list}
                  isLoading={experts.isLoading}
                  selectedByDefault={selectedQuestions}
                  value={expertIds}
                  onChange={setExpertIds}
                />
              </List>
            </List>
          </Templates.RollScript>
        </BaseModal>
      )
    }
  )

export default ReassignExpertToQuestionModal
