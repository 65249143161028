import {
  makeAutoObservable,
  onBecomeObserved,
  onBecomeUnobserved,
  runInAction,
} from "mobx"
import { from, ObservableInput, Subscription, timer } from "rxjs"
import { switchMap, tap, retry } from "rxjs/operators"
import { toStream } from "mobx-utils"
import concat from "lodash/concat"

import { BaseUserData } from "@framework/types/user"
import userService from "@services/user.service"

const BATCH_SIZE = 5_000
const RETRY_DELAY = 30 * 1000

type Filter = {
  groupId?: string
  excludeMode?: boolean
}

type State = {
  isLoading: boolean
  data: BaseUserData[]
  total: number
  errorMessage: string
  filter: Filter | null
  searchQuery: string
  reloadTrigger: boolean
}

export class AdminUsersCollection {
  state: State

  private loadingStream$?: Subscription

  constructor() {
    this.state = {
      isLoading: false,

      data: [],

      total: BATCH_SIZE,

      errorMessage: "",

      searchQuery: "",

      filter: null,

      reloadTrigger: false,
    }

    makeAutoObservable(this)

    onBecomeObserved(this.state, "total", () => {
      this.loadingStream$ = this.initLoadingStream().subscribe()
    })

    onBecomeUnobserved(this.state, "total", () => {
      this.loadingStream$?.unsubscribe()
    })
  }

  private initLoadingStream = () => {
    return from(
      toStream(
        () => [
          this.state.searchQuery,
          this.state.filter,
          this.state.reloadTrigger,
        ],
        true
      ) as ObservableInput<
        [State["searchQuery"], State["filter"], State["reloadTrigger"]]
      >
    ).pipe(
      tap(() =>
        runInAction(() => {
          this.state.isLoading = true
          this.state.errorMessage = ""
        })
      ),
      switchMap(([query, filter]) => {
        return userService.getAdminUsers$({
          pageNum: 0,
          pageSize: BATCH_SIZE,
          query,
          ...(filter
            ? {
                groupId: filter?.groupId,
                filterGroupMode: filter?.excludeMode
                  ? "excludeGroup"
                  : "onlyGroup",
              }
            : {}),
        })
      }),
      tap((response) => {
        const { pageNum: pageIndex, pageSize } = response.data.meta

        const total = Math.min(response.data.meta.total, BATCH_SIZE)

        runInAction(() => {
          const newData = response.data.data ?? []

          if (this.state.data.length !== total) {
            const sliced = this.state.data.slice(total)
            this.state.data =
              sliced.length < total
                ? concat(new Array(total), ...sliced)
                : sliced
          }

          this.state.data.splice(pageIndex * pageSize, pageSize, ...newData)

          this.state.total = total
        })

        runInAction(() => {
          this.state.isLoading = false
        })
      }),
      retry({
        delay: () => {
          runInAction(() => {
            this.state.errorMessage = "Loading Failed"
          })
          return timer(RETRY_DELAY)
        },
      })
    )
  }

  get getById() {
    const usersMap = new Map(this.state.data.map((it) => [it.id, it]))
    return (userId: string | null) =>
      userId == null ? null : usersMap.get(userId) ?? null
  }

  get getByIndex() {
    const { data } = this.state
    return (index: number): BaseUserData | null => data[index] ?? null
  }

  search = (query: string) => {
    this.state.searchQuery = query
    this.resetData()
  }

  reset = (filter: Filter | null = null) => {
    this.state.filter = filter
    this.resetData()
  }

  resetData = () => {
    this.state.total = BATCH_SIZE
    this.state.data = new Array(BATCH_SIZE)
  }

  refresh = () => {
    this.state.reloadTrigger = !this.state.reloadTrigger
  }

  deleteUserById = (id: string) => {
    if (!this.state.data.length) return
    const newList = this.state.data.filter((it) => it?.id !== id)
    this.state.total = newList.length
  }
}

export default AdminUsersCollection
