import React from "react"

import { calcPosition } from "./utils"
import useEvent from "./useEvent"

export type Alignment = "start" | "end"
export type Placement = "top" | "right" | "bottom" | "left"
export type PopperPlacement = `${Placement}-${Alignment}`

export interface ContainerProps {
  placement?: PopperPlacement
  disabled?: boolean
  defaultValue?: boolean
}

export const usePopper = (
  containerRef: HTMLElement | null,
  popperRef: HTMLElement | null,
  {
    placement = "bottom-start",
    disabled = false,
    defaultValue = false,
  }: ContainerProps = {}
) => {
  const [isOpened, setIsOpened] = React.useState<boolean>(defaultValue)

  const [pos, setPos] = React.useState<React.CSSProperties>({
    position: "fixed",
  })

  React.useLayoutEffect(() => {
    if (!containerRef || !popperRef || !isOpened || disabled) return undefined

    const handleScroll = () => {
      if (!containerRef) return
      const contRect = containerRef.getBoundingClientRect()
      const popRect = popperRef.getBoundingClientRect()

      const [place, align] = placement.split("-")

      setPos({
        position: "fixed",
        ...calcPosition(contRect, popRect, place, align),
      })
    }

    handleScroll()
    document.addEventListener("scroll", handleScroll, true)
    window.addEventListener("resize", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll, true)
      window.removeEventListener("resize", handleScroll)
    }
  }, [placement, containerRef, popperRef, isOpened, disabled])

  React.useLayoutEffect(() => {
    if (!containerRef || disabled) return undefined

    if (!isOpened) {
      const handleClick = (e: Event) => {
        e.stopPropagation()
        setIsOpened(true)
      }
      containerRef.addEventListener("click", handleClick)
      return () => containerRef.removeEventListener("click", handleClick)
    }

    if (!popperRef) return undefined

    const handleClick = (e: Event) => {
      if (
        !containerRef.contains(e.target as Node) &&
        !popperRef.contains(e.target as Node)
      ) {
        setIsOpened(false)
      }
    }
    document.addEventListener("mouseup", handleClick)
    return () => document.removeEventListener("mouseup", handleClick)
  }, [containerRef, popperRef, isOpened, disabled])

  React.useLayoutEffect(() => {
    if (disabled) setIsOpened(false)
    else setIsOpened(defaultValue)
  }, [disabled])

  const handleToggle = useEvent((e?: React.MouseEvent, value?: boolean) => {
    setIsOpened((prev) => value ?? !prev)
    e?.preventDefault()
    e?.stopPropagation()
  })

  return {
    isActive: isOpened,
    style: pos as React.CSSProperties,
    toggle: handleToggle,
  }
}

export default usePopper
